import React, { useState } from "react";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import { Dispatch } from "redux";
import { useSelector, useDispatch } from 'react-redux';
import { AxiosResponse, AxiosError } from "axios";
import axios from "axios";
import { History } from "history";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { PaymentApis } from '../../apis/paymentApis';
import { Oval } from 'react-loader-spinner'



function VerifyCreditUserPayment() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [show, setShow] = useState(false);

  const navigate = useNavigate();

  // How to access the redux store
  const userLoginData = useSelector((state: any) => state.data.login.value);

  // This is used to update the store
  const dispatch: Dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams()

  if (searchParams.get('reference')) {

    let data = {
      "reference": searchParams.get('reference'),
      "email": searchParams.get('email'),
      "dateFrom": searchParams.get('dateFrom'),
      "dateTo": searchParams.get('dateTo')
    }
    PaymentApis.verifyCreditShipmentPayment(data).then(
      (response: any) => {
        if (response?.data) {
          if (response?.data?.status === true) {
            navigate('/shipments');
          }
        } else {
          // toast.warn('Invalid Login Credentials');
        }
      }
    ).catch(function (error: any) {
      // handle error
      console.log(error.response.data);
    }).finally(() => {
      // toast.error("No Internet Connection");

    });
  }



  return (
    <>
      <div className="flex justify-center mt-[50vh]">
        <Oval
          visible={true}
          height="80"
          width="80"
          color="#E04343"
          secondaryColor="#FCBF94"
          ariaLabel="oval-loading"
          wrapperStyle={{}}
          wrapperClass=""
        />
      </div>


    </>
  );
}

export default VerifyCreditUserPayment;
