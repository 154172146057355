import React from 'react'
import { NavLink } from 'react-router-dom'

function Footer() {
  return (
    <>
    <section className='pt-10 md:pt-10 pb-8 bg-[#000000]'>
        <div className='grid grid-cols-2 gap-8 md:grid-cols-3 lg:grid-cols-12 px-8 lg:px-16 pb-10'>
            <div className='col-span-3 mb-6'>
            <h4 className='text-[#ffffff] text-[16px] md:text-[18px] font-medium mb-6'>OUR OFFICE LOCATIONS</h4>
            <div className='mb-3'>
                <h1 className="text-[14px] text-[#ffffff]">HEAD OFFICE:</h1>
                <div className='w-[16px] h-[1px] bg-[#ffffff] mt-1'></div>
                <h3 className='text-[10px] md:text-[12px] font-medium text-[#ffffff] mt-3'>
                19/21 Balogun street, off Akinremi Street, Anifowoshe, Ikeja, Lagos, Nigeria
                </h3>
              </div>
              <div className='mb-3'>
                <h1 className="text-[14px] text-[#ffffff]">Port Harcourt</h1>
                <div className='w-[16px] h-[1px] bg-[#ffffff] mt-1'></div>
                <h3 className='text-[10px] md:text-[12px] font-medium text-[#ffffff] mt-3'>
                61B, Aba Road, By craft Centre Bus Stop, Beside Semec Company, PHC
                </h3>
              </div>
              <div className='mb-3'>
                <h1 className="text-[14px] text-[#ffffff]">Abuja</h1>
                <div className='w-[16px] h-[1px] bg-[#ffffff] mt-1'></div>
                <h3 className='text-[10px] md:text-[12px] font-medium text-[#ffffff] mt-3'>
                Suite 207, 1st Floor, Plot 336, Global Plaza, Opp. Mallam Shehu Plaza, Obafemi Awolowo Way, Jabi
                </h3>
              </div>
              <div className='mb-3'>
                <h1 className="text-[14px] text-[#ffffff]">Ibadan</h1>
                <div className='w-[16px] h-[1px] bg-[#ffffff] mt-1'></div>
                <h3 className='text-[10px] md:text-[12px] font-medium text-[#ffffff] mt-3'>
                Middle floor, 49 Adekunle Fajuyi road, beside Bovas filling station Adamasingba, Ibadan
                </h3>
              </div>
               
                
            </div>
            <div className='col-span-3 mb-6'>
            <h4 className='text-[#ffffff] text-[16px] md:text-[18px] font-medium mb-6'>OUR OFFICE LOCATIONS</h4>
            <div className='mb-3'>
                <h1 className="text-[14px] text-[#ffffff]">Ado-Ekiti</h1>
                <div className='w-[16px] h-[1px] bg-[#ffffff] mt-1'></div>
                <h3 className='text-[10px] md:text-[12px] font-medium text-[#ffffff] mt-3'>
                Shop 5, phenrose filling station complex, (formerly NNPC filling station) beside Uba, Ajilosun Ado Ekiti.
                </h3>
              </div>
              <div className='mb-3'>
                <h1 className="text-[14px] text-[#ffffff]">Abeokuta:</h1>
                <div className='w-[16px] h-[1px] bg-[#ffffff] mt-1'></div>
                <h3 className='text-[10px] md:text-[12px] font-medium text-[#ffffff] mt-3'>
                No.1, Onijaye Street, Off Olusegun Obasanjo way, Oke Sokori Abeokuta.
                </h3>
              </div>
              <div className='mb-3'>
                <h1 className="text-[14px] text-[#ffffff]">Osogbo:</h1>
                <div className='w-[16px] h-[1px] bg-[#ffffff] mt-1'></div>
                <h3 className='text-[10px] md:text-[12px] font-medium text-[#ffffff] mt-3'>
                MAO House, opp. Chicken Republic, Stadium Road, Igbona, Osogbo.
                </h3>
              </div>
              <div className='mb-3'>
                <h1 className="text-[14px] text-[#ffffff]">Akure</h1>
                <div className='w-[16px] h-[1px] bg-[#ffffff] mt-1'></div>
                <h3 className='text-[10px] md:text-[12px] font-medium text-[#ffffff] mt-3'>
                Lumen Christi house, opp. Mary Queen Catholic Church, 74B Hospital Road, Akure.
                </h3>
              </div>
                
            </div>
            <div className=' col-span-2'>
                <h4 className='text-[#ffffff] text-[16px] md:text-[18px] font-medium mb-6'>SUPPORT</h4>
                <NavLink to="/faq">
                <h3 className='mb-2 text-[10px] md:text-[12px] font-medium text-[#ffffff]'>About Us</h3>
                </NavLink>
                <NavLink to="/about-us">
                <h3 className='mb-2 text-[10px] md:text-[12px] font-medium text-[#ffffff]'>Tracking</h3>
                </NavLink>
               <NavLink to="/contact-us">
               <h3 className='mb-2 text-[10px] md:text-[12px] font-medium text-[#ffffff]'>Contact Us</h3>
               </NavLink>
                <NavLink to="/">
                <h3 className='mb-2 text-[10px] md:text-[12px] font-medium text-[#ffffff]'>Get Quote</h3>
                </NavLink>
                
            </div>
            <div className=' col-span-2'>
                <h4 className='text-[#ffffff] text-[16px] md:text-[18px] font-medium mb-6'>SERVICES</h4>
                <h3 className='mb-2 text-[10px] md:text-[12px] font-medium text-[#ffffff]'>International & Nationwide Courier</h3>
                <h3 className='mb-2 text-[10px] md:text-[12px] font-medium text-[#ffffff]'>Haulage/Distribution</h3>
                <h3 className='mb-2 text-[10px] md:text-[12px] font-medium text-[#ffffff]'>Freight Forwarding</h3>
                <h3 className='mb-2 text-[10px] md:text-[12px] font-medium text-[#ffffff]'>Warehousing</h3>
                <h3 className='mb-2 text-[10px] md:text-[12px] font-medium text-[#ffffff]'>Procurement & Presentation</h3>
                <h3 className='mb-2 text-[10px] md:text-[12px] font-medium text-[#ffffff]'>Customs Clearing</h3>
                


            </div>
            <div className=' col-span-2'>
                <h4 className='text-[#ffffff] text-[16px] md:text-[18px] font-medium mb-6'>CONTACT</h4>
                <NavLink to="/tuition-payment">
                <h3 className=' text-[10px] md:text-[14px] font-medium text-[#ffffff]'>Phone: </h3>
                <h3 className='mb-2 text-[10px] md:text-[12px] font-medium text-[#ffffff]'>07000759638, 09056533136 </h3>
                </NavLink>
                
                <NavLink to="/student-loan">
                <h3 className=' text-[10px] md:text-[14px] font-medium text-[#ffffff]'>Email: </h3>
                <h3 className='mb-2 text-[10px] md:text-[12px] font-medium text-[#ffffff]'>enquiry@skynetworldwide.com.ng</h3>
                </NavLink>
                
                <NavLink to="/savings-with-afripay">
                <h3 className='text-[10px] md:text-[14px] font-medium text-[#ffffff]'>For Asos Complaints:</h3>
                <h3 className='mb-2 text-[10px] md:text-[12px] font-medium text-[#ffffff]'>cs@skynetworldwide.com.ng</h3>
                </NavLink>
                
              
                
            </div>
        </div>
        <hr/>
        <div className='flex justify-center mt-8 px-8 lg:px-16'>
        <div>
                <p className='text-white text-[12px] md:text-[15px]'>Copyright © 2024 | SkyNet Worlwide Express Nigeria</p>
            </div>
            </div>
        
    </section>
</>
  )
}

export default Footer