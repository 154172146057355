import React from 'react'
// @ts-ignore
import Carousel from 'react-grid-carousel'

function Testimonials() {
  return (
    <>
    <div className='bg-[#FFFFFF] sm:px-16 px-6 flex justify-center items-start py-10' >
        <div className='xl:max-w-[1280px] w-full'>
          <div className='flex justify-center items-center'>
            <h1 className="text-center font-bold font-poppins md:text-[32px] text-[27px] text-black md:leading-[50px] leading-[38px]">
              Testimonials
            </h1>
          </div>

          <div className=' pt-10'>
            <Carousel cols={1} rows={1} gap={10} loop showDots>
              <Carousel.Item>
                <div className="flex justify-center">
                  <img src="/images/test1.jpg" alt="hero" className="h-[100px] w-[100px] rounded-full" />
                </div>
                <div className="flex justify-center items-center text-center">

                  <div className=" md:mx-[200px]">
                    <h3 className="mt-3 text-[26px] font-normal leading-[33px]">Danjuma Okeme</h3>
                    <h4 className="mt-3 text-[#4B4F58] text-[16px]">National warehouse and Logistics Manager, Smile Communications Nigeria Limited</h4>
                    <p className="mt-3 text-[#4B4F58] text-[16px]">They have handled and cleared over 400 TONS of telecom equipment worth over 100 Million USD for us since commencement of our relationship. For almost half a decade now, they have successfully provided us with logistics and deployment solutions of our network equipment which now covers four key states namely; Lagos, Ibadan, Abuja and Port Harcourt.</p>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="flex justify-center">
                  <img src="/images/test2.png" alt="hero" className="h-[100px] w-[100px] rounded-full" />
                </div>
                <div className="flex justify-center items-center text-center">
                  <div className=" md:mx-[200px]">
                    <h3 className="mt-3 text-[26px] font-normal leading-[33px]">Prince Neburagho</h3>
                    <h4 className="mt-3 text-[#4B4F58] text-[16px]">Supply Chain Manager, RANBAXY NIGERIA LTD</h4>
                    <p className="mt-3 text-[#4B4F58] text-[16px]">They are known for quality experience within the logistics and haulage industry, their core values of safety, customer service and professionalism create a niche for them above others. With their trained personnel, the outfit will meet your expectations.</p>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="flex justify-center">
                  <img src="/images/test3.png" alt="hero" className="h-[100px] w-[100px] rounded-full" />
                </div>
                <div className="flex justify-center items-center text-center">

                  <div className=" md:mx-[200px]">
                    <h3 className="mt-3 text-[26px] font-normal leading-[33px]">Banjoko Adedapo</h3>
                    <h4 className="mt-3 text-[#4B4F58] text-[16px]">Logistics Manager, Calcare Development Nigeria LTD</h4>
                    <p className="mt-3 text-[#4B4F58] text-[16px]">For the past 5 years,Skynet Worldwide Express Nigeriahas handled our nationwide deliveries and export of our international packages.They are our major service provider, and we attribute this to their good track records in providing dependable and reliable courier services.</p>
                  </div>
                </div>
              </Carousel.Item>


            </Carousel>



          </div>




        </div>
      </div>
    </>
  )
}

export default Testimonials