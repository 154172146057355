import React from 'react'
import { SvgElement, icontypesEnum } from "../assets/svgElement";
import { SuperAdminApis } from '../../apis/superAdminApi';
import { AxiosResponse } from 'axios';
import { Dispatch } from "redux";
import { useSelector, useDispatch } from 'react-redux';

import AdminNavbar from "../Navbars/AdminNavbar.js";
import Sidebar from "../Sidebar/Sidebar";
import AdminSidebar from '../Sidebar/AdminSidebar';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';



ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'User History',
    },
  },
};

export const shipmentOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'Shipment History',
    },
  },
};


function AdminDashboard() {

  const dispatch: Dispatch = useDispatch();
  const [userCount, setUserCount] = React.useState<any>([]);
  const [userDates, setUserDates] = React.useState<any>([]);
  const [userData, setUserData] = React.useState<any>([]);
  const [shipmentDates, setShipmentDates] = React.useState<any>([]);
  const [shipmentData, setShipmentData] = React.useState<any>([]);

  React.useEffect(() => {
    SuperAdminApis.getlinksCount().then(
      (response: AxiosResponse<any>) => {
        if (response?.data) {
          setUserCount(response?.data?.data)
          setUserDates(response?.data?.data?.user_stat?.months)
          setUserData(response?.data?.data?.user_stat?.total)
          setShipmentDates(response?.data?.data?.shipment_stat?.months)
          setShipmentData(response?.data?.data?.shipment_stat?.total)
          console?.log(response?.data?.data)
        }
      }
    ).catch(function (error: any) {
      // handle error
      console.log(error);
      console.log("new error");
    })
  }, []);

  const dataUser = {
    labels:userDates,
    datasets: [
      {
        label: 'Total Users',
        data: userData,
        borderColor: '#E04343',
        backgroundColor: '#F9E8E899',
      },
    ],
  };

  const dataShipment = {
    labels:shipmentDates,
    datasets: [
      {
        label: 'Total Shipments',
        data: shipmentData,
        borderColor: '#E04343',
        backgroundColor: '#F9E8E899',
      },
    ],
  };


  return (
    <>
      <AdminSidebar title="Dashboard" />
      <div className="relative md:ml-64 bg-[#FAFAFA] ">
        <AdminNavbar title="Dashboard" />
        <div className=' lg:px-10 pb-6 px-6 bg-[#FAFAFA] min-h-screen'>

          <div className=" lg:grid lg:grid-cols-4 grid-cols-1 gap-3 lg:py-5">
           
              
                <div className=''>
                  <div className='bg-white shadow-lg rounded-[10px] px-[15px] pt-[20px] pb-[4px] border-t-[2px] border-[#E04343]'>
                    <h5 className='text-[12px] flex justify-start gap-2'>
                      <span className='mt-1'> Total Customers:</span>
                     
                      <span className='text-[16px] font-semibold'>{userCount?.total_cashCustomers +userCount?.total_credit_customrs}</span>
                      
                      </h5>
                   
                    <div className='flex justify-between mt-2' >
                      <div>
                      <h3 className='text-[12px]'>Cash</h3>
                      <h4 className='text-[16px] font-semibold'>{userCount?.total_cashCustomers}</h4>
                      </div>
                      <div>
                      <h3 className='text-[12px]'>Credit</h3>
                      <h4 className='text-[16px] font-semibold'>{userCount?.total_credit_customrs}</h4>
                      </div>
                     
                        {/* <h3 className='flex flex-col'>
                          <span>Cash</span> 
                          <span> {userCount?.total_cashCustomers}</span>
                          </h3>

                          <h3 className='flex flex-col'>
                          <span>Credit</span> 
                          <span> {userCount?.total_credit_customrs}</span>
                          </h3> */}
                        
                        
                      </div>
                  </div>
                </div>
                <div className=''>
                  <div className='bg-white shadow-lg rounded-[10px] px-[15px] pt-[20px] pb-[24px]  border-t-[2px] border-[#E04343]'>
                    <h5 className='text-[12px]'>Total Shippment</h5>
                    <div className='flex justify-between'>
                      <h1 className='text-[24px] font-semibold'>{userCount?.total_shipment}</h1>
                      <div className='flex space-x-1 mt-2'>
                        
                      </div>
                    </div>
                  </div>
                </div>
                <div className=''>
                  <div className='bg-white shadow-lg rounded-[10px] px-[15px] pt-[20px] pb-[24px]   border-t-[2px] border-[#E04343]'>
                    <h5 className='text-[12px]'>Total Manifest</h5>
                    <div className='flex justify-between'>
                      <h1 className='text-[24px] font-semibold'>{userCount?.total_manifest}</h1>
                      <div className='flex space-x-1 mt-2'>
                      
                      </div>
                    </div>
                  </div>
                </div>
                <div className=''>
                  <div className='bg-white shadow-lg rounded-[10px] px-[15px] pt-[20px] pb-[24px] border-t-[2px] border-[#E04343]'>
                    <h5 className='text-[12px]'>Total Staffs</h5>
                    <div className='flex justify-between'>
                      <h1 className='text-[24px] font-semibold'>{userCount?.total_staff}</h1>
                      <div className='flex space-x-1 mt-2'>
                        
                      </div>
                    </div>
                  </div>
                </div>
             
            
           
          </div>

          <div className='grid md:grid-cols-1 '>
          <div className="border shadow-lg border-gray-200 rounded-lg  mt-3 min-h-[30vh]">
              <div className="p-3">
                <span className="text-[14px] font-bold">User Statistics</span>

                <Line height={'100px'} options={options} data={dataUser} />
              </div>
            </div>

            <div className="border shadow-lg border-gray-200 rounded-lg  mt-3 min-h-[30vh]">
              <div className="p-3">
                <span className="text-[14px] font-bold">Shipment Statistics</span>

                <Line  height={'100px'} options={shipmentOptions} data={dataShipment} />
              </div>
            </div>
          </div>


         


        </div>

       
       


       
      </div>
    </>
  )
}

export default AdminDashboard