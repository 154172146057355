import { AxiosGlobal } from "../shared/axios";
import { AxiosPromise } from "axios";
import configs from "../../configs";
import { store } from "../../store/store";



export class UserLiveApis extends AxiosGlobal {
    
    //Links Apis
    createShipment(data: any): AxiosPromise<Array<any>> {
        return this.axios.post(`${configs.context}/shipment/create-shipment`, data, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    }


    calculateShipment(data: any): AxiosPromise<Array<any>> {
        return this.axios.post(`${configs.context}/shipment/calculate-shipment-cost`, data, {
            headers: { "Content-Type": "aplication/json", "Accept": "aplication/json", "Authorization": `Bearer ${store.getState().data.login.value.token}`, "Access-Control-Allow-Origin": "*" },
        });
    }

    getAllShipmentsByUser(pageNo:any): AxiosPromise<Array<any>> {
        return this.axios.get(`${configs.context}/shipment/get-user-shipments?page=${pageNo}`,{
            headers: { "Content-Type": "aplication/json","Accept":"aplication/json","Authorization":`Bearer ${store.getState().data.login.value.token}`,"Access-Control-Allow-Origin":"*" },
          });
    }

    getSingleShipmentsByUser(id:any): AxiosPromise<Array<any>> {
        return this.axios.post(`${configs.context}/shipment/get-single-user-shipment/${id}`,{
            headers: { "Content-Type": "aplication/json","Accept":"aplication/json","Authorization":`Bearer ${store.getState().data.login.value.token}`,"Access-Control-Allow-Origin":"*" },
          });
    }

    trackShipment(id:any): AxiosPromise<Array<any>> {
        return this.axios.post(`${configs.context}/shipment/track-shipment`,id,{
            headers: { "Content-Type": "aplication/json","Accept":"aplication/json","Authorization":`Bearer ${store.getState().data.login.value.token}`,"Access-Control-Allow-Origin":"*" },
          });
    }
    
    
    updateShipment(data:any,id:any): AxiosPromise<Array<any>> {
        return this.axios.put(`${configs.context}/shipment/update-shipment/${id}`,data,{
            headers: { "Content-Type": "aplication/json","Accept":"aplication/json","Authorization":`Bearer ${store.getState().data.login.value.token}`,"Access-Control-Allow-Origin":"*" },
          });
    }

    updateShipmentStatus(data:any,id:any): AxiosPromise<Array<any>> {
        return this.axios.put(`${configs.context}/shipment/update-shipment-status/${id}`,data,{
            headers: { "Content-Type": "aplication/json","Accept":"aplication/json","Authorization":`Bearer ${store.getState().data.login.value.token}`,"Access-Control-Allow-Origin":"*" },
          });
    }

    deleteShipment(id:any): AxiosPromise<Array<any>> {
        return this.axios.delete(`${configs.context}/shipment/delete-shipment/${id}`,{
            headers: { "Content-Type": "aplication/json","Accept":"aplication/json","Authorization":`Bearer ${store.getState().data.login.value.token}`,"Access-Control-Allow-Origin":"*" },
          });
    }

   

}