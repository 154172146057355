import React, { useEffect, useRef } from 'react'
import { SvgElement, icontypesEnum } from "../assets/svgElement";
import { NavLink } from 'react-router-dom';
import Navbar from '../Navbars/Navbar';
import Testimonials from './Testimonials';
import OurClient from './OurClient';

import Footer from '../Footer';

const About: React.FC = () => {

  
  return (
    <>
      <Navbar />

      <section className='flex md:flex-row flex-col sm:py-16 py-6'>
        <div className='flex-1 flex justify-center items-center'>
          <img src="/images/hero.png" alt="hero" />
        </div>
      </section>


      {/* Start Section */}
      <div className='bg-[#FFFFFF] sm:px-16 px-6 flex justify-center items-start md:pt-10' >
        <div className='xl:max-w-[1280px] w-full'>
          <section className='flex md:flex-row flex-col sm:py-16 py-6'>
            <div className='flex-1 flex-col justify-center items-start  '>
              <div>
                <h1 className="text-[22px] font-bold leading-[24px] text-[#FD0000]">About Us</h1>
                <div className='w-[60px] h-[1px] bg-[#000000] mt-2'></div>
              </div>
              <div className='mt-5'>
                <h1 className='text-[38px] text-[#282828] font-extrabold'>MODERN & TRUSTED<br /> LOGISTICS COMPANY</h1>
              </div>
            </div>

            <div className='flex-1 flex flex-col md:ml-[80px] ml-0'>

              <div>
                <p className="text-[18px] leading-[25px] text-black">SkyNet Worldwide Express launched its first services in the USA in 1972.
                  Through understanding customers various needs and in response to market changes we have become the largest independently owned express network in the world providing flexible and reliable door to door services.</p>
              </div>
              <div className='mt-4'>
                <p className="text-[18px] leading-[25px] mt-3 text-black">More than 1000 strategically located offices ensure delivery of your shipments to 209 cities, with major gateways in Dubai, Hong Kong, Johannesburg, London, Miami, Singapore and Sydney.</p>
              </div>
              <div className='mt-4'>
                <p className="text-[18px] leading-[25px] mt-3 text-black">As a single-source provider, we believe that with our flexible business model we maintain a competitive advantage by providing our customers consistent, worldwide, door-to-door delivery. Our team carries out and keeps up its success by creating effective, reliable, and flexible solutions that meet the market current and future needs.</p>
              </div>
              <div className='mt-4'>
                <p className="text-[18px] leading-[25px] mt-3 text-black">To succeed and expand in today’s marketplace, we use advanced information systems as a key component in providing high quality service.</p>
              </div>
            </div>
          </section>
        </div>
      </div>
      {/* End Section */}

      {/* Start Section */}
      <div className='bg-[#FFFFFF] sm:px-16 px-6 flex justify-center items-start md:pt-10' >
        <div className='xl:max-w-[1280px] w-full'>
          <div className='flex justify-center'>
            <div className='grid lg:grid-cols-3 grid-cols-1 gap-9'>
              <div>
                <div className='flex'>
                  <h3 className='text-[24px] font-bold text-[#282828]'>Our Vision</h3>
                </div>
                <div className='w-[350px] h-[2px] bg-[#FE0503] mt-4'></div>
                <p className="text-[18px] leading-[25px] text-black mt-8">
                  Our vision is to become Africa’s largest logistics Company with extensive network in Nigeria and in all major cities of the world.
                </p>
              </div>
              <div>
                <div className='flex'>
                  <h3 className='text-[24px] font-bold text-[#282828]'>Our Mission</h3>
                </div>
                <div className='w-[350px] h-[2px] bg-[#FE0503] mt-4'></div>
                <p className="text-[18px] leading-[25px] text-black mt-8">
                  Our mission is to deliver professional services in the shortest possible time while maintaining a high level of promptness and technical competence.
                </p>
              </div>
              <div>
                <div className='flex'>
                  <h3 className='text-[24px] font-bold text-[#282828]'>Our Core Values</h3>
                </div>
                <div className='w-[350px] h-[2px] bg-[#FE0503] mt-4'></div>
                <div className='mt-8'>
                  <ul className='text-[18px] leading-[25px] text-black list-disc list-inside space-y-1'>
                    <li>Integrity in Business</li>
                    <li>Honesty in Relationship Management</li>
                    <li>Reliability</li>
                    <li>Professionalism</li>
                    <li>Service Excellence</li>
                    <li>Creativity and Innovation</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      {/* End Section */}


      <div className='relative pt-10'>
        <div
          className="w-full h-80 bg-cover bg-fixed bg-center bg-no-repeat"
          style={{ backgroundImage: "url('/images/slide-1.png')" }}>

        </div>
        <div className='absolute top-40 left-52'>
          <h1 className='text-white text-[50px] font-extrabold text-center'>Need Immediate Support Or Any Help?</h1>
          <div className='flex justify-center mt-3'>
            <button className='bg-[#FE0503] text-white px-[30px] py-[15px] rounded-[100px] uppercase'>Connent With Us</button>
          </div>
        </div>
      </div>







      {/* End Section */}

      {/* Start Section */}
      <Testimonials />
      {/* End Section */}

      {/* Start Section */}
      <OurClient />
      {/* End Section */}

      {/* Start Section */}
      <Footer />
      {/* End Section */}
    </>
  )
}

export default About;