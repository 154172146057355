import React from 'react'
import AdminNavbar from "../../Navbars/AdminNavbar";
import Sidebar from "../../Sidebar/Sidebar";
import { SuperAdminApis } from '../../../apis/superAdminApi';
import { useNavigate } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { convertUTCDateToLocalDate } from '../../Reusable/functions';
//@ts-ignore
import Modal from 'react-awesome-modal';
import { NavLink } from "react-router-dom";
import { SvgElement, icontypesEnum } from '../../assets/svgElement';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function EditstateToModal(props: any) {

  const navigate = useNavigate();
  const [userLists, setUserList] = React.useState<any>([]);

  const [countryData, setCountryData] = React.useState<any>([]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCountryData({ ...countryData, [e.target.name]: e.target.value });
  }


  React.useEffect(() => {
    setCountryData(props?.stateData)
  }, [props]);


  const handleSubmit = React.useCallback(
    (e: any) => {
      e.preventDefault();
      let data = countryData;
      SuperAdminApis.updatestateTo(data,props?.stateData?.id).then(
        (response: any) => {
          if (response?.data) {
            toast.success(response?.data?.message);
            props?.handleClose()
          } else {
            toast.error(response?.response?.data?.message);
          }

          // toast.success(response?.data?.message);
        }
      ).catch(function (error) {
        // handle error
        // console.log(error.response);
        toast.error(error.response.data.message);

      })
    },
    [countryData]
  );


  return (
    <>

      <section>
        <Modal
          visible={props?.show}
          width="600"
          height="600"
          effect="fadeInUp"
          onClickAway={() => props?.handleClose}
        >
          <div className=" " style={{ overflow: 'auto' }}>
            <span className="flex justify-between px-3 pt-3 mb-3">
              <p className='font-bold'>Update State in {props?.data?.country_name}</p>
              <p className="cursor-pointer font-bold" onClick={props?.handleClose}><SvgElement type={icontypesEnum.CANCEL} /></p>

            </span>

            <form onSubmit={handleSubmit}>
              <div className=' px-3'>
                <div className="flex flex-col lg:flex-row lg:justify-between max-w-screen-xl mx-auto ">
                  <div className="relative flex flex-col min-w-0 break-words w-full  ">
                    <div className="flex-auto   py-10 pt-0">
                      <div className="flex flex-wrap ">

                        <div className="w-full lg:w-6/12 lg:pr-4">
                          <div className="relative w-full mb-5">
                            <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                              State Name
                            </label>
                            <input
                              type="text"
                              onChange={(e)=>handleChange(e)}
                              value={countryData?.state_name}
                              className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                              placeholder=""
                              name="state_name"

                              // onChange={null}
                              required
                            />
                          </div>
                        </div>
                        <div className="w-full lg:w-6/12 lg:pr-4">
                          <div className="relative w-full mb-5">
                            <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                            State Code
                            </label>
                            <input
                              type="text"
                              onChange={(e)=>handleChange(e)}
                              className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                              placeholder=""
                              value={countryData?.state_code}
                              name="state_code"
                              // onChange={null}
                              required
                            />
                          </div>
                        </div>

                        <div className="w-full lg:w-12/12 lg:pr-4">
                          <div className="relative w-full mb-5">
                            <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                             Client Email (Optional)
                            </label>
                            <input
                              type="text"
                              onChange={(e)=>handleChange(e)}
                              className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                              placeholder=""
                              value={countryData?.client_id}
                              name="client_id"
                              // onChange={null}
                              
                            />
                          </div>
                        </div>

                        <div className="w-full lg:w-6/12 lg:pr-4">
                            <div className="relative w-full mb-5">
                              <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                Zone
                              </label>
                              <select id="countries" name='zone'   onChange={(e:any)=>handleChange(e)} className="border border-[#D1D1D1] bg-white text-[#333333] text-sm rounded-[5px] block w-full p-2.5 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150">
                              <option disabled selected>{countryData?.zone}</option>
                                <option value="north-central">North Central</option>
                                <option value="north-east">North East</option>
                                <option value="north-west">North West</option>
                                <option value="south-east">South East</option>
                                <option value="south-south">South South</option>
                                <option value="south-west">South West</option>
                              </select>

                            </div>
                          </div>

                        {/* <div className="w-full lg:w-6/12 lg:pr-4">
                          <div className="relative w-full mb-5">
                            <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                             Zone
                            </label>
                            <input
                              type="text"
                              onChange={(e)=>handleChange(e)}
                              className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                              placeholder=""
                              value={countryData?.zone}
                              name="zone"
                              // onChange={null}
                              required
                            />
                          </div>
                        </div> */}

                        <div className="w-full lg:w-6/12 lg:pr-4">
                          <div className="relative w-full mb-5">
                            <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                             Base Value
                            </label>
                            <input
                              type="text"
                              onChange={(e)=>handleChange(e)}
                              className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                              placeholder=""
                              value={countryData?.base_value}
                              name="base_value"
                              // onChange={null}
                              required
                            />
                          </div>
                        </div>

                        <div className="w-full lg:w-4/12 lg:pr-4">
                          <div className="relative w-full mb-5">
                            <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                              Per Kg
                            </label>
                            <input
                              type="text"
                              onChange={(e)=>handleChange(e)}
                              className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                              placeholder=""
                              value={countryData?.per_kg}
                              name="per_kg"
                              // onChange={null}
                              required
                            />
                          </div>
                        </div>


                        <div className="w-full lg:w-4/12 lg:pr-4">
                          <div className="relative w-full mb-5">
                            <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                            10.5-20 KG
                            </label>
                            <input
                              type="number"
                              onChange={(e)=>handleChange(e)}
                              className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                              placeholder=""
                              value={countryData?.ten_kg}
                              name="ten_kg"
                              // onChange={null}
                              required
                            />
                          </div>
                        </div>

                        <div className="w-full lg:w-4/12 lg:pr-4">
                          <div className="relative w-full mb-5">
                            <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                               20.5-30 kg
                            </label>
                            <input
                              type="number"
                              onChange={(e)=>handleChange(e)}
                              className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                              placeholder=""
                              value={countryData?.twenty_kg}
                              name="twenty_kg"
                              
                              // onChange={null}
                              required
                            />
                          </div>
                        </div>

                        <div className="w-full lg:w-4/12 lg:pr-4">
                          <div className="relative w-full mb-5">
                            <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                            30.5-40 kg
                            </label>
                            <input
                              type="number"
                              onChange={(e)=>handleChange(e)}
                              className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                              placeholder=""
                              value={countryData?.thirty_kg}
                              name="thirty_kg"
                              
                              // onChange={null}
                              required
                            />
                          </div>
                        </div>
                        <div className="w-full lg:w-4/12 lg:pr-4">
                          <div className="relative w-full mb-5">
                            <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                            40.5-50 kg
                            </label>
                            <input
                              type="number"
                              onChange={(e)=>handleChange(e)}
                              className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                              placeholder=""
                              value={countryData?.forty_kg}
                              name="forty_kg"
                              
                              // onChange={null}
                              required
                            />
                          </div>
                        </div>


                        <div className="w-full lg:w-4/12 lg:pr-4">
                          <div className="relative w-full mb-5">
                            <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                            50Kg and Above
                            </label>
                            <input
                              type="number"
                              onChange={(e)=>handleChange(e)}
                              className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                              placeholder=""
                              value={countryData?.fifty_kg}
                              name="fifty_kg"
                              
                              // onChange={null}
                              required
                            />
                          </div>
                        </div>


                        <div className="w-full flex justify-end">
                          <button type='submit' className='bg-[#E04343] text-white py-[10px] rounded w-full'>Update State</button>
                        </div>


                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </form>




          </div>
        </Modal>
      </section>

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
    </>
  )
}

export default EditstateToModal