import React from "react";
import { FaArchive } from "react-icons/fa";

// components
import AdminNavbar from "../Navbars/AdminNavbar.js";
import Sidebar from "../Sidebar/Sidebar";
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from "react-router-dom";
import { UserApis } from "../../apis/userApi";
import { convertUTCDateToLocalDate } from "../Reusable/functions";


export default function Dashboard() {

  const userLoginData = useSelector((state: any) => state.data.login.value);
  const [shipmentInfos, setShipmentInfos] = React.useState<any>([]);
  const [total, setTotal] = React.useState<any>('');
  const [pending, setPending] = React.useState<any>('');
  const [verified, setVerified] = React.useState<any>('');
  const [others, setOthers] = React.useState<any>('');

  React.useEffect(() => {
    UserApis.getAllShipmentsByUser('').then(
      (response: any) => {
        if (response?.data) {
          setShipmentInfos(response?.data?.data);
          setPending(response?.data?.data?.data?.filter((e: any) => e?.Status == 'inReview')?.length)
          setVerified(response?.data?.data?.data?.filter((e: any) => e?.Status == 'Verified')?.length)
          setOthers(response?.data?.data?.data?.filter((e: any) => (e?.Status !== 'Verified' && e?.Status !== 'inReview' ))?.length)
          setTotal(response?.data?.data?.data?.length)

        }
      }
    ).catch(function (error) {
      // handle error

    })
  }, []);



  return (
    <>
      <Sidebar title="Dashboard" />
      <div className="relative md:ml-64 bg-[#FAFAFA] ">
        <AdminNavbar title="Dashboard" />
        <div className=' lg:px-10 pb-6 px-6 bg-[#FAFAFA] min-h-screen'>
          {/* <!-- Desktop view --> */}

          <div className=" lg:grid lg:grid-cols-2 grid-cols-1 lg:justify-between gap-3 lg:pt-5">

            <div className="lg:hidden lg:pb-0 pb-3 block">
              <h3 className="text-[20px] font-semibold"> Hi, {userLoginData?.data?.last_name} </h3>
              <p className="text-[13px] font-normal">Here are your shipments so far</p>
            </div>

            <div className="bg-white rounded-[10px] border border-[#E2E8F0] lg:pt-[31px] px-[26px] pb-[20px]">
              <div className="hidden lg:block">
                <h3 className="text-[20px] font-semibold"> Hi, {userLoginData?.data?.last_name} </h3>
                <p className="text-[13px] font-normal">Here are your shipments so far</p>
              </div>


              <div className="grid lg:grid-cols-4 grid-cols-2 gap-4 mt-[18px] mb-[26px]">
                <div className="bg-[#EBEBEB66] text-[#263238] p-[10px] flex-col justify-center items-center border border-[#DADADA] rounded-[15px]">
                  <div className="flex justify-center">
                    <FaArchive size={24} />
                  </div>
                  <h4 className="text-[15px] text-center mt-2">Total</h4>
                  <h1 className="text-[36px] font-semibold text-center">{total}</h1>
                </div>

               

                <div className="bg-[#E3C5A133] text-[#F29339] p-[10px] flex-col justify-center items-center border border-[#F29339] rounded-[15px]">
                  <div className="flex justify-center">
                    <FaArchive size={24} />
                  </div>
                  <h4 className="text-[15px] text-center mt-2">In Review</h4>
                  <h1 className="text-[36px] font-semibold text-center">{pending}</h1>
                </div>

                <div className="bg-[#A1E3CB33] text-[#0EBC93] p-[10px] flex-col justify-center items-center border border-[#0EBC93] rounded-[15px]">
                  <div className="flex justify-center">
                    <FaArchive size={24} />
                  </div>
                  <h4 className="text-[15px] text-center mt-2">Verified</h4>
                  <h1 className="text-[36px] font-semibold text-center">{verified}</h1>
                </div>
                
                <div className="bg-[#CAD8FA] text-[#4979ED] p-[10px] flex-col justify-center items-center border border-[#4979ED] rounded-[15px]">
                  <div className="flex justify-center">
                    <FaArchive size={24} />
                  </div>
                  <h4 className="text-[15px] text-center mt-2">Others</h4>
                  <h1 className="text-[36px] font-semibold text-center">{others}</h1>
                </div>
              </div>

              <div className="flex justify-end">
                <NavLink to={'/shipments'}>
                  <button className="bg-[#E04343] text-[#ffffff] py-[10px] px-[37px] text-[14px] rounded-[5px]">See More</button>
                </NavLink>

              </div>
            </div>
            <div className="bg-white rounded-[10px] border border-[#E2E8F0] pt-[31px] px-[26px] pb-[20px] lg:mt-0 mt-6">
              <h3 className="text-[20px] font-semibold"> Track your Shipment </h3>
              <p className="text-[13px] font-normal text-[#999999]">Track the current location of your package</p>
              <div className="lg:mt-[78px] mt-[20px]">
                <form>
                  <div className="mb-5">

                    <input type="text" id="email" className="bg-[#F6F9FF]  border-[0.5px] placeholder:text-[#AAAAAA] placeholder:text-[12px] border-[#D9D9D9] text-gray-900 text-sm rounded-[5px] block w-full p-4 " placeholder="e.g 56776899097hj787" required />
                  </div>
                  <div className="flex justify-end">
                    <NavLink to={'/tracking'}>
                      <button type="button" className="bg-[#E04343] text-[#ffffff] py-[10px] px-[37px] text-[14px] rounded-[5px]">Track Package</button>
                    </NavLink>

                  </div>
                </form>
              </div>
            </div>
          </div>



          <div className="px-6 mt-10 bg-white pt-[24px] rounded-[10px] pb-10">
            <div className="flex justify-start">
              <span className="font-[700]">Recent Shipments</span>
              {/* <span className="bg-[#E3A1A133] text-[#E04343] text-[12px] rounded-[32px] px-4 py-1">See More</span> */}
            </div>
            <div className="relative overflow-x-auto  mt-6">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
                <thead className="text-[12px] text-[#808080] bg-[#FAFAFA] ">
                  <tr>

                    <th scope="col" className="px-2 py-2">
                      S/N
                    </th>
                    {/* <th scope="col" className="px-2 py-2">
                      Run&nbsp;ID
                    </th> */}
                    <th scope="col" className="px-2 py-2">
                      Created&nbsp;By
                    </th>
                    <th scope="col" className="px-2 py-2">
                      Created&nbsp;Date
                    </th>
                    <th scope="col" className="px-2 py-2">
                      Shipment&nbsp;ID
                    </th>
                    <th scope="col" className="px-2 py-2">
                      Sender&nbsp;Ref
                    </th>

                    {/* <th scope="col" className="px-2 py-2">
                      Serv.
                    </th> */}

                    <th scope="col" className="px-2 py-2">
                      Recipient
                    </th>
                    <th scope="col" className="px-2 py-2">
                      Location
                    </th>
                    <th scope="col" className="px-2 py-2">
                      Country
                    </th>
                    <th scope="col" className="px-2 py-2">
                      Shipment Status
                    </th>
                    <th scope="col" className="px-2 py-2">
                      Payment Status
                    </th>
                    {/* <th scope="col" className="px-2 py-2">
                      Action
                    </th> */}



                  </tr>
                </thead>

                <tbody className=''>

                  {
                    shipmentInfos?.data?.slice(0, 5)?.map(
                      (datas: any, index: any) => (
                        <tr className="bg-white  ">

                          <td className="px-2 py-2">
                            {index + 1}
                          </td>

                          {/* <td className="px-6 py-4">
          {datas?.run_id}
        </td> */}
                          <td className="px-6 py-4">
                            {datas?.SenderName}
                          </td>
                          <td className="px-6 py-4">
                            {convertUTCDateToLocalDate(datas?.created_at)}
                          </td>
                          <td className="px-6 py-4 ">
                            {datas?.ShipmentNumber ? datas?.ShipmentNumber : `Not Available`}
                          </td>
                          <td className="px-6 py-4">
                            {datas?.SenderRef1}
                          </td>

                          {/* <td className="px-6 py-4">
                            {datas?.ServiceTypeName}
                          </td> */}
                          <td className="px-6 py-4">
                            {datas?.ReceiverName}
                          </td>
                          <td className="px-6 py-4">
                            {datas?.ReceiverAdd1}
                          </td>
                          <td className="px-6 py-4">
                            {datas?.ReceiverCountryCode}
                          </td>
                          <td className="px-2 py-2">
                            {/* {datas?.Status} */}
                            {datas?.Status == 'inReview' ? <p><b style={{ fontWeight: "500", fontSize: '14px', backgroundColor: '#FFDDBD', color: '#F29339', borderRadius: '10px', padding: '2px 10px' }}>{datas?.Status}</b></p> : <p><b style={{ fontWeight: "500", fontSize: '14px', backgroundColor: '#C9F0D0', color: '#51CF66', borderRadius: '10px', padding: '2px 10px' }}>{datas?.Status}</b></p>}
                            {/* {datas?.Status == 'confirm_shipment' ? <p><b style={{ fontWeight: "500", fontSize: '14px', backgroundColor: '#FFDDBD', color: '#FF922B', borderRadius: '10px', padding: '2px 10px' }}>{datas?.Status}</b></p> : ''}
          {datas?.Status == 'new3' ? <p><b style={{ fontWeight: "500", fontSize: '14px', backgroundColor: '#E6BDFF', color: '#B24BF1', borderRadius: '10px', padding: '2px 10px' }}>{datas?.Status}</b></p> : ''}
          {datas?.Status == 'Missed' ? <p><b style={{ fontWeight: "500", fontSize: '14px', backgroundColor: '#CAD8FA', color: '#4979ED', borderRadius: '10px', padding: '2px 10px' }}>{datas?.Status}</b></p> : ''}
          {datas?.Status == 'Closed' ? <p><b style={{ fontWeight: "500", fontSize: '14px', backgroundColor: '#CAD8FA', color: '#4979ED', borderRadius: '10px', padding: '2px 10px' }}>{datas?.Status}</b></p> : ''} */}

                          </td>

                          <td className="px-2 py-2">
                            {/* {datas?.Status} */}
                            {datas?.payment_status == 'successful' ? <p><b style={{ fontWeight: "500", fontSize: '14px', backgroundColor: '#C9F0D0', color: '#51CF66', borderRadius: '10px', padding: '2px 10px' }}>{datas?.payment_status}</b></p> : ''}
                            {datas?.payment_status == 'pending' ? <p><b style={{ fontWeight: "500", fontSize: '14px', backgroundColor: '#FFDDBD', color: '#FF922B', borderRadius: '10px', padding: '2px 10px' }}>{datas?.payment_status}</b></p> : ''}
                            {datas?.payment_status == 'failed' ? <p><b style={{ fontWeight: "500", fontSize: '14px', backgroundColor: '#E6BDFF', color: '#B24BF1', borderRadius: '10px', padding: '2px 10px' }}>{datas?.payment_status}</b></p> : ''}
                            {datas?.payment_status == 'Missed' ? <p><b style={{ fontWeight: "500", fontSize: '14px', backgroundColor: '#CAD8FA', color: '#4979ED', borderRadius: '10px', padding: '2px 10px' }}>{datas?.payment_status}</b></p> : ''}
                            {datas?.Spayment_statustatus == 'Closed' ? <p><b style={{ fontWeight: "500", fontSize: '14px', backgroundColor: '#CAD8FA', color: '#4979ED', borderRadius: '10px', padding: '2px 10px' }}>{datas?.payment_status}</b></p> : ''}

                          </td>

                          {/* <td className="px-6 py-4 cursor-pointer">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="24" height="24" rx="4" fill="#EBEBEB" />
            <path d="M12 13.5C12.8284 13.5 13.5 12.8284 13.5 12C13.5 11.1716 12.8284 10.5 12 10.5C11.1716 10.5 10.5 11.1716 10.5 12C10.5 12.8284 11.1716 13.5 12 13.5Z" fill="#3F83F8" />
            <path d="M15.2536 9.30891C14.2289 8.60625 13.1323 8.25 11.9944 8.25C10.9704 8.25 9.97219 8.55469 9.02742 9.15187C8.07469 9.75539 7.1407 10.8516 6.375 12C6.99422 13.0312 7.84125 14.0916 8.72344 14.6995C9.73547 15.3966 10.8359 15.75 11.9944 15.75C13.1428 15.75 14.2409 15.3968 15.2592 14.7002C16.1557 14.0859 17.0088 13.027 17.625 12C17.0067 10.9821 16.1508 9.92437 15.2536 9.30891ZM12 14.25C11.555 14.25 11.12 14.118 10.75 13.8708C10.38 13.6236 10.0916 13.2722 9.92127 12.861C9.75097 12.4499 9.70642 11.9975 9.79323 11.561C9.88005 11.1246 10.0943 10.7237 10.409 10.409C10.7237 10.0943 11.1246 9.88005 11.561 9.79323C11.9975 9.70642 12.4499 9.75097 12.861 9.92127C13.2722 10.0916 13.6236 10.38 13.8708 10.75C14.118 11.12 14.25 11.555 14.25 12C14.2493 12.5965 14.012 13.1684 13.5902 13.5902C13.1684 14.012 12.5965 14.2493 12 14.25Z" fill="#3F83F8" />
          </svg>
        </td> */}




                        </tr>
                      )
                    )
                  }

                </tbody>

              </table>
            </div>

          </div>
        </div>





      </div>
    </>
  );
}
