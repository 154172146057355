import './App.css';
import React from 'react'
import { Routes, Route } from "react-router-dom";
import EmailVerify from './Components/Pages/Auth/EmailVerify'
import ForgotPassword from './Components/Pages/Auth/ForgotPword'
import PasswordSuccess from './Components/Pages/Auth/PwordResetSucces'
import EmailVerifyComplete from './Components/Pages/Auth/EmailVerifyComplete'
import Register from './Components/Pages/Auth/Register'
import VerifyPayment from './Components/Pages/verifyPayment'
import VerifyCreditUserPayment from './Components/Pages/verifyCreditUserPayment'
import Login from './Components/Pages/Auth/Login';
import Dashboard from './Components/user/Dashboard';
import Dashboards from './Components/admin/Dashboard';
import Shipments from './Components/user/Shipments';
import AdminShipments from './Components/admin/Shipments';
import CreditCustomer from './Components/admin/CreditCustomer';
import ManageCreditCustomerRates from './Components/admin/ManageCreditCustomerRate';
import EditCreditCustomer from './Components/admin/EditCreditCustomer';
import ChangePassword from './Components/Pages/Auth/ChangePassword';
import CalculateRate from './Components/user/CalculateRate';
import CalculateAdminRate from './Components/admin/CalculateRate';
import Tracking from './Components/user/Tracking';
import AdminTracking from './Components/admin/Tracking';
import Settings from './Components/user/Settings';
import AdminDashboard from './Components/admin/AdminDashboard';
import ManageInvoice from './Components/admin/ManageInvoice';
import ManageExpense from './Components/admin/ManageExpense';
import ManageAudit from './Components/admin/ManageAudit';
import Manifest from './Components/admin/Manifest';
import RunwayManifest from './Components/admin/RunwayManifest';
import ManageRider from './Components/admin/ManageRiders';
import ManageStaffs from './Components/admin/ManageStaffs';
import ManageBranch from './Components/admin/ManageBranch';
import ManageRates from './Components/admin/ManageRates';
import UsersList from './Components/admin/UsersList';
import EditShipment from './Components/admin/EditShipment';
import EditUserShipment from './Components/user/EditShipment';
import Home from './Components/Pages/Home';
import About from './Components/Pages/About';
import Services from './Components/Pages/Services';
import TrackingPage from './Components/Pages/TrackingPage';
import Blog from './Components/Pages/Blog';
import UsaToNigeria from './Components/Pages/BlogDetails/UsaToNigeria';
import UkToNigeria from './Components/Pages/BlogDetails/UkToNigeria';
import ExpenseVoucher from './Components/admin/ExpenseVoucher';
import EditExpenseVoucher from './Components/admin/EditExpenseVoucher';
import StaffLogin from './Components/Pages/Auth/StaffLogin';


function App() {
  return (
    <div>



      <Routes>
        <Route path='*' element={<Login />} />
        <Route path="/" element={<Home />} />
        {/* <Route  path="/" element={<Login/>}/> */}
        <Route path="/about-us" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/tracking-page" element={<TrackingPage />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/login" element={<Login />} />
        <Route path="/staff-login" element={<StaffLogin />} />
        <Route path="/register" element={<Register />} />
        <Route path="/verify-payment" element={<VerifyPayment />} />
        <Route path="/verify-credit-user-payment" element={<VerifyCreditUserPayment />} />
        <Route path="/email-verify" element={<EmailVerify />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/password-success" element={<PasswordSuccess />} />
        <Route path="/email-verify/:email" element={<EmailVerifyComplete />} />

        {/* <!-- Blog Details --> */}
        <Route path="/how-to-ship-goods-from-nigeria-to-usa" element={<UsaToNigeria/>} />
        <Route path="/how-to-export-foodstuffs-from-nigeria-to-uk" element={<UkToNigeria/>} />

        {/* <!-- User --> */}
        <Route path="/user-dashboard" element={<Dashboard />} />
        <Route path="/shipments" element={<Shipments />} />
        <Route path="/edit-user-shipment/:id" element={<EditUserShipment />} />
        <Route path="/calculate-rate" element={<CalculateRate />} />
        <Route path="/tracking" element={<Tracking />} />
        <Route path="/settings" element={<Settings />} />

        {/* <!-- Admin --> */}
        <Route path="/admin/admin-dashboard" element={<AdminDashboard />} />
        <Route path="/admin/dashboard" element={<Dashboards />} />
        <Route path="/admin/shipments" element={<AdminShipments />} />
        <Route path="/admin/credit-customers" element={<ManageCreditCustomerRates />} />
        <Route path="/admin/view-credit-customers/:id" element={<EditCreditCustomer />} />

        <Route path="/admin/expense-voucher" element={<ExpenseVoucher />} />
        <Route path="/admin/view-expense-voucher/:id" element={<EditExpenseVoucher />} />


        <Route path="/admin/audits" element={<ManageAudit />} />
        <Route path="/admin/manifest" element={<Manifest />} />
        <Route path="/admin/runway-manifest" element={<RunwayManifest />} />
        <Route path="/admin/manage-invoice" element={<ManageInvoice />} />
        <Route path="/admin/manage-expense" element={<ManageExpense />} />
        <Route path="/admin/manage-riders" element={<ManageRider />} />
        <Route path="/admin/calculate-rate" element={<CalculateAdminRate />} />
        <Route path="/admin/manage-staffs" element={<ManageStaffs />} />
        <Route path="/admin/manage-branch" element={<ManageBranch />} />
        <Route path="/admin/tracking" element={<AdminTracking />} />
        <Route path="/admin/manage-rates" element={<ManageRates />} />
        <Route path="/admin/users" element={<UsersList />} />
        <Route path="/admin/edit-shipment/:id" element={<EditShipment />} />

      </Routes>
    </div>
  );
}

export default App;
