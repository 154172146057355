import { AxiosPromise } from "axios";
import configs from "../configs";
import { UserLiveApis } from "./live/userLiveApi";



export class UserApis {
    private static adminApi: UserLiveApis = new UserLiveApis();

    

    static createShipment(data: any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.adminApi.createShipment(data);
        }
    } 


    static calculateShipment(data: any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.adminApi.calculateShipment(data);
        }
    } 
    
    static getAllShipmentsByUser(pageNo:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.adminApi.getAllShipmentsByUser(pageNo);
        }
    }

    static trackShipment(id:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.adminApi.trackShipment(id);
        }
    }

    static getSingleShipmentsByUser(id:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.adminApi.getSingleShipmentsByUser(id);
        }
    }

    static updateShipment(data:any,id:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.adminApi.updateShipment(data,id);
        }
    }

    static updateShipmentStatus(data:any,id:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.adminApi.updateShipmentStatus(data,id);
        }
    }

    static deleteShipment(id:any): AxiosPromise<Array<any>> {
        if (configs.type === "LOCAL") {
            return {} as AxiosPromise;
        } else {
            return this.adminApi.deleteShipment(id);
        }
    }



}