import React from 'react'
import { AuthApis } from '../../../apis/authApis';
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { SvgElement, icontypesEnum } from '../../assets/svgElement';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Oval } from 'react-loader-spinner';


function ChangePassword() {

  const [userData, setUserdata] = React.useState({
    'email': "",
    'otp': "",
    'password': "",
    "confirm_password": ""
  });
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    toast.success('We have sent your OTP to your Email.');
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setUserdata({ ...userData, [name]: value });
  };
  const [loader,setLoader] =React.useState<boolean>(false);

  const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoader(true)
    const formData = new FormData()
    formData.append('email', location?.state?.email)
    formData.append('token', userData?.otp)
    formData.append('password', userData?.password)
    formData.append('password_confirmation', userData?.confirm_password)

    AuthApis.resetPassword(formData).then(
      (response:any) => {
        if (response?.data) {
          if (response?.data?.success) {
            navigate('/password-success');
            setLoader(false)
          }
        } else {
          setLoader(false)
          toast.warn(response?.response?.data?.message);
        }

        // toast.success(response?.data?.message);
      }
    ).catch(function (error) {
      // handle error
      // console?.log(error)
      toast.warn(error?.response?.data?.message);
      setLoader(false)
    }).finally(() => {
      // toast.error("No Internet Connection");

    });
  }


  return (
    <>

      <div className="flex flex-row">
        <div className="lg:basis-2/5 basis-full relative">
          <div className="h-screen bg-white lg:pl-[90px] lg:pr-[80px] pl-[30px] pr-[20px] pt-[70px] lg:pb-[40px]">
            {/* <img src="/images/Auth/logo.png" alt="hero" /> */}
            <div className="mt-[20px] ">
              <h1 className="text-[#42505C] lg:text-[54px] text-[44px] font-[700]">Change Password</h1>
              <p className="text-[#404040] lg:text-[14px] text-[12px] font-[400] mt-3">Input your new desired password in the input fields below to create a new password. We strongly advise you to store it safely.</p>
            </div>
            {/* <hr className=""/> */}
            <div className="mt-10">

              <form onSubmit={handleSubmit}>

              <div className="relative mb-4">
                  <div className="flex justify-between mb-2">
                    <h3 className="text-[#333333] text-[12px]">OTP <span className=' text-red-600'>*</span></h3>
                    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path opacity="0.1" d="M9.15022 0C4.51085 0 0.75 3.58176 0.75 8.00021C0.750057 9.05081 0.967392 10.0911 1.3896 11.0617C1.8118 12.0323 2.4306 12.9142 3.21068 13.6571C3.99075 14.3999 4.91681 14.9892 5.936 15.3912C6.95518 15.7932 8.04752 16.0001 9.15065 16C10.2538 15.9999 11.3461 15.793 12.3652 15.3909C13.3844 14.9888 14.3104 14.3994 15.0904 13.6565C15.8704 12.9136 16.4891 12.0316 16.9112 11.061C17.3333 10.0903 17.5505 9.04998 17.5504 7.99937C17.5504 3.58176 13.7887 0 9.15022 0ZM9.01809 12.6662H8.97346C8.2892 12.647 7.80618 12.1662 7.82631 11.5236C7.84556 10.8919 8.33995 10.4328 9.00146 10.4328L9.04172 10.4344C9.74523 10.4536 10.223 10.9295 10.2029 11.5903C10.1827 12.2237 9.69711 12.6662 9.01809 12.6662ZM11.8978 7.22352C11.7368 7.44019 11.3833 7.71187 10.937 8.04271L10.4453 8.36522C10.1757 8.56522 10.013 8.7544 9.95261 8.93857C9.90361 9.08357 9.88086 9.12274 9.87649 9.41858V9.49358H7.99956L8.00481 9.34191C8.02844 8.72189 8.04419 8.35522 8.31457 8.05271C8.73896 7.57936 9.67523 7.00435 9.71461 6.98018C9.84305 6.89081 9.95511 6.78184 10.0462 6.65767C10.2431 6.3985 10.3297 6.19516 10.3297 5.99682C10.3297 5.71848 10.244 5.46098 10.0734 5.23347C9.90886 5.0118 9.59648 4.90179 9.14497 4.90179C8.69696 4.90179 8.38982 5.0368 8.20694 5.31514C8.01794 5.59931 7.92256 5.89849 7.92256 6.20433V6.281H5.98789L5.99139 6.20099C6.04126 5.07347 6.46477 4.26094 7.24704 3.78676C7.73968 3.48592 8.35307 3.33342 9.06797 3.33342C10.0025 3.33342 10.7935 3.55009 11.4148 3.97677C12.0457 4.40928 12.3651 5.05763 12.3651 5.90265C12.3642 6.37517 12.2075 6.81934 11.8978 7.22352Z" fill="black" />
                    </svg>

                  </div>
                  <input type="text" required name='otp' onChange={handleChange} className=" border border-[#D1D1D1] text-gray-900 text-sm rounded-[10px] block w-full pl-4 p-2.5" placeholder="" />
                </div>

                <div className="relative mb-4">
                  <div className="flex justify-between mb-2">
                    <h3 className="text-[#333333] text-[12px]">New Password <span className=' text-red-600'>*</span></h3>
                    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path opacity="0.1" d="M9.15022 0C4.51085 0 0.75 3.58176 0.75 8.00021C0.750057 9.05081 0.967392 10.0911 1.3896 11.0617C1.8118 12.0323 2.4306 12.9142 3.21068 13.6571C3.99075 14.3999 4.91681 14.9892 5.936 15.3912C6.95518 15.7932 8.04752 16.0001 9.15065 16C10.2538 15.9999 11.3461 15.793 12.3652 15.3909C13.3844 14.9888 14.3104 14.3994 15.0904 13.6565C15.8704 12.9136 16.4891 12.0316 16.9112 11.061C17.3333 10.0903 17.5505 9.04998 17.5504 7.99937C17.5504 3.58176 13.7887 0 9.15022 0ZM9.01809 12.6662H8.97346C8.2892 12.647 7.80618 12.1662 7.82631 11.5236C7.84556 10.8919 8.33995 10.4328 9.00146 10.4328L9.04172 10.4344C9.74523 10.4536 10.223 10.9295 10.2029 11.5903C10.1827 12.2237 9.69711 12.6662 9.01809 12.6662ZM11.8978 7.22352C11.7368 7.44019 11.3833 7.71187 10.937 8.04271L10.4453 8.36522C10.1757 8.56522 10.013 8.7544 9.95261 8.93857C9.90361 9.08357 9.88086 9.12274 9.87649 9.41858V9.49358H7.99956L8.00481 9.34191C8.02844 8.72189 8.04419 8.35522 8.31457 8.05271C8.73896 7.57936 9.67523 7.00435 9.71461 6.98018C9.84305 6.89081 9.95511 6.78184 10.0462 6.65767C10.2431 6.3985 10.3297 6.19516 10.3297 5.99682C10.3297 5.71848 10.244 5.46098 10.0734 5.23347C9.90886 5.0118 9.59648 4.90179 9.14497 4.90179C8.69696 4.90179 8.38982 5.0368 8.20694 5.31514C8.01794 5.59931 7.92256 5.89849 7.92256 6.20433V6.281H5.98789L5.99139 6.20099C6.04126 5.07347 6.46477 4.26094 7.24704 3.78676C7.73968 3.48592 8.35307 3.33342 9.06797 3.33342C10.0025 3.33342 10.7935 3.55009 11.4148 3.97677C12.0457 4.40928 12.3651 5.05763 12.3651 5.90265C12.3642 6.37517 12.2075 6.81934 11.8978 7.22352Z" fill="black" />
                    </svg>

                  </div>
                  <input type="password" required name='password' onChange={handleChange} className=" border border-[#D1D1D1] text-gray-900 text-sm rounded-[10px] block w-full pl-4 p-2.5" placeholder="" />
                </div>

                <div className="relative mb-4">
                  <div className="flex justify-between mb-2">
                    <h3 className="text-[#333333] text-[12px]">Comfirm New Password <span className=' text-red-600'>*</span></h3>
                    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path opacity="0.1" d="M9.15022 0C4.51085 0 0.75 3.58176 0.75 8.00021C0.750057 9.05081 0.967392 10.0911 1.3896 11.0617C1.8118 12.0323 2.4306 12.9142 3.21068 13.6571C3.99075 14.3999 4.91681 14.9892 5.936 15.3912C6.95518 15.7932 8.04752 16.0001 9.15065 16C10.2538 15.9999 11.3461 15.793 12.3652 15.3909C13.3844 14.9888 14.3104 14.3994 15.0904 13.6565C15.8704 12.9136 16.4891 12.0316 16.9112 11.061C17.3333 10.0903 17.5505 9.04998 17.5504 7.99937C17.5504 3.58176 13.7887 0 9.15022 0ZM9.01809 12.6662H8.97346C8.2892 12.647 7.80618 12.1662 7.82631 11.5236C7.84556 10.8919 8.33995 10.4328 9.00146 10.4328L9.04172 10.4344C9.74523 10.4536 10.223 10.9295 10.2029 11.5903C10.1827 12.2237 9.69711 12.6662 9.01809 12.6662ZM11.8978 7.22352C11.7368 7.44019 11.3833 7.71187 10.937 8.04271L10.4453 8.36522C10.1757 8.56522 10.013 8.7544 9.95261 8.93857C9.90361 9.08357 9.88086 9.12274 9.87649 9.41858V9.49358H7.99956L8.00481 9.34191C8.02844 8.72189 8.04419 8.35522 8.31457 8.05271C8.73896 7.57936 9.67523 7.00435 9.71461 6.98018C9.84305 6.89081 9.95511 6.78184 10.0462 6.65767C10.2431 6.3985 10.3297 6.19516 10.3297 5.99682C10.3297 5.71848 10.244 5.46098 10.0734 5.23347C9.90886 5.0118 9.59648 4.90179 9.14497 4.90179C8.69696 4.90179 8.38982 5.0368 8.20694 5.31514C8.01794 5.59931 7.92256 5.89849 7.92256 6.20433V6.281H5.98789L5.99139 6.20099C6.04126 5.07347 6.46477 4.26094 7.24704 3.78676C7.73968 3.48592 8.35307 3.33342 9.06797 3.33342C10.0025 3.33342 10.7935 3.55009 11.4148 3.97677C12.0457 4.40928 12.3651 5.05763 12.3651 5.90265C12.3642 6.37517 12.2075 6.81934 11.8978 7.22352Z" fill="black" />
                    </svg>

                  </div>
                  <input type="password" required name='confirm_password' onChange={handleChange} className=" border border-[#D1D1D1] text-gray-900 text-sm rounded-[10px] block w-full pl-4 p-2.5" placeholder="" />
                </div>




                <div className="flex justify-center mt-6">
                  <button type='submit' className="w-full text-white bg-[#E04343] rounded-[30px] transition ease-out delay-300 py-[12px] px-[42px] font-[700] text-[14px]">
                    
                  
                    <div className="flex justify-center gap-3 ">
                      <span>  Change Password</span>
                      <Oval
                        visible={loader}
                        height="20"
                        width="20"
                        color="#E04343"
                        secondaryColor="#FCBF94"
                        ariaLabel="oval-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                      />
                    </div>
                    </button>
                </div>
              </form>
            </div>

          </div>
        </div>

        <div className="basis-3/5 hidden lg:block">
          <div className="h-screen flex items-center justify-center bg-[#FAFAFA]">
            <img src="/images/Auth/forgot-pw.png" alt="hero" className="w-[50%]" />
          </div>
        </div>


      </div>


      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
    </>
  )
}

export default ChangePassword;