import React from 'react'
import AdminNavbar from "../Navbars/AdminNavbar";
import Sidebar from "../Sidebar/Sidebar";
import { SuperAdminApis } from '../../apis/superAdminApi';
import { useNavigate } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector, useDispatch } from 'react-redux';

import { convertUTCDateToLocalDate } from '../Reusable/functions';
//@ts-ignore
import Modal from 'react-awesome-modal';
import { SvgElement, icontypesEnum } from '../assets/svgElement';
import { AuthApis } from '../../apis/authApis';
import { error } from 'console';
import AdminSidebar from '../Sidebar/AdminSidebar';
import { STATUSTYPES } from '../Reusable/Status';
import { Oval } from 'react-loader-spinner';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { MdDownload } from "react-icons/md";
import * as XLSX from 'xlsx';

interface Shipment {
  connoteForm: string;
  shipper: string;
  receiver: string;
  goodsDesc: string;
  weight: string;
}

interface ShipmentData {
  created_at: string;
  fleet_name: string;
  manifest_id: string;
  destination_state: string;
  manifest_name: string;
  shipments: any[];
}

interface Shipments {
  SenderName: string;
  SenderAddCity: string;
  ReceiverName: string;
  ReceiverAddCity: string;
  GoodsDescription: string;
  Status: string;
  Weight: string;
  NoOfItems: number;
  ReceiverAdd1: string;
}

interface Manifests {
  id: number;
  manifest_name: string;
  manifest_id: string;
  sender_state: string;
  destination_state: string;
  created_at: string;
  shipments: Shipments[];
}

const exportToExcel = (data: Manifests[]) => {
  // Prepare data for export
  const exportData = data?.flatMap((manifest) => {
    // Map manifest data to a flat structure, with each shipment as a new row
    return manifest.shipments?.map((shipment, index) => ({
      'Manifest Name': manifest.manifest_name,
      'Manifest ID': manifest.manifest_id,
      'Sender State': manifest.sender_state,
      'Destination State': manifest.destination_state,
      'Created At': manifest.created_at,
      'Shipment Sender Name': shipment.SenderName,
      'Shipment Sender City': shipment.SenderAddCity,
      'Shipment Receiver Name': shipment.ReceiverName,
      'Shipment Receiver City': shipment.ReceiverAddCity,
      'Goods Description': shipment.GoodsDescription,
      'Shipment Status': shipment.Status,
      'Weight': shipment.Weight,
      'Number of Items': shipment.NoOfItems,
      'Reciever Address': shipment.ReceiverAdd1,
    }));
  });

  // Create a new workbook
  const workbook = XLSX?.utils?.book_new();

  // Convert the JSON data to a sheet
  const worksheet = XLSX.utils?.json_to_sheet(exportData);

  // Append the worksheet to the workbook
  XLSX?.utils?.book_append_sheet(workbook, worksheet, 'Manifests');

  // Export the workbook to an Excel file
  XLSX?.writeFile(workbook, 'manifests.xlsx');
};

const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 10,
  },
  section: {
    marginBottom: 10,

  },
  header: {
    fontSize: 14,
    marginBottom: 10,
    textAlign: 'center',
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  table: {
    display: 'flex',
    paddingTop: 40,
    flexDirection: 'column',
    width: '100%',
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1px solid #000',
    paddingBottom: 4,
    marginBottom: 4,
  },
  tableCol: {
    flex: 1,
    padding: 4,
  },
  tableColHeader: {
    flex: 1,
    padding: 4,
    fontWeight: 'bold',
  },
  boldText: {
    fontWeight: 'bold',
  },
  footer: {
    marginTop: 20,
    // borderTop: '1px solid #000',
    paddingTop: '50px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  footer2: {
    marginTop: 20,
    // borderTop: '1px solid #000',
    paddingTop: '10px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  footerText: {
    fontSize: 10
  },
  logo: {
    width: 100,
    height: 'auto',
    marginBottom: 20,
  }
});


const ShipmentManifest = ({ data }: { data: ShipmentData }) => (
  <Document>
    <Page style={styles.page}>
      <View>
        <Image
          style={styles.logo}
          src="/images/Auth/logo.png"
        />
      </View>
      <Text style={styles.header}>Manifest</Text>

      <View style={styles.section}>
        <Text style={{ paddingBottom: 8 }}><Text style={{ fontWeight: 'bold' }}>Date</Text> : {convertUTCDateToLocalDate(data.created_at)}</Text>
        <Text style={{ paddingBottom: 8 }}><Text style={{ fontWeight: 'bold' }}>Fleet</Text> : {data.fleet_name}</Text>
        <Text style={{ paddingBottom: 8 }}> <Text style={{ fontWeight: 'bold' }}>Manifest Name</Text> : {data.manifest_name}</Text>
        <Text style={{ paddingBottom: 8 }}> <Text style={{ fontWeight: 'bold' }}>Manifest Id</Text> : {data.manifest_id}</Text>
        <Text> <Text style={{ fontWeight: 'bold' }}>Destination</Text> : {data.destination_state}</Text>
      </View>

      <View style={styles.table}>
      <View style={styles.tableRow}>
            <View style={styles.tableColHeader}>
              <Text>Shipment Id #</Text>
            </View>
            <View style={styles.tableCol}>
              <Text>Shipper</Text>
            </View>
            <View style={styles.tableCol}>
              <Text>Receiver</Text>
            </View>
            <View style={styles.tableCol}>
              <Text>Goods Desc</Text>
            </View>
            <View style={styles.tableCol}>
              <Text>Weight</Text>
            </View>
            <View style={styles.tableCol}>
              <Text>No of Pcs.</Text>
            </View>
            <View style={styles.tableCol}>
              <Text>Reciever Add.</Text>
            </View>
            <View style={styles.tableCol}>
              <Text>Custom Value</Text>
            </View>
          </View>
        {data.shipments.map((shipment, index) => (
          <View key={index} style={styles.tableRow}>
            <View style={styles.tableColHeader}>
              <Text style={styles.boldText}>{shipment.ShipmentNumber}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text>{shipment.SenderName},{shipment.SenderAddState},{shipment.SenderCountryCode} </Text>
            </View>
            <View style={styles.tableCol}>
              <Text>{shipment.ReceiverName},{shipment.ReceiverAddState},{shipment.ReceiverCountryCode} </Text>
            </View>
            <View style={styles.tableCol}>
              <Text>{shipment.GoodsDescription}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text>{shipment.Weight} {shipment.WeightMeasurement} </Text>
            </View>
            <View style={styles.tableCol}>
              <Text>{shipment.NoOfItems} </Text>
            </View> 
            <View style={styles.tableCol}>
              <Text>{shipment.ReceiverAdd1} </Text>
            </View>
            <View style={styles.tableCol}>
              <Text>{shipment.CustomValue} </Text>
            </View>
          </View>
        ))}
      </View>

      <View style={styles.footer2}>
        <Text style={styles.footerText}>Total Shipments: {data.shipments.length} </Text><br />
        {/* <Text style={styles.footerText}>Total Weights: {data.shipments.length}</Text><br />
        <Text style={styles.footerText}>Total Pieces: {data.shipments.length}</Text><br /> */}
      </View>

      <View style={styles.footer}>
        {/* <Text style={styles.footerText}>Summary for Docket 01 Bag #: {data.shipments.length} connotes</Text><br/> */}
        <Text style={styles.footerText}>Driver's Signature: ______________________</Text>
        <Text style={styles.footerText}>Collected date/time: ______________________</Text>
      </View>
    </Page>
  </Document>
);



function Manifest() {
  const navigate = useNavigate();
  const [userLists, setUserList] = React.useState<any>([]);
  const [stateFrom, setStateFrom] = React.useState<any>([]);



  const userLoginData = useSelector((state: any) => state.data.login.value);

  const [searchText, setSearchText] = React.useState('');

  let [riderModal, setRiderModal] = React.useState<boolean>(false);
  let [shipmentRider, setShipmentRider] = React.useState<any>('');
  let [riders, setRiders] = React.useState<any>([]);
  let [vehicle, setVehicle] = React.useState<any>([]);
  const [shipmentInfo, setShipmentInfo] = React.useState<any>([]);
  const [manifestListData, setmanifestListData] = React.useState<any>([]);
  const [runmanifestListData, setrunmanifestListData] = React.useState<any>([]);
  let [shipmentStatusId, setShipmentStatusId] = React.useState<any>('');

  const [shipid, setShipId] = React.useState<any>({
    shipids: [],
  });

  const [shipWeight, setShipWeight] = React.useState<any>({
    shipWeights: 0,
  });

  let [addModal, setAddModal] = React.useState<boolean>(false);
  const [tabNo, setTabNo] = React.useState<any>('1');
  const [fleetData, setFleetData] = React.useState<any>([]);
  const [fleetData2, setFleetData2] = React.useState<any>([]);

  const handleChange2 = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFleetData2({ ...fleetData2, [name]: value });

    if (name == 'shipment_status') {
      setFleetData2({ ...fleetData2, [name]: value });
      STATUSTYPES?.filter((data: any) => data?.EventName == value)?.map((datas: any, index: any) => {
        setShipmentStatusId(datas?.EventCode)
        setFleetData2({ ...fleetData2, 'status_id': datas?.EventCode, shipment_status: value });
      }
      )
    }
  };


  const [loading, setLoading] = React.useState<boolean>(false);


  const [userData, setUserdata] = React.useState<any>({
    'manifest_name': "",
    'manifest_id': " ",
    'created_by': "",
    'sender_state': "",
    'destination_state': "",
    'shipment_status': "",
    'fleet_name': "null",
    'vehicle_name': "null",
    'goods_description': "null",
    'pcs': "null",
    'weight': "null",
    'fleet_price': 0,
    'custom_value': 0,
    // 'checked': false
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target;
    setUserdata({ ...userData, [name]: value });
  };


  const handleSubmit = React.useCallback(
    (e: React.ChangeEvent<HTMLFormElement>) => {
      e.preventDefault();

      let eventCode = '';
      setLoading(true)

      STATUSTYPES?.filter((data: any) => data?.EventName == userData?.shipment_status)?.map((datas: any, index: any) => {
        setShipmentStatusId(datas?.EventCode)
        eventCode = datas?.EventCode
      }
      )
      const formData = new FormData()
      formData.append('manifest_name', userData?.manifest_name)
      formData.append('manifest_id', Math.floor(Math.random() * Date.now()).toString(32))
      formData.append('created_by', userLoginData?.data?.email)
      formData.append('sender_state', userLoginData?.data?.office)
      formData.append('destination_state', (userData?.destination_state))
      formData.append('shipment_status', userData?.shipment_status)
      formData.append('status_id', eventCode)
      formData.append('fleet_name', shipmentRider?.target?.value)
      formData.append('fleet_price', userData?.fleet_price)
      formData.append('shipment_ids', shipid?.shipids?.toString())
      formData.append('vehicle_name', userData?.vehicle_name)
      formData.append('goods_description', userData?.goods_description)
      formData.append('pcs', shipid?.shipids?.length)
      formData.append('weight', shipWeight?.shipWeights[0])
      formData.append('custom_value', userData?.custom_value)


      SuperAdminApis.createManifest(formData).then(
        (response: AxiosResponse<any>) => {
          if (response?.data) {
            if (response?.data?.status === true) {
              toast.success(response?.data?.message);
            }

          } else {
            console?.log(response)
            toast.warn("Something went wrong,Try Again");
          }
          setLoading(false)
          setAddModal(false)
          const query: any = {
            search: searchText,
          };
          SuperAdminApis.getAllManifest('', query).then(
            (response: AxiosResponse<any>) => {
              if (response?.data) {
                setUserList(response?.data?.data)
              }
            }
          )

          setUserdata([])

          // toast.success(response?.data?.message);
        }
      ).catch(function (error) {
        // handle error
        setLoading(false)
        console.log(error.response.data);
        toast.warn(error.response?.data?.message);
        toast.error("Offfline");
      }).finally(() => {
        setLoading(false)
      });
    },
    [userData, userLists, shipWeight, shipmentRider]
  );

  const updateManifest = React.useCallback(
    (e: React.ChangeEvent<HTMLFormElement>) => {
      e.preventDefault();
      setLoading(true)
      const formData = fleetData2



      SuperAdminApis.updateManifest(formData).then(
        (response: AxiosResponse<any>) => {
          if (response?.data) {
            if (response?.data?.status === true) {
              toast.success(response?.data?.message);
            }

          } else {
            console?.log(response)
            toast.warn("Something went wrong,Try Again");
          }
          setLoading(false)
          setRiderModal(false)
          const query: any = {
            search: searchText,
          };
          SuperAdminApis.getAllManifest('', query).then(
            (response: AxiosResponse<any>) => {
              if (response?.data) {
                setUserList(response?.data?.data)
              }
            }
          )

          setUserdata([])

          // toast.success(response?.data?.message);
        }
      ).catch(function (error) {
        // handle error
        setLoading(false)
        console.log(error.response.data);
        toast.warn(error.response?.data?.message);
        toast.error("Offfline");
      }).finally(() => {
        setLoading(false)
      });
    },
    [userLists, fleetData2, riderModal, loading]
  );



  React.useEffect(() => {
    const query: any = {
      search: searchText,
    };
    SuperAdminApis.getAllRiders('', query).then(
      (response: AxiosResponse<any>) => {
        if (response?.data) {
          setRiders(response?.data?.data?.data)
        }
      }
    ).catch(function (error) {
      // handle error

    })

    SuperAdminApis.getAllManifest('', query).then(
      (response: AxiosResponse<any>) => {
        if (response?.data) {
          setUserList(response?.data?.data)
          // console?.log(response?.data?.data)
          // setRiders(response?.data?.data?.data)
        }
      }
    ).catch(function (error) {
      // handle error

    })


    let data = {
      "country_id": 1
    }
    SuperAdminApis.getAllStateTo('', data).then(
      (response: AxiosResponse<any>) => {
        if (response?.data) {
          setStateFrom(response?.data?.data)
        }
      }
    )

    SuperAdminApis.getAllShipments('', '').then(
      (response: any) => {
        if (response?.data) {
          setShipmentInfo(response?.data?.out_bound?.data);
          setmanifestListData(response?.data?.manifest)
         
        }
      }
    )
  }, []);




  const selectShipment = (e: any, datas: any) => {
    // Destructuring
    // 
    // console?.log(datas)
    const { value, checked } = e.target;
    const { shipids } = shipid;
    const { shipWeights } = shipWeight;

    // Case 1 : The user checks the box
    if (checked) {
      setShipId({
        shipids: [...shipids, value],
      });

      setShipWeight({
        shipWeights: [parseInt(shipWeights) + parseInt(datas?.Weight)],
      });
    }

    // Case 2  : The user unchecks the box
    else {
      setShipId({
        shipids: shipids.filter((e: any) => e !== value)
      });

      setShipWeight({
        shipWeights: [parseInt(shipWeights) - parseInt(datas?.Weight)],
      });
    }
  };

  const [manifestData, setManifestData] = React.useState<any>([]);
  //Vehicles
  const nextPage = React.useCallback((id: any) => {
    SuperAdminApis.getSingleManifest(id).then(
      (response: AxiosResponse<any>) => {
        if (response?.data) {
          setManifestData(response?.data?.data)
          setTabNo(2)
        }
      }
    ).catch(function (error) {
      // handle error

    })
  }, [manifestData, tabNo]);

  const getAllVehicles = React.useCallback((id: any) => {
    const query: any = {
      search: searchText,
    };
    SuperAdminApis.getAllVehicle('', query, id).then(
      (response: AxiosResponse<any>) => {
        if (response?.data) {
          setVehicle(response?.data?.data?.data)

        }
      }
    ).catch(function (error) {
      // handle error

    })
  }, [vehicle]);




  const paginator = React.useCallback(
    (value: any) => {
      //   setLoader(true);
      let value2 = '';
      if (value !== null) {
        value2 = value;
      } else {
        value2 = ''
      }
      const query: any = {
        search: searchText,
      };

      SuperAdminApis.getAllManifest(value2, query).then(
        (response: AxiosResponse<any>) => {
          if (response?.data) {
            setUserList(response?.data?.data)
          }
        }
      ).catch(function (error) {
        console.log(error.response.data);
      })

    }, [userLists, searchText]);


  const data = {
    date: '28/Apr/2024 08:00',
    carrier: 'TBA',
    docket: '01',
    route: 'Direct',
    shipments: [
      {
        connoteForm: '051490011326',
        shipper: 'Philip Test, Ikeja Lagos, NIGERIA',
        receiver: 'SKYNET NIGERIA, Abuja',
        goodsDesc: 'hair',
        weight: '5.000 Kg',
      },
      {
        connoteForm: '051490011327',
        shipper: 'philip test 2, Lagos, NIGERIA',
        receiver: 'SKYNET NIGERIA, Abuja',
        goodsDesc: 'shoe',
        weight: '10.000 Kg',
      },
      {
        connoteForm: '051490011327',
        shipper: 'philip test 2, Lagos, NIGERIA',
        receiver: 'SKYNET NIGERIA, Abuja',
        goodsDesc: 'shoe',
        weight: '10.000 Kg',
      },
      {
        connoteForm: '051490011327',
        shipper: 'philip test 2, Lagos, NIGERIA',
        receiver: 'SKYNET NIGERIA, Abuja',
        goodsDesc: 'shoe',
        weight: '10.000 Kg',
      },

    ],
  };

  const [shipType, setShipType] = React.useState<any>('in_bound');


  type StateMapping = {
    [key: string]: string[];
  };

  const stateMappings: StateMapping = {
    "Ibadan": ["Kwara", "Ondo", "Ekiti", "Ogun", "Osun", "Oyo"],
    "Port Harcourt": ["Edo", "Cross River", "Akwa Ibom", "Delta", "Bayelsa"],
    "Enugu": ["Abia", "Anambra", "Imo", "Delta Asaba", "Ebonyi"],
    "Abuja": [
      "Kano", "Niger", "Kaduna", "Adamawa", "Kebbi", "Gombe", "Borno",
      "Bauchi", "Sokoto", "Zamfara", "Benue", "Jigawa", "Katsina",
      "Plateau", "Nassarawa", "Kogi", "Taraba", "Yobe"
    ]
  };


  const capitalize = (str: string): string =>
    str.replace(/\b\w/g, char => char.toUpperCase());

  const isSubStateUnderMainState = (mainState: string, subState: string): boolean => {
    if (mainState && subState) {
      const capitalizedMainState = capitalize(mainState?.trim());
      const capitalizedSubState = capitalize(subState?.trim());

      if (stateMappings[capitalizedMainState]) {
        return stateMappings[capitalizedMainState]?.includes(capitalizedSubState);
      }

    }

    return false;

  };


  const handleExport = () => {
    const data: Manifests[] = userLists?.[shipType]?.data;

    exportToExcel(data);
  };


  return (
    <>
      <AdminSidebar title="Manifests" />
      <div className="relative md:ml-64 bg-[#FAFAFA] ">
        <AdminNavbar title="Manifest" />

        {tabNo == "1" ?
          <div className=' lg:px-10 pb-6 px-6 bg-[#FAFAFA] min-h-screen'>
            <div className='flex justify-between my-5'>
              <div>
                <h3 className='text-[15px] text-[#1C1C1C] font-semibold'>Manifest List</h3>

              </div>
              <div>
                {/* <button className='bg-[#E04343] text-white text-[14px] px-[12px] py-[8px] rounded-[10px]'>+ Add Customers</button> */}
                <div className='flex justify-end'>
                  <div className="relative flex w-full flex-wrap items-stretch">
                    <div className="absolute inset-y-0 right-2 flex items-center pl-3 pointer-events-none">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="#9da4aa" d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0a5.5 5.5 0 0 1 11 0z" /></svg>
                    </div>
                    <input type="text" onClick={() => paginator('')} onChange={e => setSearchText(e.target.value)} placeholder='search...' id="simple-search" className=" border border-gray-300 text-gray-500 text-sm rounded-md block w-full pl-4 p-1  " required />
                  </div>

                  <div className='mt-0.5 ml-2'><button type='button' onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => paginator('')} className={"font-normal text-white bg-[#E04343] px-3 py-0.5 rounded-md"}>Search</button> </div>

                  <div className='mt-0.5 ml-2'><button type='button' onClick={handleExport} className={"font-normal text-white bg-[#E04343] px-3 py-0.5 rounded-md"}>Export</button> </div>

                </div>




              </div>
            </div>
            <div className={'md:flex ' + ((userLoginData?.data?.role == 'fleet' || userLoginData?.data?.role == 'admin' || userLoginData?.data?.role == 'driver') ? ' justify-end' : ' justify-between') + ' mt-5'}>

              {(userLoginData?.data?.role == 'fleet' || userLoginData?.data?.role == 'admin' || userLoginData?.data?.role == 'driver' ) ?
                ''
                :
                <div className='flex md:justify-between justify-start gap-2'>


                  <button
                    onClick={() => setShipType('in_bound')}
                    type='button'
                    className={shipType == 'in_bound' ? 'bg-[#E04343] rounded-[12px] p-[4px] border-red-100 text-[12px] text-white' : 'bg-[#ffffff] border-red-500 border rounded-[12px] p-[4px] text-[12px] text-[#E04343]'}
                  >
                    In Bound
                  </button>


                  <button
                    onClick={() => setShipType('out_bound')}
                    type='button'
                    className={shipType == 'out_bound' ? 'bg-[#E04343] rounded-[12px] p-[4px] border-red-100 text-[12px] text-white' : 'bg-[#ffffff] border-red-500 border rounded-[12px] p-[4px] text-[12px] text-[#E04343]'}
                  >
                    Out Bound
                  </button>

                </div>
              }

              <div className='mt-0.5 ml-2'><button type='button' onClick={() => setAddModal(true)} className={"font-normal text-white bg-[#E04343] px-3 py-0.5 rounded-md"}>Create Manifest</button> </div>
            </div>


            <div className="relative overflow-x-auto">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 border-separate border-spacing-y-3">
                <thead className="text-[12px] text-[#030229] bg-[#FAFAFA] ">
                  <tr>

                    <th scope="col" className="px-2 py-2">
                      S/N
                    </th>

                    <th scope="col" className="px-2 py-2">
                      Manifest&nbsp;Name
                    </th>
                    <th scope="col" className="px-2 py-2">
                      Manifest &nbsp;Id
                    </th>
                    <th scope="col" className="px-2 py-2">
                      Origin State
                    </th>
                    <th scope="col" className="px-2 py-2">
                      Destination State
                    </th>

                    <th scope="col" className="px-2 py-2">
                      Shipment&nbsp;Status
                    </th>

                    <th scope="col" className="px-2 py-4">
                      Fleet
                    </th>

                    <th scope="col" className="px-2 py-4">
                      description
                    </th>

                    <th scope="col" className="px-2 py-4">
                      Pcs
                    </th>

                    <th scope="col" className="px-2 py-4">
                      Weight
                    </th>

                    <th scope="col" className="px-2 py-4">
                      Custom Value
                    </th>

                    <th scope="col" className="px-8 py-4">
                      Action
                    </th>


                  </tr>
                </thead>


                <tbody className=''>

                  {

                    (userLoginData?.data?.role == 'admin' || userLoginData?.data?.role == 'fleet' || userLoginData?.data?.role == 'driver' ?
                      (userLists?.data?.length > 0 ?
                        userLists?.data?.map(
                          (datas: any, index: any) => (
                            <tr className="bg-white  ">

                              <td className="px-2 py-2">
                                {index + 1}
                              </td>

                              <td className="px-2 py-4">
                                {datas?.manifest_name}
                              </td>

                              <td className="px-2 py-4">
                                {datas?.manifest_id}
                              </td>

                              <td className="px-2 py-4">
                                {datas?.sender_state}
                              </td>

                              <td className="px-2 py-4">
                                {datas?.destination_state}
                              </td>

                              <td className="px-2 py-4">
                                {datas?.shipment_status}
                              </td>

                              <td className="px-2 py-4">
                                {datas?.fleet_name}
                              </td>


                              <td className="px-2 py-4 ">
                                {datas?.goods_description}
                              </td>
                              <td className="px-2 py-4 ">
                                {datas?.pcs}
                              </td>

                              <td className="px-2 py-4 ">
                                {datas?.weight}
                              </td>

                              <td className="px-2 py-4 ">
                                {datas?.custom_value}
                              </td>



                              <td className='flex justify-between gap-3 px-6 py-4'>

                                {
                                  userLoginData?.data?.role == 'fleet' || userLoginData?.data?.role == 'driver' ?
                                    ''
                                    :
                                    <span className="hover:underline text-blue-500 cursor-pointer" onClick={() => { setRiderModal(true); setFleetData2(datas) }}>
                                      Edit
                                    </span>
                                }


                                <div>
                                  <PDFDownloadLink document={<ShipmentManifest data={datas} />} fileName="manifest.pdf">
                                    {({ loading }) => (loading ? 'Loading document...' : <MdDownload size={24} />)}
                                  </PDFDownloadLink>
                                </div>


                                <span className=" cursor-pointer" onClick={(e: any) => { nextPage(datas?.id); }}>
                                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="24" height="24" rx="4" fill="#EBEBEB" />
                                    <path d="M12 13.5C12.8284 13.5 13.5 12.8284 13.5 12C13.5 11.1716 12.8284 10.5 12 10.5C11.1716 10.5 10.5 11.1716 10.5 12C10.5 12.8284 11.1716 13.5 12 13.5Z" fill="#3F83F8" />
                                    <path d="M15.2536 9.30891C14.2289 8.60625 13.1323 8.25 11.9944 8.25C10.9704 8.25 9.97219 8.55469 9.02742 9.15187C8.07469 9.75539 7.1407 10.8516 6.375 12C6.99422 13.0312 7.84125 14.0916 8.72344 14.6995C9.73547 15.3966 10.8359 15.75 11.9944 15.75C13.1428 15.75 14.2409 15.3968 15.2592 14.7002C16.1557 14.0859 17.0088 13.027 17.625 12C17.0067 10.9821 16.1508 9.92437 15.2536 9.30891ZM12 14.25C11.555 14.25 11.12 14.118 10.75 13.8708C10.38 13.6236 10.0916 13.2722 9.92127 12.861C9.75097 12.4499 9.70642 11.9975 9.79323 11.561C9.88005 11.1246 10.0943 10.7237 10.409 10.409C10.7237 10.0943 11.1246 9.88005 11.561 9.79323C11.9975 9.70642 12.4499 9.75097 12.861 9.92127C13.2722 10.0916 13.6236 10.38 13.8708 10.75C14.118 11.12 14.25 11.555 14.25 12C14.2493 12.5965 14.012 13.1684 13.5902 13.5902C13.1684 14.012 12.5965 14.2493 12 14.25Z" fill="#3F83F8" />
                                  </svg>
                                </span>

                              </td>

                            </tr>
                          )
                        )
                        :
                        <div className='flex justify-start mt-4'>
                          <td >No Record Found</td>
                        </div>
                      )

                      :

                      (userLists?.[shipType]?.data?.length > 0 ?
                        userLists?.[shipType]?.data?.map(
                          (datas: any, index: any) => (
                            <tr className="bg-white  ">

                              <td className="px-2 py-2">
                                {index + 1}
                              </td>

                              <td className="px-2 py-4">
                                {datas?.manifest_name}
                              </td>

                              <td className="px-2 py-4">
                                {datas?.manifest_id}
                              </td>

                              <td className="px-2 py-4">
                                {datas?.sender_state}
                              </td>

                              <td className="px-2 py-4">
                                {datas?.destination_state}
                              </td>

                              <td className="px-2 py-4">
                                {datas?.shipment_status}
                              </td>

                              <td className="px-2 py-4">
                                {datas?.fleet_name}
                              </td>


                              <td className="px-2 py-4 ">
                                {datas?.goods_description}
                              </td>
                              <td className="px-2 py-4 ">
                                {datas?.pcs}
                              </td>

                              <td className="px-2 py-4 ">
                                {datas?.weight}
                              </td>

                              <td className="px-2 py-4 ">
                                {datas?.custom_value}
                              </td>



                              <td className='flex justify-between gap-3 px-6 py-4'>
                                <span className="hover:underline text-blue-500 cursor-pointer" onClick={() => { setRiderModal(true); setFleetData2(datas) }}>
                                  Edit
                                </span>

                                <div>
                                  <PDFDownloadLink document={<ShipmentManifest data={datas} />} fileName="manifest.pdf">
                                    {({ loading }) => (loading ? 'Loading document...' : <MdDownload size={24} />)}
                                  </PDFDownloadLink>
                                </div>


                                <span className=" cursor-pointer" onClick={(e: any) => { nextPage(datas?.id); }}>
                                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="24" height="24" rx="4" fill="#EBEBEB" />
                                    <path d="M12 13.5C12.8284 13.5 13.5 12.8284 13.5 12C13.5 11.1716 12.8284 10.5 12 10.5C11.1716 10.5 10.5 11.1716 10.5 12C10.5 12.8284 11.1716 13.5 12 13.5Z" fill="#3F83F8" />
                                    <path d="M15.2536 9.30891C14.2289 8.60625 13.1323 8.25 11.9944 8.25C10.9704 8.25 9.97219 8.55469 9.02742 9.15187C8.07469 9.75539 7.1407 10.8516 6.375 12C6.99422 13.0312 7.84125 14.0916 8.72344 14.6995C9.73547 15.3966 10.8359 15.75 11.9944 15.75C13.1428 15.75 14.2409 15.3968 15.2592 14.7002C16.1557 14.0859 17.0088 13.027 17.625 12C17.0067 10.9821 16.1508 9.92437 15.2536 9.30891ZM12 14.25C11.555 14.25 11.12 14.118 10.75 13.8708C10.38 13.6236 10.0916 13.2722 9.92127 12.861C9.75097 12.4499 9.70642 11.9975 9.79323 11.561C9.88005 11.1246 10.0943 10.7237 10.409 10.409C10.7237 10.0943 11.1246 9.88005 11.561 9.79323C11.9975 9.70642 12.4499 9.75097 12.861 9.92127C13.2722 10.0916 13.6236 10.38 13.8708 10.75C14.118 11.12 14.25 11.555 14.25 12C14.2493 12.5965 14.012 13.1684 13.5902 13.5902C13.1684 14.012 12.5965 14.2493 12 14.25Z" fill="#3F83F8" />
                                  </svg>
                                </span>

                              </td>

                            </tr>
                          )
                        )
                        :
                        <div className='flex justify-start mt-4'>
                          <td >No Record Found</td>
                        </div>)
                    )




                  }

                </tbody>
              </table>
            </div>

            {userLoginData?.data?.role == 'admin' || userLoginData?.data?.role == 'fleet' || userLoginData?.data?.role == 'driver' ?

              <div className='m-4 flex justify-end mt-20'>
                {
                  userLists?.links?.filter(((item: any, idx: any) => idx < 1000)).map(
                    (datas: any, index: any) => (
                      <button onClick={() => paginator(datas?.label == 'Next &raquo;' ? datas?.url?.charAt(datas?.url?.length - 1) : (datas?.label === '&laquo; Previous') ? datas?.url?.charAt(datas?.url?.length - 1) : datas?.label)} disabled={datas?.active} className={'mx-1 py-1 px-2 ' + ((datas?.active == false || datas?.url == null) ? 'bg-gray-300 text-black ' : 'bg-[#E04343] text-white')}>
                        {datas?.label == '&laquo; Previous' ? '< Previous' : (datas?.label === 'Next &raquo;') ? 'Next  >' : datas?.label}
                      </button>
                    )
                  )
                }

              </div>
              :
              <div className='m-4 flex justify-end mt-20'>
                {
                  userLists?.[shipType]?.links?.filter(((item: any, idx: any) => idx < 1000)).map(
                    (datas: any, index: any) => (
                      <button onClick={() => paginator(datas?.label == 'Next &raquo;' ? datas?.url?.charAt(datas?.url?.length - 1) : (datas?.label === '&laquo; Previous') ? datas?.url?.charAt(datas?.url?.length - 1) : datas?.label)} disabled={datas?.active} className={'mx-1 py-1 px-2 ' + ((datas?.active == false || datas?.url == null) ? 'bg-gray-300 text-black ' : 'bg-[#E04343] text-white')}>
                        {datas?.label == '&laquo; Previous' ? '< Previous' : (datas?.label === 'Next &raquo;') ? 'Next  >' : datas?.label}
                      </button>
                    )
                  )
                }

              </div>
            }
          </div>
          :
          ''
        }

        {tabNo == "2" ?
          <div className=' lg:px-10 pb-6 px-6 bg-[#FAFAFA] min-h-screen'>

            <div className='flex justify-between my-5'>
              <div>
                <h3 className='text-[15px] text-[#1C1C1C] font-semibold capitalize'>{fleetData?.first_name} Shipments under {manifestData?.manifest?.manifest_name} List</h3>

              </div>
              <div>
                {/* <button className='bg-[#E04343] text-white text-[14px] px-[12px] py-[8px] rounded-[10px]'>+ Add Customers</button> */}
                <div className='flex justify-end'>
                  <div className="relative flex w-full flex-wrap items-stretch">
                    <div className="absolute inset-y-0 right-2 flex items-center pl-3 pointer-events-none">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="#9da4aa" d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0a5.5 5.5 0 0 1 11 0z" /></svg>
                    </div>
                    <input type="text" onClick={() => paginator('')} onChange={e => setSearchText(e.target.value)} placeholder='search...' id="simple-search" className=" border border-gray-300 text-gray-500 text-sm rounded-md block w-full pl-4 p-1  " required />
                  </div>

                  <div className='mt-0.5 ml-2'><button type='button' onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => paginator('')} className={"font-normal text-white bg-[#E04343] px-3 py-0.5 rounded-md"}>Search</button> </div>

                </div>




              </div>

            </div>

            <div className='flex justify-start mt-4'>
              <div className='mt-0.5 ml-2'><button type='button' onClick={() => setTabNo(1)} className={"font-normal text-white bg-[#E04343] px-3 py-0.5 rounded-md"}>Go back</button> </div>
            </div>
            <div className="relative overflow-x-auto">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 border-separate border-spacing-y-3">
                <thead className="text-[12px] text-[#808080] bg-[#FAFAFA] ">
                  <tr>

                    <th scope="col" className="px-2 py-2">
                      S/N
                    </th>
                    <th scope="col" className="px-2 py-2">
                      Reciever&nbsp;Name
                    </th>
                    <th scope="col" className="px-2 py-2">
                      Reciever&nbsp;Phone&nbsp;Number
                    </th>
                    <th scope="col" className="px-2 py-2">
                      Reciever&nbsp;Address
                    </th>
                    <th scope="col" className="px-2 py-2">
                      Status
                    </th>

                    <th scope="col" className="px-2 py-2">
                      Shipment&nbsp;Number
                    </th>

                    <th scope="col" className="px-2 py-2">
                      Fleet
                    </th>

                    <th scope="col" className="px-2 py-2">
                      Vehicle
                    </th>

                  </tr>
                </thead>


                <tbody className=''>

                  {
                    manifestData?.shipments?.length > 0 ?
                      manifestData?.shipments?.map(
                        (datas: any, index: any) => (
                          <tr className="bg-white  ">

                            <td className="px-2 py-2">
                              {index + 1}
                            </td>
                            <td className="px-6 py-4">
                              {datas?.ReceiverName}
                            </td>
                            <td className="px-6 py-4">
                              {datas?.ReceiverPhone}
                            </td>

                            <td className="px-6 py-4">
                              {datas?.ReceiverAdd1}
                            </td>

                            <td className="px-2 py-2 cursor-pointer" >
                              {(datas?.Status == 'inReview' || datas?.Status == 'InReview') ? <p><b style={{ fontWeight: "500", fontSize: '14px', backgroundColor: '#FFDDBD', color: '#F29339', borderRadius: '10px', padding: '2px 10px' }}>{datas?.Status}</b></p> : <p><b style={{ fontWeight: "500", fontSize: '14px', backgroundColor: '#C9F0D0', color: '#51CF66', borderRadius: '10px', padding: '2px 10px' }}>{datas?.Status}</b></p>}
                            </td>

                            <td className="px-6 py-4 ">
                              {datas?.ShipmentNumber ? datas?.ShipmentNumber : `Not Available`}
                            </td>

                            <td className="px-6 py-4 cursor-pointer">
                              {datas?.fleet_name ? datas?.fleet_name : "No Fleet"}
                            </td>

                            <td className="px-6 py-4" >
                              {datas?.vehicle_name ? datas?.vehicle_name : "No Vehicle"}
                            </td>

                          </tr>
                        )
                      )
                      :
                      <div className='flex justify-start mt-4'>
                        <td >No Record Found</td>
                      </div>


                  }

                </tbody>
              </table>
            </div>

          </div>
          :
          ''
        }
      </div>


      <section>
        <Modal
          visible={addModal}
          width="900"
          height="700"
          effect="fadeInUp"
          onClickAway={() => setAddModal(false)}
        >

          {
            loading ?
              <div className="flex justify-center mt-[30vh]">
                <Oval
                  visible={true}
                  height="80"
                  width="80"
                  color="#E04343"
                  secondaryColor="#FCBF94"
                  ariaLabel="oval-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </div>
              :
              <div className=" " style={{ height: '100%', overflow: 'auto' }}>

                <div className="container  bg-[#fff] rounded-lg p-2">

                  <div className="px-3">

                    {/* <span className="flex justify-around">
                      <h1 className=" text-xs text-red-600" style={{ fontSize: '10px' }}>Link can’t be edited in free plan. <span style={{ color: '#61A24F' }} className="font-bold text-xs">Upgrade to Pro</span></h1>
                    </span> */}
                    <span className="flex justify-between px-2 pt-3">
                      <p className='mt-2'>Create New Manifest</p>
                      <p className="cursor-pointer font-bold" onClick={(e) => setAddModal(false)}><SvgElement type={icontypesEnum.CANCEL} /></p>
                    </span>

                    <form onSubmit={handleSubmit}>
                      <div className="flex flex-col lg:flex-row lg:justify-between max-w-screen-xl mx-auto  mt-5">
                        <div className="relative flex flex-col min-w-0 break-words w-full  ">
                          <div className="flex-auto   py-5 pt-0">
                            <div className="flex flex-wrap ">

                              <div className="lg:w-4/12 w-full lg:pr-4">
                                <div className="flex justify-between mb-3 ">
                                  <label className="block text-sm font-semibold text-[#19213D]">Manifest Name</label>
                                  <SvgElement type={icontypesEnum.QUESTIONMARK} />
                                </div>
                                <div className="relative mb-4">
                                  <input type="text" name='manifest_name' onChange={(e: any) => handleChange(e)} className=" border border-[#D1D1D1] text-gray-900 text-sm rounded-[5px] block w-full pl-6 p-2.5" placeholder="" />
                                </div>
                              </div>

                              <div className="lg:w-8/12 w-full lg:pr-4">
                                <div className="flex justify-between mb-3">
                                  <label className="block text-sm font-semibold text-[#19213D]">Goods Decription</label>
                                  <SvgElement type={icontypesEnum.QUESTIONMARK} />
                                </div>
                                <div className="relative mb-4">
                                  <textarea name='goods_description' onChange={(e: any) => handleChange(e)} className=" border border-[#D1D1D1] text-gray-900 text-sm rounded-[5px] block w-full pl-6 p-2.5" placeholder="" />
                                </div>
                              </div>


                              <div className="w-full lg:w-3/12 lg:pr-4">
                                <div className="relative w-full mb-5">
                                  <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                    Destination State
                                  </label>
                                  <select id="countries" name='destination_state' onChange={(e: any) => handleChange(e)} className="border border-[#D1D1D1] bg-white text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150">
                                    <option disabled selected>--Select State--</option>
                                    {stateFrom?.data?.filter((data: any) => data?.client_id == null)?.map((datas: any, index: any) => (
                                      <option value={datas?.state_name}>{datas?.state_name}</option>
                                    )
                                    )}
                                  </select>

                                </div>
                              </div>


                              <div className="w-full lg:w-3/12 lg:pr-4">
                                <div className="relative w-full mb-5">
                                  <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                    Select Status
                                  </label>
                                  <select id="countries" name='shipment_status' onChange={(e: any) => handleChange(e)} className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-r-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150">
                                    {
                                      STATUSTYPES?.map((datas: any, index: any) => (
                                        <option value={datas?.EventName}>{datas?.EventName}</option>
                                      )
                                      )}
                                  </select>
                                </div>
                              </div>


                              <div className="w-full lg:w-3/12 lg:pr-4">
                                <div className="relative w-full mb-5">
                                  <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                    Select Fleet
                                  </label>
                                  <select required id="countries" onChange={(e: any) => { setShipmentRider(e); getAllVehicles(e?.target?.value) }} className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-r-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150">
                                    <option selected>{'Select Fleet'}</option>

                                    {riders?.map((datas: any, index: any) => (
                                      <option value={datas?.id}>{datas?.first_name}</option>
                                    )
                                    )}
                                  </select>
                                </div>
                              </div>

                              <div className="w-full lg:w-3/12 ">
                                <div className="relative w-full mb-5">
                                  <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                    Select Vehicle
                                  </label>
                                  <select required id="countries" name='vehicle_name' onChange={(e: any) => handleChange(e)} className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-r-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150">
                                    <option selected>{'Select Vehicle'}</option>
                                    {vehicle?.filter((data: any) => data?.status == 'Available')?.map((datas: any, index: any) => (
                                      <option value={datas?.vehicle_name}>{datas?.vehicle_name}</option>
                                    )
                                    )}
                                  </select>
                                </div>
                              </div>


                              <div className="lg:w-3/12 w-full lg:pr-4">
                                <div className="flex justify-between mb-3">
                                  <label className="block mb-2 text-sm font-semibold text-[#19213D]">Fleet Price</label>
                                  <SvgElement type={icontypesEnum.QUESTIONMARK} />
                                </div>
                                <div className="relative mb-4">
                                  <input type="number" name='fleet_price' onChange={(e: any) => handleChange(e)} className=" border border-[#D1D1D1] text-gray-900 text-sm rounded-[5px] block w-full pl-6 p-2.5" placeholder="" />
                                </div>
                              </div>


                              <div className="lg:w-3/12 w-full lg:pr-4">
                                <div className="flex justify-between mb-3">
                                  <label className="block mb-2 text-sm font-semibold text-[#19213D]">No. of Items</label>
                                  <SvgElement type={icontypesEnum.QUESTIONMARK} />
                                </div>
                                <div className="relative mb-4">
                                  <input type="number" name='pcs' disabled value={shipid?.shipids?.length} onChange={(e: any) => handleChange(e)} className=" border border-[#D1D1D1] text-gray-900 text-sm rounded-[5px] block w-full pl-6 p-2.5" placeholder="" />
                                </div>
                              </div>

                              <div className="lg:w-3/12 w-full lg:pr-4">
                                <div className="flex justify-between mb-3">
                                  <label className="block mb-2 text-sm font-semibold text-[#19213D]">Weight (KG)</label>
                                  <SvgElement type={icontypesEnum.QUESTIONMARK} />
                                </div>
                                <div className="relative mb-4">
                                  <input type="text" disabled name='weight' value={shipWeight?.shipWeights[0]} onChange={(e: any) => handleChange(e)} className=" border border-[#D1D1D1] text-gray-900 text-sm rounded-[5px] block w-full pl-6 p-2.5" placeholder="" />
                                </div>
                              </div>

                              <div className="lg:w-3/12 w-full lg:pr-4">
                                <div className="flex justify-between mb-3">
                                  <label className="block mb-2 text-sm font-semibold text-[#19213D]">Cust. Value</label>
                                  <SvgElement type={icontypesEnum.QUESTIONMARK} />
                                </div>
                                <div className="relative mb-4">
                                  <input type="text" name='custom_value' onChange={(e: any) => handleChange(e)} className=" border border-[#D1D1D1] text-gray-900 text-sm rounded-[5px] block w-full pl-6 p-2.5" placeholder="" />
                                </div>
                              </div>



                            </div>
                          </div>
                        </div>
                      </div>



                      <div className=" h-[250px] overflow-auto">
                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 border-separate border-spacing-y-3">
                          <thead className="text-[12px] text-[#808080] bg-[#FAFAFA] ">
                            <tr>

                              <th scope="col" className="px-2 py-2">
                                S/N
                              </th>
                              <th scope="col" className="px-2 py-2">
                                Reciever&nbsp;Name
                              </th>
                              <th scope="col" className="px-2 py-2">
                                Reciever&nbsp;Phone&nbsp;Number
                              </th>
                              <th scope="col" className="px-2 py-2">
                                Reciever&nbsp;Address
                              </th>
                              <th scope="col" className="px-2 py-2">
                                Status
                              </th>

                              <th scope="col" className="px-2 py-2">
                                Shipment&nbsp;Number
                              </th>

                              <th scope="col" className="px-2 py-2">
                                Fleet
                              </th>

                              <th scope="col" className="px-2 py-2">
                                Vehicle
                              </th>

                            </tr>
                          </thead>
                          <tbody className=''>

                            {manifestListData?.filter((e: any) => (e?.ShipmentNumber))?.map(
                              (datas: any, index: any) => (
                                <tr className="bg-white  ">

                                  {/* <td className="px-2 py-2">
                                  {index + 1}
                                </td> */}

                                  <td scope="row" className="px-6 py-4 font-medium text-[#6C757D] whitespace-nowrap">
                                    <div className="flex space-x-3">
                                      <div className="flex items-center ">
                                        <input id="default-checkbox" type="checkbox" value={datas?.ShipmentNumber} onChange={(e) => selectShipment(e, datas)} className="w-4 h-4 accent-[#E04343] bg-gray-100 border-gray-300 rounded focus:ring-[#E04343] cursor-pointer" />
                                      </div>
                                    </div>
                                  </td>
                                  <td className="px-6 py-4">
                                    {datas?.ReceiverName}
                                  </td>
                                  <td className="px-6 py-4">
                                    {datas?.ReceiverPhone}
                                  </td>

                                  <td className="px-6 py-4">
                                    {datas?.ReceiverAdd1}
                                  </td>

                                  <td className="px-2 py-2 cursor-pointer">
                                    {(datas?.Status == 'inReview' || datas?.Status == 'InReview') ? <p><b style={{ fontWeight: "500", fontSize: '14px', backgroundColor: '#FFDDBD', color: '#F29339', borderRadius: '10px', padding: '2px 10px' }}>{datas?.Status}</b></p> : <p><b style={{ fontWeight: "500", fontSize: '14px', backgroundColor: '#C9F0D0', color: '#51CF66', borderRadius: '10px', padding: '2px 10px' }}>{datas?.Status}</b></p>}
                                  </td>

                                  <td className="px-6 py-4 ">
                                    {datas?.ShipmentNumber ? datas?.ShipmentNumber : `Not Available`}
                                  </td>

                                  <td className="px-6 py-4 cursor-pointer">
                                    {datas?.fleet_name ? datas?.fleet_name : "No Fleet"}
                                  </td>

                                  <td className="px-6 py-4" >
                                    {datas?.vehicle_name ? datas?.vehicle_name : "No Vehicle"}
                                  </td>

                                </tr>
                              )
                            )
                            }
                          </tbody>
                        </table>

                      </div>

                      <div className="flex justify-end pb-8">
                        <div className="mt-1 ">
                          <button disabled={loading} type="submit" className="text-white bg-[#E04343] rounded-[30px] transition ease-out delay-300 py-[10px] px-[42px] font-[700] text-[14px]">


                            <div className="flex justify-center gap-3 ">
                              <span>Create Manifest</span>
                              <Oval
                                visible={loading}
                                height="20"
                                width="20"
                                color="#E04343"
                                secondaryColor="#FCBF94"
                                ariaLabel="oval-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                              />
                            </div>
                          </button>
                        </div>

                      </div>
                    </form>
                  </div>

                </div>

              </div>

          }

        </Modal>
      </section>


      <section>
        <Modal
          visible={riderModal}
          width="900"
          height="500"
          effect="fadeInUp"
          onClickAway={() => setRiderModal(false)}
        >

          {
            loading ?
              <div className="flex justify-center mt-[20vh]">
                <Oval
                  visible={true}
                  height="80"
                  width="80"
                  color="#E04343"
                  secondaryColor="#FCBF94"
                  ariaLabel="oval-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </div>
              :
              <div className=" " style={{ height: '100%', overflow: 'auto' }}>

                <div className="container  bg-[#fff] rounded-lg p-2">

                  <div className="px-3">

                    {/* <span className="flex justify-around">
                      <h1 className=" text-xs text-red-600" style={{ fontSize: '10px' }}>Link can’t be edited in free plan. <span style={{ color: '#61A24F' }} className="font-bold text-xs">Upgrade to Pro</span></h1>
                    </span> */}
                    <span className="flex justify-between px-2 pt-3">
                      <p className='mt-2'>Update Manifest</p>
                      <p className="cursor-pointer font-bold" onClick={(e) => setRiderModal(false)}><SvgElement type={icontypesEnum.CANCEL} /></p>
                    </span>

                    <form onSubmit={updateManifest}>
                      <div className="flex flex-col lg:flex-row lg:justify-between max-w-screen-xl mx-auto  mt-5">
                        <div className="relative flex flex-col min-w-0 break-words w-full  ">
                          <div className="flex-auto   py-5 pt-0">
                            <div className="flex flex-wrap ">

                              <div className="lg:w-4/12 w-full lg:pr-4">
                                <div className="flex justify-between mb-3 ">
                                  <label className="block text-sm font-semibold text-[#19213D]">Manifest Name</label>
                                  <SvgElement type={icontypesEnum.QUESTIONMARK} />
                                </div>
                                <div className="relative mb-4">
                                  <input type="text" name='manifest_name' defaultValue={fleetData2?.manifest_name} onChange={(e: any) => handleChange2(e)} className=" border border-[#D1D1D1] text-gray-900 text-sm rounded-[5px] block w-full pl-6 p-2.5" placeholder="" />
                                </div>
                              </div>

                              <div className="lg:w-8/12 w-full lg:pr-4">
                                <div className="flex justify-between mb-3">
                                  <label className="block text-sm font-semibold text-[#19213D]">Goods Decription</label>
                                  <SvgElement type={icontypesEnum.QUESTIONMARK} />
                                </div>
                                <div className="relative mb-4">
                                  <textarea name='goods_description' defaultValue={fleetData2?.goods_description} onChange={(e: any) => handleChange2(e)} className=" border border-[#D1D1D1] text-gray-900 text-sm rounded-[5px] block w-full pl-6 p-2.5" placeholder="" />
                                </div>
                              </div>


                              <div className="w-full lg:w-3/12 lg:pr-4">
                                <div className="relative w-full mb-5">
                                  <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                    Destination State
                                  </label>
                                  <select id="countries" name='destination_state' onChange={(e: any) => handleChange2(e)} className="border border-[#D1D1D1] bg-white text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150">
                                    <option value={fleetData2?.destination_state}>{fleetData2?.destination_state}</option>
                                    {stateFrom?.data?.filter((data: any) => data?.client_id == null)?.map((datas: any, index: any) => (
                                      <option value={datas?.state_name}>{datas?.state_name}</option>
                                    )
                                    )}
                                  </select>

                                </div>
                              </div>


                              <div className="w-full lg:w-3/12 lg:pr-4">
                                <div className="relative w-full mb-5">
                                  <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                    Select Status
                                  </label>
                                  <select id="countries" name='shipment_status' onChange={(e: any) => handleChange2(e)} className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-r-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150">
                                    <option value={fleetData2?.shipment_status}>{fleetData2?.shipment_status}</option>
                                    {
                                      STATUSTYPES?.map((datas: any, index: any) => (
                                        <option value={datas?.EventName}>{datas?.EventName}</option>
                                      )
                                      )}
                                  </select>
                                </div>
                              </div>


                              <div className="w-full lg:w-3/12 lg:pr-4">
                                <div className="relative w-full mb-5">
                                  <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                    Select Fleet
                                  </label>
                                  <select id="countries" onChange={(e: any) => { handleChange2(e); getAllVehicles(e?.target?.value) }} className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-r-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150">
                                    <option value={fleetData2?.fleet_name}>{fleetData2?.fleet_name}</option>

                                    {riders?.map((datas: any, index: any) => (
                                      <option value={datas?.id}>{datas?.first_name}</option>
                                    )
                                    )}
                                  </select>
                                </div>
                              </div>

                              <div className="w-full lg:w-3/12 ">
                                <div className="relative w-full mb-5">
                                  <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                    Select Vehicle
                                  </label>
                                  <select id="countries" name='vehicle_name' onChange={(e: any) => handleChange2(e)} className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-r-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150">
                                    <option value={fleetData2?.vehicle_name}>{fleetData2?.vehicle_name}</option>
                                    {vehicle?.filter((data: any) => data?.status == 'Available')?.map((datas: any, index: any) => (
                                      <option value={datas?.vehicle_name}>{datas?.vehicle_name}</option>
                                    )
                                    )}
                                  </select>
                                </div>
                              </div>


                              <div className="lg:w-3/12 w-full lg:pr-4">
                                <div className="flex justify-between mb-3">
                                  <label className="block mb-2 text-sm font-semibold text-[#19213D]">Fleet Price</label>
                                  <SvgElement type={icontypesEnum.QUESTIONMARK} />
                                </div>
                                <div className="relative mb-4">
                                  <input type="number" name='fleet_price' defaultValue={fleetData2?.fleet_price} onChange={(e: any) => handleChange2(e)} className=" border border-[#D1D1D1] text-gray-900 text-sm rounded-[5px] block w-full pl-6 p-2.5" placeholder="" />
                                </div>
                              </div>


                              <div className="lg:w-3/12 w-full lg:pr-4">
                                <div className="flex justify-between mb-3">
                                  <label className="block mb-2 text-sm font-semibold text-[#19213D]">No. of Items</label>
                                  <SvgElement type={icontypesEnum.QUESTIONMARK} />
                                </div>
                                <div className="relative mb-4">
                                  <input type="number" name='pcs' defaultValue={fleetData2?.pcs} onChange={(e: any) => handleChange2(e)} className=" border border-[#D1D1D1] text-gray-900 text-sm rounded-[5px] block w-full pl-6 p-2.5" placeholder="" />
                                </div>
                              </div>

                              <div className="lg:w-3/12 w-full lg:pr-4">
                                <div className="flex justify-between mb-3">
                                  <label className="block mb-2 text-sm font-semibold text-[#19213D]">Weight (KG)</label>
                                  <SvgElement type={icontypesEnum.QUESTIONMARK} />
                                </div>
                                <div className="relative mb-4">
                                  <input type="text" name='weight' defaultValue={fleetData2?.weight} onChange={(e: any) => handleChange2(e)} className=" border border-[#D1D1D1] text-gray-900 text-sm rounded-[5px] block w-full pl-6 p-2.5" placeholder="" />
                                </div>
                              </div>

                              <div className="lg:w-3/12 w-full lg:pr-4">
                                <div className="flex justify-between mb-3">
                                  <label className="block mb-2 text-sm font-semibold text-[#19213D]">Cust. Value</label>
                                  <SvgElement type={icontypesEnum.QUESTIONMARK} />
                                </div>
                                <div className="relative mb-4">
                                  <input type="text" name='custom_value' defaultValue={fleetData2?.custom_value} onChange={(e: any) => handleChange2(e)} className=" border border-[#D1D1D1] text-gray-900 text-sm rounded-[5px] block w-full pl-6 p-2.5" placeholder="" />
                                </div>
                              </div>



                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="flex justify-end pb-8">
                        <div className="mt-1 ">
                          <button type="submit" className="text-white bg-[#E04343] rounded-[30px] transition ease-out delay-300 py-[10px] px-[42px] font-[700] text-[14px]">
                            <div className="flex justify-center gap-3 ">
                              <span>Update Manifest</span>
                              <Oval
                                visible={loading}
                                height="20"
                                width="20"
                                color="#E04343"
                                secondaryColor="#FCBF94"
                                ariaLabel="oval-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                              />
                            </div>
                          </button>
                        </div>

                      </div>
                    </form>
                  </div>

                </div>

              </div>

          }

        </Modal>
      </section>



      {/* Add Vehicle */}




      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
    </>
  )
}

export default Manifest