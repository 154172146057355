import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import { Dispatch } from "redux";
import { useSelector, useDispatch } from 'react-redux';
import { login } from '../../../reducer/loginSlice'
import { AxiosResponse, AxiosError } from "axios";
import axios from "axios";
import { AuthLiveApis } from "../../../apis/live/authLiveApis";
import { AuthApis } from "../../../apis/authApis";
import { store } from "../../../store/store";
import { History } from "history";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from "../../Navbars/Navbar";
import { Oval } from "react-loader-spinner";


function StaffLogin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();

  // How to access the redux store
  const userLoginData = useSelector((state: any) => state.data.login.value);

  // This is used to update the store
  const dispatch: Dispatch = useDispatch();

  const handleSubmit = React.useCallback(
    (e: React.ChangeEvent<HTMLFormElement>) => {
      e.preventDefault();
      setLoader(true)
      const formData = new FormData()

      formData.append('email', email)
      formData.append('password', password)

      AuthApis.login(formData).then(
        (response: AxiosResponse<any>) => {
          if (response?.data) {
            if (response?.data?.status === true) {
              if (response.data?.data?.isVerified == 'false') {
                navigate('/email-verify');
              } else {
                dispatch(login({ email: email, token: response.data.token, name: response.data.name, data: response.data?.data }))
                {
                  response.data?.data?.role !== 'user'
                    ?
                    (response.data?.data?.role !== 'admin' ? navigate('/admin/dashboard') : navigate('/admin/dashboard'))
                    :
                    navigate('/staff-login')
                }
              }


            }
          } else {
            setLoader(false)
            toast.warn('Invalid Login Credentials');
          }

          toast.success(response?.data?.message);
        }
      ).catch(function (error) {
        // handle error
        setLoader(false)
        console.log(error.response.data);
        toast.error("Offfline");
      }).finally(() => {
        setLoader(false);
        // toast.error("No Internet Connection");

      });
    },
    [email, password, loader]
  );



  return (
    <>

      <div className="flex flex-row">
        <div className="lg:basis-2/5 basis-full relative">
          <div className="h-screen bg-white lg:pl-[90px] lg:pr-[80px] pl-[30px] pr-[20px] pt-[20px] md:pt-[100px] lg:pb-[40px]">
            <NavLink to='/'>
              <img src="/images/Auth/cflex.jpeg" alt="hero"/>
            </NavLink>

            <div className="">
            <p className="text-[#404040] lg:text-[34px] text-[30px] font-[600] mt-3">Welcome to CFlexFlow</p>
            <p className="text-[#404040] lg:text-[14px] text-[12px] font-[400] mb-10">Your Comprehensive Shipping and Management Platform</p>
            <p className="text-[#404040] lg:text-[11px] text-[9px] font-[400] mt-3">CFlexFlow seamlessly integrates Skynet eTrack, GetOnline, and more, offering a unified solution for all your shipping needs. With features including billing, expense vouchers and expense tracking, fleet management, and shipment management, CFlexFlow empowers you to efficiently handle every aspect of logistics.</p>
              {/* <h1 className="text-[#42505C] lg:text-[54px] text-[44px] font-[700]">Log in</h1> */}
             
            </div>
            {/* <hr className=""/> */}
            <div className="mt-10">
              <div className="flex justify-between mb-3">
                <h3 className="text-[#333333] text-[12px]">Account</h3>
                <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path opacity="0.1" d="M9.15022 0C4.51085 0 0.75 3.58176 0.75 8.00021C0.750057 9.05081 0.967392 10.0911 1.3896 11.0617C1.8118 12.0323 2.4306 12.9142 3.21068 13.6571C3.99075 14.3999 4.91681 14.9892 5.936 15.3912C6.95518 15.7932 8.04752 16.0001 9.15065 16C10.2538 15.9999 11.3461 15.793 12.3652 15.3909C13.3844 14.9888 14.3104 14.3994 15.0904 13.6565C15.8704 12.9136 16.4891 12.0316 16.9112 11.061C17.3333 10.0903 17.5505 9.04998 17.5504 7.99937C17.5504 3.58176 13.7887 0 9.15022 0ZM9.01809 12.6662H8.97346C8.2892 12.647 7.80618 12.1662 7.82631 11.5236C7.84556 10.8919 8.33995 10.4328 9.00146 10.4328L9.04172 10.4344C9.74523 10.4536 10.223 10.9295 10.2029 11.5903C10.1827 12.2237 9.69711 12.6662 9.01809 12.6662ZM11.8978 7.22352C11.7368 7.44019 11.3833 7.71187 10.937 8.04271L10.4453 8.36522C10.1757 8.56522 10.013 8.7544 9.95261 8.93857C9.90361 9.08357 9.88086 9.12274 9.87649 9.41858V9.49358H7.99956L8.00481 9.34191C8.02844 8.72189 8.04419 8.35522 8.31457 8.05271C8.73896 7.57936 9.67523 7.00435 9.71461 6.98018C9.84305 6.89081 9.95511 6.78184 10.0462 6.65767C10.2431 6.3985 10.3297 6.19516 10.3297 5.99682C10.3297 5.71848 10.244 5.46098 10.0734 5.23347C9.90886 5.0118 9.59648 4.90179 9.14497 4.90179C8.69696 4.90179 8.38982 5.0368 8.20694 5.31514C8.01794 5.59931 7.92256 5.89849 7.92256 6.20433V6.281H5.98789L5.99139 6.20099C6.04126 5.07347 6.46477 4.26094 7.24704 3.78676C7.73968 3.48592 8.35307 3.33342 9.06797 3.33342C10.0025 3.33342 10.7935 3.55009 11.4148 3.97677C12.0457 4.40928 12.3651 5.05763 12.3651 5.90265C12.3642 6.37517 12.2075 6.81934 11.8978 7.22352Z" fill="black" />
                </svg>

              </div>
              <form onSubmit={handleSubmit}>
                <div className="relative mb-4">
                  <div className="absolute inset-y-0 start-0 flex items-center pl-3.5 pointer-events-none">
                    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path opacity="0.1" d="M8.85019 0C11.1707 0 13.0502 1.79 13.0502 4C13.0502 6.21 11.1707 8 8.85019 8C6.5297 8 4.6502 6.21 4.6502 4C4.6502 1.79 6.5297 0 8.85019 0ZM8.85019 16C8.85019 16 17.2502 16 17.2502 14C17.2502 11.6 13.1552 9 8.85019 9C4.5452 9 0.450195 11.6 0.450195 14C0.450195 16 8.85019 16 8.85019 16Z" fill="black" />
                    </svg>

                  </div>
                  <input type="text" name="email" onChange={(e) => setEmail(e?.target?.value)} className=" border border-[#D1D1D1] text-gray-900 text-sm rounded-[10px] block w-full pl-10 p-2.5" placeholder="email" />
                </div>

                <div className="relative mb-4">
                  <div className="absolute inset-y-0 start-0 flex items-center pl-3.5 pointer-events-none">
                    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path opacity="0.1" d="M17.1501 5.50003C17.1501 8.53759 14.5645 11 11.3751 11C11.0069 11 10.647 10.9668 10.298 10.9041L9.51007 11.7483C9.43618 11.8274 9.34553 11.8908 9.24408 11.9342C9.14263 11.9776 9.03269 12 8.92148 12H7.7001V13.25C7.7001 13.6642 7.34753 14 6.9126 14H5.6001V15.25C5.6001 15.6642 5.24753 16 4.8126 16H1.1376C0.702668 16 0.350098 15.6642 0.350098 15.25V12.8107C0.350098 12.6118 0.43308 12.421 0.580737 12.2803L5.88986 7.224C5.70208 6.68169 5.6001 6.10222 5.6001 5.5C5.6001 2.46244 8.18562 3.12503e-05 11.3751 2.98518e-10C14.5739 -3.12497e-05 17.1501 2.45347 17.1501 5.50003ZM11.3751 4C11.3751 4.82844 12.0802 5.5 12.9501 5.5C13.82 5.5 14.5251 4.82844 14.5251 4C14.5251 3.17156 13.82 2.5 12.9501 2.5C12.0802 2.5 11.3751 3.17156 11.3751 4Z" fill="black" />
                    </svg>


                  </div>
                  <input type="password" name="password" onChange={(e) => setPassword(e?.target?.value)} className=" border border-[#D1D1D1] text-gray-900 text-sm rounded-[10px] block w-full pl-10 p-2.5" placeholder="Password" />
                </div>
                <div>
                  <Link to={'/forgot-password'} className="text-[14px] text-[#E04343] font-[400] cursor-pointer">Forgot your password?</Link>
                </div>
                {/* <div className="flex items-center mb-2 mt-4">
                  <input id="default-checkbox" type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-[#D1D1D1] rounded " />
                  <label htmlFor="default-checkbox" className="ml-3 text-sm font-medium text-[#333333]">Remember me</label>
                </div> */}
                <div className="flex justify-end">
                  <button disabled={loader} className="text-white bg-[#E04343] rounded-[15px] transition ease-out delay-300 py-[12px] px-[42px] font-[700] text-[14px]">
                    <div className="flex justify-center gap-3 ">
                      <span>Login</span>
                      <Oval
                        visible={loader}
                        height="20"
                        width="20"
                        color="#E04343"
                        secondaryColor="#FCBF94"
                        ariaLabel="oval-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                      />
                    </div>

                  </button>
                </div>
              </form>
            </div>
            {/* <div className=" absolute bottom-[5px] left-0 w-full text-center lg:pb-2 pb-4">
              <h3 className="text-[#333333] text-[14px]">Don’t have an account yet?</h3>
              <Link to="/register">
                <h3 className="text-[#E04343] text-[14px] cursor-pointer">Register here</h3>
              </Link>

            </div> */}
          </div>
        </div>

        <div className="basis-3/5 hidden lg:block">
          <div className="h-screen flex items-center justify-center bg-[#FAFAFA]">
            <img src="/images/Auth/login.png" alt="hero" />
          </div>
        </div>


      </div>


      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
    </>
  );
}

export default StaffLogin;
