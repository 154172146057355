import React from 'react'
import AdminNavbar from "../Navbars/AdminNavbar";
import Sidebar from "../Sidebar/Sidebar";
import { AxiosResponse } from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//@ts-ignore
import Modal from 'react-awesome-modal';
import { SvgElement, icontypesEnum } from '../assets/svgElement';
import { SuperAdminApis } from '../../apis/superAdminApi';
import { useParams } from 'react-router-dom';
import AdminSidebar from '../Sidebar/AdminSidebar';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FaPen } from "react-icons/fa";
import { Oval } from 'react-loader-spinner';



function EditCreditCustomer() {
  let [showScreen, setShowScreen] = React.useState<any>(1);

  const navigate = useNavigate();

  const userLoginData = useSelector((state: any) => state.data.login.value);

  let [creditCustomers, setCreditCustomers] = React.useState<any>([]);

  let [loader, setLoader] = React.useState<boolean>(false);

  const params: any = useParams();
  let cusId: string = params?.id;


  React.useEffect(() => {
    SuperAdminApis.getSingleCreditCustomers(cusId).then(
      (response: AxiosResponse<any>) => {
        if (response?.data) {
          setCreditCustomers(response?.data?.data)
        }
      }
    )
  }, []);





  let [statusModal, setStatusModal] = React.useState<boolean>(false);
  let [approveModal, setApproveModal] = React.useState<boolean>(false);

  const handleShipmentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCreditCustomers({ ...creditCustomers, [e.target.name]: e.target.value });
  }


  const updateShipment = React.useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoader(true)

    SuperAdminApis.updateCreditCustomer(creditCustomers)
      .then((response: any) => {
        if (response?.data) {
          if (response?.data?.status) {
            toast.success(response?.data?.message);
            setLoader(false);
          }
          // console?.log(response?.data)
        }
      }).catch((err: any) => {
        console.log(err);
      })

  }, [creditCustomers,loader]);





  // console?.log(shipmentRider)
  const [searchText, setSearchText] = React.useState('');
  function refreshPage() {
    window.location.reload();
  }

  return (
    <>
      <AdminSidebar title="Credit Customer" />
      <div className="relative md:ml-64 bg-[#FAFAFA] ">
        <AdminNavbar title="Credit Customer" />


        <div>
          <form onSubmit={updateShipment}>
            <div className=' lg:px-10 pb-6 px-6 bg-[#FAFAFA] min-h-screen'>
              {/* <!-- Sender Information --> */}
              <div className="px-6 lg:mt-5 bg-white pt-[24px] rounded-[10px]">
                <div>
                  <div className="flex flex-col lg:flex-row lg:justify-between max-w-screen-xl mx-auto  mt-5">
                    <div className="relative flex flex-col min-w-0 break-words w-full  ">
                      <div className="flex-auto   py-10 pt-0">
                        <div className="flex flex-wrap ">

                          <div className="w-full lg:w-4/12 lg:pr-4">
                            <div className="relative w-full mb-5">
                              <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                Name of Company
                              </label>
                              <input
                                type="text"
                                className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                placeholder="Company Name"
                                name="companyName"
                                defaultValue={creditCustomers?.companyName}
                                onChange={handleShipmentChange}

                              />
                            </div>
                          </div>



                          <div className="w-full lg:w-4/12 lg:pr-4">
                            <div className="relative w-full mb-5">
                              <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                Telephone Number
                              </label>
                              <input
                                type="text"
                                className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                placeholder="Company Phone Number"
                                name="phoneNumber"
                                defaultValue={creditCustomers?.phoneNumber}
                                onChange={handleShipmentChange}

                              />
                            </div>
                          </div>



                          <div className="w-full lg:w-4/12 lg:pr-4">
                            <div className="relative w-full mb-5">
                              <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                E-Mail Address
                              </label>
                              <input
                                type="email"
                                className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                placeholder="Company Email"
                                name="email"
                                defaultValue={creditCustomers?.email}
                                onChange={handleShipmentChange}

                              />
                            </div>
                          </div>

                          <div className="w-full lg:w-8/12 lg:pr-4">
                            <div className="relative w-full mb-5">
                              <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                Registered Address
                              </label>
                              <textarea
                                className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                placeholder="Company Address"
                                name="address"
                                defaultValue={creditCustomers?.address}
                                onChange={(e: any) => handleShipmentChange(e)}

                              />
                            </div>
                          </div>


                          <div className="w-full lg:w-4/12 lg:pr-4">
                            <div className="relative w-full mb-5">
                              <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                Name of Authorizing Officer
                              </label>
                              <input
                                type="text"
                                className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                placeholder="Officer Name"
                                name="authorizingOfficer"
                                defaultValue={creditCustomers?.authorizingOfficer}
                                onChange={handleShipmentChange}

                              />
                            </div>
                          </div>


                          <div className="w-full lg:w-4/12 lg:pr-4">
                            <div className="relative w-full mb-5">
                              <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                Name Of Banker / Branch 1
                              </label>
                              <input
                                type="text"
                                className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                placeholder="Banker / Branch 1"
                                name="banker1"
                                defaultValue={creditCustomers?.banker1}
                                onChange={handleShipmentChange}

                              />
                            </div>
                          </div>

                          <div className="w-full lg:w-4/12 lg:pr-4">
                            <div className="relative w-full">
                              <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                Name Of Banker / Branch 2
                              </label>
                              <input
                                type="text"
                                className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                placeholder="banker / Branch 2"
                                name="banker2"
                                defaultValue={creditCustomers?.banker2}
                                onChange={handleShipmentChange}

                              />
                            </div>
                          </div>


                          <div className="w-full lg:w-4/12 lg:pr-4">
                            <div className="relative w-full">
                              <label className="block mb-2 text-[12px] font-semibold text-[#19213D]">
                                Name of Officer In Charge Of Mailroom Or Pick Up Point
                              </label>
                              <input
                                type="text"
                                className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                placeholder="Officer Name"
                                name="officerInCharge"
                                defaultValue={creditCustomers?.officerInCharge}
                                onChange={handleShipmentChange}

                              />
                            </div>
                          </div>


                          <div className="w-full lg:w-8/12 lg:pr-4">
                            <div className="relative w-full">
                              <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                State Briefly The Procedure For Payment
                              </label>
                              <textarea

                                className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                placeholder="Payment Procedure"
                                name="procedureForPayment"
                                defaultValue={creditCustomers?.procedureForPayment}
                                onChange={(e: any) => handleShipmentChange(e)}

                              />
                            </div>
                          </div>


                          <div className="w-full lg:w-4/12 lg:pr-4">
                            <div className="relative w-full">
                              <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                Pick up days
                              </label>
                              <input
                                type="text"
                                className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                placeholder="e.g Mon,Tue,Wed..."
                                name="pickupDays"
                                defaultValue={creditCustomers?.pickupDays}
                                onChange={handleShipmentChange}

                              />
                            </div>
                          </div>


                          <div className="w-full lg:w-4/12 lg:pr-4">
                            <div className="relative w-full">
                              <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                Account Commencement Date
                              </label>
                              <input
                                type="date"
                                className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                placeholder=""
                                name="accountCommencementDate"
                                defaultValue={creditCustomers?.accountCommencementDate}
                                onChange={handleShipmentChange}

                              />
                            </div>
                          </div>

                          <div className="w-full lg:w-4/12 lg:pr-4">
                            <div className="relative w-full">
                              <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                Advanced Payment date
                              </label>
                              <input
                                type="date"
                                className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                placeholder=""
                                name="advancePaymentDate"
                                defaultValue={creditCustomers?.advancePaymentDate}
                                onChange={handleShipmentChange}

                              />
                            </div>
                          </div>


                          <div className="w-full lg:w-4/12 lg:pr-4">
                            <div className="relative w-full">
                              <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                To Be Reviewed By
                              </label>
                              <input
                                type="email"
                                className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                placeholder=""
                                name="reviewedBy"
                                defaultValue={creditCustomers?.reviewedBy}
                                onChange={handleShipmentChange}

                              />
                            </div>
                          </div>


                          <div className="w-full lg:w-4/12 lg:pr-4">
                            <div className="relative w-full">
                              <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                To Be Approved By
                              </label>
                              <input
                                type="email"
                                className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                placeholder=""
                                name="approvedBy"
                                defaultValue={creditCustomers?.approvedBy}
                                onChange={handleShipmentChange}
                              />
                            </div>
                          </div>



                        </div>
                      </div>
                    </div>
                  </div>

                </div>

              </div>

              {/* <!-- Receiver  Information --> */}


              <div className='flex justify-end mt-6'>
                <div className='flex space-x-10'>
                  <div>
                    <button
                      // onClick={() => setShowScreen(2)}
                      type='submit'
                      disabled={loader}
                      className='bg-[#E04343] rounded-[40px] py-[5px] px-[20px] text-white inline-flex'>

                      <div className="flex justify-center gap-3 ">
                        <span> Update Account</span>
                        <Oval
                          visible={loader}
                          height="20"
                          width="20"
                          color="#E04343"
                          secondaryColor="#FCBF94"
                          ariaLabel="oval-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                        />
                      </div>
                    </button>
                  </div>
                </div>
              </div>



            </div>


            {/* <!-- Shipments  Information --> */}

          </form>
        </div>

      </div>

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
    </>
  )
}

export default EditCreditCustomer