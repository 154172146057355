import React from 'react'
import AdminNavbar from "../Navbars/AdminNavbar.js";
import Sidebar from "../Sidebar/Sidebar";
function Settings() {
    let [toggle, setToggle] = React.useState(false);
    function togglePasswordModal() {
        setToggle(!toggle)
    }
    return (
        <>
            <Sidebar title="Settings" />
            <div className="relative md:ml-64 bg-[#FAFAFA] ">
                <AdminNavbar title="Settings" />

                <div className=' lg:px-10 pb-6 px-6 pt-5 bg-[#FAFAFA] min-h-screen'>
                    <div className='grid lg:grid-cols-2 grid-cols-1 gap-5'>
                        <div className='bg-white rounded-[10px] px-[15px] py-[14px]'>
                            <h3 className='text-[16px] font-semibold'>Account Details</h3>
                            <p className='text-[14px] text-[#999999] mt-1'>Update your account information here.</p>
                            <div>
                                <form>
                                    <div className="flex flex-col lg:flex-row lg:justify-between max-w-screen-xl mx-auto  mt-5">
                                        <div className="relative flex flex-col min-w-0 break-words w-full  ">
                                            <div className="flex-auto   pt-0">
                                                <div className="flex flex-wrap ">

                                                    <div className="lg:w-6/12 w-full lg:pr-4">
                                                        <div className="flex justify-between mb-3">
                                                            <label className="text-[#333333] text-[12px]">First name</label>

                                                        </div>
                                                        <div className="relative mb-4">
                                                            <input type="text" className=" border border-[#D1D1D1] text-gray-900 text-sm rounded-[5px] block w-full pl-4 p-2" placeholder="" />
                                                        </div>
                                                    </div>

                                                    <div className="lg:w-6/12 w-full ">
                                                        <div className="flex justify-between mb-3">
                                                            <label className="text-[#333333] text-[12px]">Last name</label>
                                                        </div>
                                                        <div className="relative mb-4">
                                                            <input type="text"
                                                                className=" border border-[#D1D1D1] text-gray-900 text-sm rounded-[5px] block w-full pl-4 p-2"
                                                                placeholder=""
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="lg:w-6/12 w-full lg:pr-4">
                                                        <div className="flex justify-between mb-3">
                                                            <label className="text-[#333333] text-[12px] font-[400]">Email</label>
                                                        </div>
                                                        <div className="relative mb-4">
                                                            <input type="email"
                                                                className=" border border-[#D1D1D1] text-gray-900 text-sm rounded-[5px] block w-full pl-4 p-2"
                                                                placeholder=""
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="lg:w-6/12 w-full">
                                                        <div className="flex justify-between mb-3">
                                                            <label className="text-[#333333] text-[12px] font-[400]">Phone Number</label>

                                                        </div>
                                                        <div className="relative mb-4">

                                                            <input type="number" className=" border border-[#D1D1D1] text-gray-900 text-sm rounded-[5px] block w-full pl-4 p-2" placeholder="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex  pb-8">

                                        <div className="mt-1 ">
                                            <button className="text-white bg-[#E04343] rounded-[30px] transition ease-out delay-300 py-2 px-[42px] font-[700] text-[14px]">Update</button>
                                        </div>

                                    </div>





                                </form>
                            </div>
                        </div>
                        <div className='bg-white rounded-[10px] px-[15px] py-[14px]'>
                            <h3 className='text-[16px] font-semibold'>Account Actions</h3>
                            <p className='text-[14px] text-[#999999] mt-1'>Manage yout Skynet Account here</p>
                            <form>
                            <div className='mt-5'>
                                <span 
                                className='bg-[#1C1C1C] text-center text-white py-2 px-[60px] rounded-[10px] cursor-pointer'
                                onClick={togglePasswordModal}>Change Password</span>

                                <div className={toggle ? 'mt-6' : 'hidden'}>
                                    <div className="lg:w-6/12 w-full ">
                                        <div className="flex justify-between mb-3">
                                            <label className="text-[#333333] text-[12px]">Current Password</label>
                                        </div>
                                        <div className="relative mb-4">
                                            <input type="password"
                                                className=" border border-[#D1D1D1] text-gray-900 text-sm rounded-[5px] block w-full pl-4 p-2"
                                                placeholder=""
                                            />
                                        </div>
                                    </div>
                                    <div className="lg:w-6/12 w-full ">
                                        <div className="flex justify-between mb-3">
                                            <label className="text-[#333333] text-[12px]">New Password</label>
                                        </div>
                                        <div className="relative mb-4">
                                            <input type="password"
                                                className=" border border-[#D1D1D1] text-gray-900 text-sm rounded-[5px] block w-full pl-4 p-2"
                                                placeholder=""
                                            />
                                        </div>
                                    </div>
                                    <div className="lg:w-6/12 w-full ">
                                        <div className="flex justify-between mb-3">
                                            <label className="text-[#333333] text-[12px]">Confirm Password</label>
                                        </div>
                                        <div className="relative mb-4">
                                            <input type="password"
                                                className=" border border-[#D1D1D1] text-gray-900 text-sm rounded-[5px] block w-full pl-4 p-2"
                                                placeholder=""
                                            />
                                        </div>
                                    </div>




                                    <button
                                        type="submit"
                                        
                                        className="bg-[#E04343] rounded-[30px] text-sm sm:w-auto px-10 py-2 text-center text-white font-medium "
                                    >
                                        Update
                                    </button>
                                </div>
                            </div>
                            </form>
                            
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default Settings