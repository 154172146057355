import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import { Dispatch } from "redux";
import { useSelector, useDispatch } from 'react-redux';
import { login } from '../../../reducer/loginSlice'
import { AxiosResponse, AxiosError } from "axios";
import axios from "axios";
import { AuthLiveApis } from "../../../apis/live/authLiveApis";
import { AuthApis } from "../../../apis/authApis";
import { store } from "../../../store/store";
import { History } from "history";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from "../../Navbars/Navbar";
import { Oval } from 'react-loader-spinner'
//@ts-ignore
import { PhoneInput } from "react-contact-number-input";
import { SvgElement, icontypesEnum } from "../../assets/svgElement";

function Register() {

  const [show, setShow] = useState(false);
  const [phone, setPhone] = useState<any>("");

  // initialValue
  const initialValue = {
    email: "",
    fullName: "",
    phoneNo: "",
    gender: "",
    password: "",
    comfirmPassword: "",
    phone_number: "",
    checked: false,
  };

  const [userData, setUserdata] = useState({
    'first_name': "",
    'last_name': "",
    'email': "",
    'password': "",
    'confirm_password': "",
    'phone_number': "",
    'where_goods_will_be_delievered_to': "",
    // 'checked': false
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target;
    setUserdata({ ...userData, [name]: value });
  };

  // console.log(userData);


  const [toggle, setToggle] = useState<boolean>(false);


  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // const [show, setShow] = useState(false);

  const navigate = useNavigate();

  // How to access the redux store
  const userLoginData = useSelector((state: any) => state.data.login.value);

  // This is used to update the store
  const dispatch: Dispatch = useDispatch();

  const handleSubmit = React.useCallback(
    (e: React.ChangeEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (userData?.password !== userData?.confirm_password) {
        return toast.error("Password does not match");
      }
      setToggle(true);
      const formData = new FormData()
      formData.append('first_name', userData?.first_name)
      formData.append('last_name', userData?.last_name)
      formData.append('email', userData?.email)
      formData.append('where_goods_will_be_delievered_to', userData?.where_goods_will_be_delievered_to)
      formData.append('phone_number', (userData?.phone_number))
      formData.append('password', userData?.password)
      formData.append('confirm_password', userData?.confirm_password)


      AuthApis.register(formData).then(
        (response: any) => {
          if (response?.data) {
            if (response?.data?.status === true) {
              // dispatch(login({ email: userData?.email, token: response.data.token, name: response.data.name }))
              toast.success("Login Successful");
              navigate('/email-verify');
              setToggle(false);

            } else {
              toast.warn('Error');
              setToggle(false);
            }

          }else{
            console.log(response);
            toast.warn('Something went wrong,Try again.');
          }
        }
      ).catch(function (error) {
        toast.warn(error?.response?.data?.message);
        // handle error
        setToggle(false);
        console.log(error);
        toast.error("Offfline");
      }).finally(() => {
        setToggle(false);
      });
    },
    [userData, phone]
  );




  //   console.log(userData);
  return (
    <>

      <div className="flex flex-row">
        <div className="lg:basis-7/12 basis-full relative">
          <div className="h-screen bg-white lg:pl-[90px] lg:pr-[60px] pl-[30px] pr-[20px] pt-[70px] lg:pb-[40px]">
            <div className="flex justify-between">
              <img src="/images/Auth/logo.png" alt="hero" />
              <NavLink to={`/login`}>
                <div className="flex space-x-2">
                  <h3 className="text-[#E04343] lg:text-[14px] text-[12px]">Are you already a member?</h3>
                  <svg width="18" height="16" className="mt-1" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.15 0C11.4705 0 13.35 1.79 13.35 4C13.35 6.21 11.4705 8 9.15 8C6.8295 8 4.95 6.21 4.95 4C4.95 1.79 6.8295 0 9.15 0ZM9.15 16C9.15 16 17.55 16 17.55 14C17.55 11.6 13.455 9 9.15 9C4.845 9 0.75 11.6 0.75 14C0.75 16 9.15 16 9.15 16Z" fill="#E04343" />
                  </svg>


                </div>
              </NavLink>
            </div>

            <div className="mt-[20px] flex lg:flex-row flex-col lg:justify-between lg:gap-14">
              <h1 className="text-[#42505C] lg:text-[54px] text-[44px] font-[700]">Input your information</h1>
              <p className="text-[#404040] lg:text-[14px] text-[12px] font-[400] mt-6 leading-[28px]">We need you to help us with some basic information for your account creation. Here are our <span className="text-[#E04343] font-[500]">terms and conditions</span>.  Please read them carefully.</p>
            </div>
            {/* <hr className=""/> */}
            <div className="mt-10">
              <form onSubmit={handleSubmit}>
                <div className="flex flex-col lg:flex-row lg:justify-between max-w-screen-xl mx-auto  mt-5">
                  <div className="relative flex flex-col min-w-0 break-words w-full  ">
                    <div className="flex-auto   py-10 pt-0">
                      <div className="flex flex-wrap ">

                        <div className="lg:w-6/12 w-full lg:pr-4">
                          <div className="flex justify-between mb-3">
                            <label className="text-[#333333] text-[12px]">First name</label>
                            <SvgElement type={icontypesEnum.QUESTIONMARK} />
                          </div>
                          <div className="relative mb-4">
                            <input type="text" name="first_name" onChange={handleChange} className=" border border-[#D1D1D1] text-gray-900 text-sm rounded-[5px] block w-full pl-6 p-2.5" placeholder="John" />
                          </div>
                        </div>

                        <div className="lg:w-6/12 w-full ">
                          <div className="flex justify-between mb-3">
                            <label className="text-[#333333] text-[12px]">Last name</label>
                            <SvgElement type={icontypesEnum.QUESTIONMARK} />
                          </div>
                          <div className="relative mb-4">

                            <input type="text" name="last_name" onChange={handleChange} className=" border border-[#D1D1D1] text-gray-900 text-sm rounded-[5px] block w-full pl-6 p-2.5" placeholder="Smith" />
                          </div>
                        </div>

                        <div className="lg:w-6/12 w-full lg:pr-4">
                          <div className="flex justify-between mb-3">
                            <label className="text-[#333333] text-[12px] font-[400]">Email</label>
                            <SvgElement type={icontypesEnum.QUESTIONMARK} />
                          </div>
                          <div className="relative mb-4">

                            <input type="email" name="email" onChange={handleChange} className=" border border-[#D1D1D1] text-gray-900 text-sm rounded-[5px] block w-full pl-6 p-2.5" placeholder="john@gmail.com" />
                          </div>
                        </div>



                        <div className="lg:w-6/12 w-full">
                          <div className="flex justify-between mb-3">
                            <label className="text-[#333333] text-[12px] font-[400]">Phone Number</label>
                            <SvgElement type={icontypesEnum.QUESTIONMARK} />
                          </div>
                          <div className="relative mb-4">

                            <input type="number" name="phone_number" onChange={handleChange} className=" border border-[#D1D1D1] text-gray-900 text-sm rounded-[5px] block w-full pl-6 p-2.5" placeholder="+234..." />
                          </div>
                        </div>

                        <div className="lg:w-12/12 w-full lg:pr-4">
                          <div className="flex justify-between mb-3">
                            <label className="text-[#333333] text-[12px] font-[400]">Home Address</label>
                            <SvgElement type={icontypesEnum.QUESTIONMARK} />
                          </div>
                          <div className="relative mb-4">

                            <input type="text" name="where_goods_will_be_delievered_to" onChange={handleChange} className=" border border-[#D1D1D1] text-gray-900 text-sm rounded-[5px] block w-full pl-6 p-2.5" placeholder="Location" />
                          </div>
                        </div>

                        <div className="lg:w-6/12 w-full lg:pr-4">
                          <div className="flex justify-between mb-3">
                            <label className="text-[#333333] text-[12px] font-[400]">Password</label>
                            <SvgElement type={icontypesEnum.QUESTIONMARK} />
                          </div>
                          <div className="relative mb-4">

                            <input type="password" name="password" onChange={handleChange} className=" border border-[#D1D1D1] text-gray-900 text-sm rounded-[5px] block w-full pl-6 p-2.5" placeholder="" />
                          </div>
                        </div>

                        <div className="lg:w-6/12 w-full">
                          <div className="flex justify-between mb-3">
                            <label className="text-[#333333] text-[12px] font-[400]">Confirm Password</label>
                            <SvgElement type={icontypesEnum.QUESTIONMARK} />
                          </div>
                          <div className="relative mb-4">

                            <input type="password" name="confirm_password" onChange={handleChange} className=" border border-[#D1D1D1] text-gray-900 text-sm rounded-[5px] block w-full pl-6 p-2.5" placeholder="" />
                          </div>
                        </div>



                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex justify-between pb-8">

                  <div className="flex items-center mb-2 mt-4">
                    <input id="default-checkbox" type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-[#D1D1D1] rounded " />
                    <label htmlFor="default-checkbox" className="ml-3 text-sm font-medium text-[#333333]">I agree with <span className="text-[#E04343]">terms and conditins</span></label>
                  </div>

                  <div className="mt-1 ">
                    <button type="submit" disabled={toggle} className="text-white bg-[#E04343] rounded-[30px] transition ease-out delay-300 py-[10px] px-[42px] font-[700] text-[14px]">
                      
                        <span className="flex justify-center gap-3">
                          <span> Register</span>
                          <span>
                            <Oval
                              visible={toggle}
                              height="20"
                              width="20"
                              color="#E04343"
                              secondaryColor="#E6F1FC"
                              ariaLabel="oval-loading"
                              wrapperStyle={{}}
                              wrapperClass=""
                            />
                          </span>
                        </span>
                       

                    </button>



                  </div>

                </div>





              </form>
            </div>

          </div>
        </div>

        <div className="basis-5/12 hidden lg:block">
          <div className="h-screen flex items-center justify-center bg-[#FAFAFA]">
            <img src="/images/Auth/register.png" alt="hero" />
          </div>
        </div>


      </div>

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
    </>
  );
}

export default Register;
