import React from 'react'
import AdminSidebar from "../Sidebar/AdminSidebar";
import { NavLink, Link, useNavigate } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SuperAdminApis } from '../../apis/superAdminApi';
import AdminNavbar from "../Navbars/AdminNavbar.js";
import Sidebar from "../Sidebar/Sidebar";
import { convertUTCDateToLocalDate } from '../Reusable/functions';

function UsersList() {

  const navigate = useNavigate();
  const [userLists, setUserList] = React.useState<any>([]);
  const [searchText, setSearchText] = React.useState('user');


  React.useEffect(() => {
    const query: any = {
      search: searchText,
    };
    SuperAdminApis.getAllUsers('', query).then(
      (response: AxiosResponse<any>) => {
        if (response?.data) {
          setUserList(response?.data?.data)
        }
      }
    ).catch(function (error) {
      // handle error

    })
  }, []);



  const paginator = React.useCallback(
    (value: any) => {
      //   setLoader(true);
      let value2 = '';
      if (value !== null) {
        value2 = value;
      } else {
        value2 = ''
      }
      const query: any = {
        search: searchText,
      };

      SuperAdminApis.getAllUsers(value2, query).then(
        (response: AxiosResponse<any>) => {
          if (response?.data) {
            setUserList(response?.data?.data)
            console?.log(response?.data?.data)
          }
        }
      ).catch(function (error) {
        console.log(error.response.data);
      })

    }, [userLists, searchText]);




  return (
    <>
      <AdminSidebar title="" />
      <div className="relative md:ml-64 bg-[#FAFAFA] ">
        <AdminNavbar title="Customer" />
        <div className=' lg:px-10 pb-6 px-6 bg-[#FAFAFA] min-h-screen'>

          <div className='flex justify-between my-5'>
            <div>
              <h3 className='text-[15px] text-[#1C1C1C] font-semibold'>Customers</h3>

            </div>
            <div>
              {/* <button className='bg-[#E04343] text-white text-[14px] px-[12px] py-[8px] rounded-[10px]'>+ Add Customers</button> */}
              <div className='flex justify-end'>
                <div className=" lg:ml-auto mr-3 flex justify-end">
                  <div className="relative flex w-full flex-wrap items-stretch">
                    <div className="absolute inset-y-0 right-2 flex items-center pl-3 pointer-events-none">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="#9da4aa" d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0a5.5 5.5 0 0 1 11 0z" /></svg>
                    </div>
                    <input type="text" onClick={() => paginator('')} onChange={e => setSearchText(e.target.value)} placeholder='search...' id="simple-search" className=" border border-gray-300 text-gray-500 text-sm rounded-md block w-full pl-4 p-1  " required />
                  </div>

                  <div className='mt-0.5 ml-2'><button type='button' onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => paginator('')} className={"font-normal text-white bg-[#E04343] px-3 py-0.5 rounded-md"}>Search</button> </div>



                </div>

                {/* <div className='mt-1'>Filter </div> */}
              </div>
            </div>
          </div>


          <div className="relative overflow-x-auto">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 border-separate border-spacing-y-3">
              <thead className="text-[12px] text-[#030229] bg-[#FAFAFA] ">
                <tr>

                  <th scope="col" className="px-2 py-2">
                    S/N
                  </th>

                  <th scope="col" className="px-2 py-2">
                    Name
                  </th>
                  <th scope="col" className="px-2 py-2">
                    Email
                  </th>
                  <th scope="col" className="px-2 py-2">
                    Phone Number
                  </th>
                  <th scope="col" className="px-2 py-2">
                    Customer Type
                  </th>
                  <th scope="col" className="px-2 py-2">
                    Status
                  </th>
                  {/* <th scope="col" className="px-2 py-2">
                    Action
                  </th> */}

                </tr>
              </thead>


              <tbody className=''>

                {
                  userLists?.data?.length > 0 ?
                    userLists?.data?.filter((e:any)=>e?.role == 'user')?.map(
                      (datas: any, index: any) => (
                        <tr className="bg-white  ">

                          <td className="px-2 py-2">
                            {index + 1}
                          </td>

                          <td className="px-6 py-4">
                            {datas?.first_name}  {datas?.last_name}
                          </td>
                          <td className="px-6 py-4 ">
                            {datas?.email}
                          </td>
                          <td className="px-6 py-4 ">
                            {datas?.phone_number}
                          </td>
                          <td className="px-6 py-4 ">
                            {datas?.customer_type}
                          </td>
                          <td className="px-6 py-4 ">
                            {datas?.isVerified == 'false' ? <p><b style={{ fontWeight: "500", fontSize: '14px', backgroundColor: '#E6BDFF', color: '#B24BF1', borderRadius: '10px', padding: '2px 10px' }}>Pending</b></p> : ''}
                            {datas?.isVerified == 'true' ? <p><b style={{ fontWeight: "500", fontSize: '14px', backgroundColor: '#CAD8FA', color: '#4979ED', borderRadius: '10px', padding: '2px 10px' }}>Verified</b></p> : ''}
                            {/* {datas?.isVerified} */}
                          </td>


                          {/* <td className="px-6 py-4 cursor-pointer">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <rect width="24" height="24" rx="4" fill="#EBEBEB" />
                              <path d="M12 13.5C12.8284 13.5 13.5 12.8284 13.5 12C13.5 11.1716 12.8284 10.5 12 10.5C11.1716 10.5 10.5 11.1716 10.5 12C10.5 12.8284 11.1716 13.5 12 13.5Z" fill="#3F83F8" />
                              <path d="M15.2536 9.30891C14.2289 8.60625 13.1323 8.25 11.9944 8.25C10.9704 8.25 9.97219 8.55469 9.02742 9.15187C8.07469 9.75539 7.1407 10.8516 6.375 12C6.99422 13.0312 7.84125 14.0916 8.72344 14.6995C9.73547 15.3966 10.8359 15.75 11.9944 15.75C13.1428 15.75 14.2409 15.3968 15.2592 14.7002C16.1557 14.0859 17.0088 13.027 17.625 12C17.0067 10.9821 16.1508 9.92437 15.2536 9.30891ZM12 14.25C11.555 14.25 11.12 14.118 10.75 13.8708C10.38 13.6236 10.0916 13.2722 9.92127 12.861C9.75097 12.4499 9.70642 11.9975 9.79323 11.561C9.88005 11.1246 10.0943 10.7237 10.409 10.409C10.7237 10.0943 11.1246 9.88005 11.561 9.79323C11.9975 9.70642 12.4499 9.75097 12.861 9.92127C13.2722 10.0916 13.6236 10.38 13.8708 10.75C14.118 11.12 14.25 11.555 14.25 12C14.2493 12.5965 14.012 13.1684 13.5902 13.5902C13.1684 14.012 12.5965 14.2493 12 14.25Z" fill="#3F83F8" />
                            </svg>
                          </td> */}




                        </tr>
                      )
                    )
                    :
                    <div className='flex justify-start mt-4'>
                      <td >No Record Found</td>
                    </div>


                }

              </tbody>
            </table>
          </div>

          <div className='m-4 flex justify-end mt-20'>
            {
              userLists?.links?.filter(((item: any, idx: any) => idx < 1000)).map(
                (datas: any, index: any) => (
                  <button onClick={() => paginator(datas?.label == 'Next &raquo;' ? datas?.url?.charAt(datas?.url?.length - 1) : (datas?.label === '&laquo; Previous') ? datas?.url?.charAt(datas?.url?.length - 1) : datas?.label)} disabled={datas?.active} className={'mx-1 py-1 px-2 ' + ((datas?.active == false || datas?.url == null) ? 'bg-gray-300 text-black ' : 'bg-[#E04343] text-white')}>
                    {datas?.label == '&laquo; Previous' ? '< Previous' : (datas?.label === 'Next &raquo;') ? 'Next  >' : datas?.label}
                  </button>
                )
              )
            }

          </div>

        </div>
      </div>
    </>
  )
}

export default UsersList