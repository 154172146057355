import React from 'react'
import AdminNavbar from "../../Navbars/AdminNavbar";
import Sidebar from "../../Sidebar/Sidebar";
import { SuperAdminApis } from '../../../apis/superAdminApi';
import { useNavigate } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { convertUTCDateToLocalDate } from '../../Reusable/functions';
//@ts-ignore
import Modal from 'react-awesome-modal';
import { NavLink } from "react-router-dom";
import { SvgElement, icontypesEnum } from '../../assets/svgElement';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function AddCountryToModal(props: any) {

  const navigate = useNavigate();
  const [userLists, setUserList] = React.useState<any>([]);
  const [countryName, setCountryName] = React.useState<any>('');
  const [countryCode, setCountryCode] = React.useState<any>('');
  const [basevalue, setBaseValue] = React.useState<any>('');
  const [perKg, setPerKg] = React.useState<any>('');
  const [clientEmail, setClientEmail] = React.useState<any>('');


  // React.useEffect(() => {
  //   SuperAdminApis.getAllCountryFrom('').then(
  //     (response: AxiosResponse<any>) => {
  //       if (response?.data) {
  //         setUserList(response?.data?.data)
  //         console?.log(response?.data?.data)
  //       }
  //     }
  //   ).catch(function (error: any) {
  //     // handle error

  //   })
  // }, []);


  const handleSubmit = React.useCallback(
    (e: any) => {
      e.preventDefault();

      const formData = new FormData()
      formData.append('country_name', countryName) 
      formData.append('country_code', countryCode)
      formData.append('client_id', clientEmail)
      formData.append('base_value', basevalue)
      formData.append('per_kg', perKg)

      SuperAdminApis.addCountryTo(formData).then(
        (response: any) => {
          if (response?.data) {
            toast.success(response?.data?.message);
            setCountryName('')
            setCountryCode('')
            setBaseValue('')
            setPerKg('')
            props?.handleClose()
          } else {
            toast.error(response?.response?.data?.message);
          }

          // toast.success(response?.data?.message);
        }
      ).catch(function (error) {
        // handle error
        // console.log(error.response);
        toast.error(error.response.data.message);

      })
    },
    [countryCode, countryName,clientEmail,setCountryCode,setCountryName,basevalue,perKg]
  );


  return (
    <>

      <section>
        <Modal
          visible={props?.show}
          width="400"
          height="600"
          effect="fadeInUp"
          onClickAway={() => props?.handleClose}
        >
          <div className=" " style={{ overflow: 'auto' }}>
            <span className="flex justify-between px-3 pt-3 mb-3">
              <p className='font-bold'>Add Country To</p>
              <p className="cursor-pointer font-bold" onClick={props?.handleClose}><SvgElement type={icontypesEnum.CANCEL} /></p>

            </span>

            <form onSubmit={handleSubmit}>
              <div className=' px-3'>
                <div className="flex flex-col lg:flex-row lg:justify-between max-w-screen-xl mx-auto ">
                  <div className="relative flex flex-col min-w-0 break-words w-full  ">
                    <div className="flex-auto   py-10 pt-0">
                      <div className="flex flex-wrap ">

                        <div className="w-full lg:w-12/12 lg:pr-4">
                          <div className="relative w-full mb-5">
                            <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                              Country Name
                            </label>
                            <input
                              type="text"
                              onChange={(e)=>setCountryName(e.target.value)}
                              value={countryName}
                              className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                              placeholder=""
                              name="DeadWeight"

                              // onChange={null}
                              required
                            />
                          </div>
                        </div>
                        <div className="w-full lg:w-12/12 lg:pr-4">
                          <div className="relative w-full mb-5">
                            <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                              Country Code
                            </label>
                            <input
                              type="text"
                              onChange={(e)=>setCountryCode(e.target.value)}
                              className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                              placeholder=""
                              value={countryCode}
                              name="DeadWeight"
                              // onChange={null}
                              required
                            />
                          </div>
                        </div>

                        <div className="w-full lg:w-12/12 lg:pr-4">
                          <div className="relative w-full mb-5">
                            <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                             Client Email (Optional)
                            </label>
                            <input
                              type="text"
                              onChange={(e)=>setClientEmail(e.target.value)}
                              className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                              placeholder=""
                              value={clientEmail}
                              name="DeadWeight"
                              // onChange={null}
                              
                            />
                          </div>
                        </div>

                        <div className="w-full lg:w-12/12 lg:pr-4">
                          <div className="relative w-full mb-5">
                            <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                             Base Value
                            </label>
                            <input
                              type="text"
                              onChange={(e)=>setBaseValue(e.target.value)}
                              className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                              placeholder=""
                              value={basevalue}
                              name="DeadWeight"
                              // onChange={null}
                              required
                            />
                          </div>
                        </div>

                        <div className="w-full lg:w-12/12 lg:pr-4">
                          <div className="relative w-full mb-5">
                            <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                              Per KG
                            </label>
                            <input
                              type="text"
                              onChange={(e)=>setPerKg(e.target.value)}
                              className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                              placeholder=""
                              value={perKg}
                              name="DeadWeight"
                              // onChange={null}
                              required
                            />
                          </div>
                        </div>






                        <div className="w-full flex justify-end">
                          <button type='submit' className='bg-[#E04343] text-white py-[10px] rounded w-full'>Add Country</button>
                        </div>


                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </form>




          </div>
        </Modal>
      </section>

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
    </>
  )
}

export default AddCountryToModal