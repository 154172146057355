import React from 'react'
import AdminNavbar from "../Navbars/AdminNavbar";
import Sidebar from "../Sidebar/Sidebar";
import { SuperAdminApis } from '../../apis/superAdminApi';
import { useNavigate } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { convertUTCDateToLocalDate } from '../Reusable/functions';
//@ts-ignore
import Modal from 'react-awesome-modal';
import { SvgElement, icontypesEnum } from '../assets/svgElement';
import { AuthApis } from '../../apis/authApis';
import { error } from 'console';
import AdminSidebar from '../Sidebar/AdminSidebar';
import { Oval } from 'react-loader-spinner';
function ManageStaffs() {

  const navigate = useNavigate();
  const [userLists, setUserList] = React.useState<any>([]);
  const [searchText, setSearchText] = React.useState('');

  let [addModal, setAddModal] = React.useState<boolean>(false);
  let [officeModal, setOfficeModal] = React.useState<boolean>(false);

  const [branchData, setBranchData] = React.useState<any>('');
  const [role, setRole] = React.useState<any>('');

  const [loader, setLoader] = React.useState<boolean>(false);

  const [branch, setBranch] = React.useState<any>([]);

  const [phone, setPhone] = React.useState<any>("");

  const [permissionList, setPermissionList] = React.useState<any>([]);



  const permissionDropdownChange = React.useCallback(
    (value: any) => {
      let verify = 0;
      if (value !== '') {
        permissionList?.map(
          (data: any, index: any) => (
            (data == value ? verify = 1 : '')
          ))

        if (verify == 0) {
          setPermissionList((permissionList: any) => [...permissionList, value])
        }

      }
    },
    [permissionList]
  );


  const remove = React.useCallback(
    (value: any, index: any) => {
      if (value !== '') {
        const temp = [...permissionList];
        temp.splice(index, 1);
        setPermissionList(temp)
      }
    },
    [permissionList]
  );


  const [userData, setUserdata] = React.useState<any>({
    'first_name': "",
    'last_name': "",
    'email': "",
    'password': "",
    'confirm_password': "",
    'phone_number': "",
    'office': '',
    'where_goods_will_be_delievered_to': "null",
    // 'checked': false
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target;
    setUserdata({ ...userData, [name]: value });
  };



  const handleSubmit = React.useCallback(
    (e: React.ChangeEvent<HTMLFormElement>) => {
      e.preventDefault();
      setLoader(true)

      if (userData?.password !== userData?.confirm_password) {
        setLoader(false)
        return toast.error("Password does not match");
      }

      const formData = new FormData()
      formData.append('first_name', userData?.first_name)
      formData.append('last_name', userData?.last_name)
      formData.append('email', userData?.email)
      formData.append('where_goods_will_be_delievered_to', userData?.where_goods_will_be_delievered_to)
      formData.append('phone_number', (userData?.phone_number))
      formData.append('office', (branchData))
      formData.append('permissions', (JSON.stringify(permissionList)))
      formData.append('role', (role))
      formData.append('password', userData?.password)
      formData.append('confirm_password', userData?.confirm_password)


      SuperAdminApis.createStaff(formData).then(
        (response: AxiosResponse<any>) => {
          if (response?.data) {
            if (response?.data?.status === true) {
              toast.success(response?.data?.message);
            }
          } else {
            toast.warn("Something went wrong,Try Again");
          }

          setAddModal(false)
          setLoader(false)
          setRole('');
          setUserdata({
            'first_name': "",
            'last_name': "",
            'email': "",
            'password': "",
            'confirm_password': "",
            'phone_number': "",
            'office': '',
            'where_goods_will_be_delievered_to': "null"
          });
          setPermissionList([]);
          const query: any = {
            search: searchText,
          };
          SuperAdminApis.getAllStaffs('', query).then(
            (response: AxiosResponse<any>) => {
              if (response?.data) {
                setUserList(response?.data?.data)
              }
            }
          )

          setUserdata([])

          // toast.success(response?.data?.message);
        }
      ).catch(function (error) {
        // handle error
        toast.warn(error.response?.data?.message);
        toast.error("Offfline");
      }).finally(() => {
        window.location.reload()
      });
    },
    [userData, phone, userLists, branchData, loader, role, permissionList]
  );

  let [riderModal, setRiderModal] = React.useState<boolean>(false);
  let [deleteStaffModal, setDeleteStaffModal] = React.useState<boolean>(false);
  let [shipmentId, setShipmentId] = React.useState<any>([]);
  let [shipmentRider, setShipmentRider] = React.useState<any>('');
  let [riders, setRiders] = React.useState<any>([]);

  const updateStaff = React.useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoader(true)

    let data = {
      'first_name': shipmentId?.first_name,
      'last_name': shipmentId?.last_name,
      'role': shipmentId?.role,
      'office': shipmentId?.office,
      'phone_number': shipmentId?.phone_number,
      'id': shipmentId?.id,
      'permissions': JSON.stringify(permissionList)
    }
    SuperAdminApis.updateStaff(data)
      .then((response: any) => {
        if (response?.data) {
          if (response?.data?.status) {
            toast.success(response?.data?.message);
            setRiderModal(false);
            setLoader(false)
            setShipmentRider('')
            setShipmentId([])

          }
          const query: any = {
            search: '',
          };
          SuperAdminApis.getAllStaffs('', query).then(
            (response: AxiosResponse<any>) => {
              if (response?.data) {
                setPermissionList([]);
                setUserList(response?.data?.data)
              }
            }
          )
        }
      }).catch((err: any) => {
        console.log(err);
      })

  }, [shipmentId, shipmentRider, loader, userLists, permissionList]);

  const deleteStaff = React.useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoader(true)

    SuperAdminApis.deleteStaff(shipmentId?.id)
      .then((response: any) => {
        if (response?.data) {
          if (response?.data?.status) {
            toast.success(response?.data?.message);
            setDeleteStaffModal(false);
            setLoader(false)
            setShipmentRider('')
            setShipmentId([])

          }
          const query: any = {
            search: '',
          };
          SuperAdminApis.getAllStaffs('', query).then(
            (response: AxiosResponse<any>) => {
              if (response?.data) {
                setUserList(response?.data?.data)
              }
            }
          )
        }
      }).catch((err: any) => {
        console.log(err);
      })

  }, [shipmentId, deleteStaffModal, loader, userLists]);




  const updateRiderStat = React.useCallback((e: React.FormEvent<HTMLFormElement>, status: any) => {
    e.preventDefault();

    let data = {
      "id": status?.id,
      "first_name": status?.first_name,
      "last_name": status?.last_name,
      "phone_number": status?.phone_number,
      "isVerified": status?.isVerified == 'false' ? 'true' : 'false',
    }
    SuperAdminApis.updateRider(data)
      .then((response: any) => {
        if (response?.data) {
          if (response?.data?.status) {
            toast.success(status?.isVerified == 'false' ? "Account Activated" : "Account Deactivated");
            setRiderModal(false);
            setShipmentRider('')

          }
          const query: any = {
            search: '',
          };
          SuperAdminApis.getAllStaffs('', query).then(
            (response: AxiosResponse<any>) => {
              if (response?.data) {
                setUserList(response?.data?.data)
              }
            }
          )
          // console?.log(response?.data)
        }
      }).catch((err: any) => {
        console.log(err);
      })

  }, [shipmentId, shipmentRider, userLists]);


  React.useEffect(() => {
    const query: any = {
      search: searchText,
    };
    SuperAdminApis.getAllStaffs('', query).then(
      (response: AxiosResponse<any>) => {
        if (response?.data) {
          setUserList(response?.data?.data)
        }
      }
    ).catch(function (error) {
      // handle error

    })

    SuperAdminApis.getAllBranch('', query).then(
      (response: AxiosResponse<any>) => {
        if (response?.data) {
          setBranch(response?.data?.data)
        }
      }
    )
  }, []);



  const paginator = React.useCallback(
    (value: any) => {
      //   setLoader(true);
      let value2 = '';
      if (value !== null) {
        value2 = value;
      } else {
        value2 = ''
      }
      const query: any = {
        search: searchText,
      };

      SuperAdminApis.getAllStaffs(value2, query).then(
        (response: AxiosResponse<any>) => {
          if (response?.data) {
            setUserList(response?.data?.data)
          }
        }
      ).catch(function (error) {
      })

    }, [userLists, searchText]);

  const handleChange2 = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target;
    setShipmentId({ ...shipmentId, [name]: value });
  };



  return (
    <>
      <AdminSidebar title="Invoice" />
      <div className="relative md:ml-64 bg-[#FAFAFA] ">
        <AdminNavbar title="Invoice" />
        <div className=' lg:px-10 pb-6 px-6 bg-[#FAFAFA] min-h-screen'>

          <div className='flex justify-between my-5'>
            <div>
              <h3 className='text-[15px] text-[#1C1C1C] font-semibold'>Staffs List</h3>

            </div>
            <div>
              {/* <button className='bg-[#E04343] text-white text-[14px] px-[12px] py-[8px] rounded-[10px]'>+ Add Customers</button> */}
              <div className='flex justify-end'>
                <div className="relative flex w-full flex-wrap items-stretch">
                  <div className="absolute inset-y-0 right-2 flex items-center pl-3 pointer-events-none">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="#9da4aa" d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0a5.5 5.5 0 0 1 11 0z" /></svg>
                  </div>
                  <input type="text" onClick={() => paginator('')} onChange={e => setSearchText(e.target.value)} placeholder='search...' id="simple-search" className=" border border-gray-300 text-gray-500 text-sm rounded-md block w-full pl-4 p-1  " required />
                </div>

                <div className='mt-0.5 ml-2'><button type='button' onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => paginator('')} className={"font-normal text-white bg-[#E04343] px-3 py-0.5 rounded-md"}>Search</button> </div>

              </div>

              <div className='flex justify-end mt-4'>
                <div className='mt-0.5 ml-2'><button type='button' onClick={() => setAddModal(true)} className={"font-normal text-white bg-[#E04343] px-3 py-0.5 rounded-md"}>Add Staff</button> </div>
              </div>


            </div>
          </div>


          <div className="relative overflow-x-auto">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 border-separate border-spacing-y-3">
              <thead className="text-[12px] text-[#030229] bg-[#FAFAFA] ">
                <tr>

                  <th scope="col" className="px-2 py-2">
                    S/N
                  </th>

                  <th scope="col" className="px-2 py-2">
                    Name
                  </th>
                  <th scope="col" className="px-2 py-2">
                    Email
                  </th>

                  <th scope="col" className="px-2 py-2">
                    Department
                  </th>

                  <th scope="col" className="px-2 py-2">
                    Branch
                  </th>
                  <th scope="col" className="px-2 py-2">
                    Phone Number
                  </th>
                  <th scope="col" className="px-2 py-2">
                    Activated
                  </th>
                  <th scope="col" className="px-12 py-4">
                    Action
                  </th>

                </tr>
              </thead>


              <tbody className=''>

                {
                  userLists?.data?.length > 0 ?
                    userLists?.data?.map(
                      (datas: any, index: any) => (
                        <tr className="bg-white  " key={index}>

                          <td className="px-2 py-2">
                            {index + 1}
                          </td>

                          <td className="px-6 py-4">
                            {datas?.first_name}
                          </td>
                          <td className="px-6 py-4 ">
                            {datas?.email}
                          </td>

                          <td className="px-6 py-4 ">
                            {datas?.role}
                          </td>

                          <td className="px-6 py-4 ">
                            {datas?.office}
                          </td>
                          <td className="px-6 py-4 ">
                            {datas?.phone_number}
                          </td>
                          <td className="px-6 py-4 cursor-pointer" onClick={(e: any) => { updateRiderStat(e, datas); }}>
                            {datas?.isVerified == 'false' ? <p><b style={{ fontWeight: "500", fontSize: '14px', backgroundColor: '#E6BDFF', color: '#B24BF1', borderRadius: '10px', padding: '2px 10px' }}>{datas?.isVerified}</b></p> : ''}
                            {datas?.isVerified == 'true' ? <p><b style={{ fontWeight: "500", fontSize: '14px', backgroundColor: '#CAD8FA', color: '#4979ED', borderRadius: '10px', padding: '2px 10px' }}>{datas?.isVerified}</b></p> : ''}
                            {/* {datas?.isVerified} */}
                          </td>

                          <td className='flex justify-between px-6 pt-4'>
                            <span className="hover:underline text-blue-500 cursor-pointer" onClick={() => { setRiderModal(true); setShipmentId(datas); setPermissionList(datas?.permissions ? JSON.parse(datas?.permissions) : []) }}>
                              Edit
                            </span>


                            <span className="hover:underline text-red-500 cursor-pointer" onClick={() => { setDeleteStaffModal(true); setShipmentId(datas) }}>
                              Delete
                            </span>
                          </td>
                        </tr>
                      )
                    )
                    :
                    <div className='flex justify-start mt-4'>
                      <td >No Record Found</td>
                    </div>


                }

              </tbody>
            </table>
          </div>
          <div className='m-4 flex justify-end mt-20'>
            {
              userLists?.links?.filter(((item: any, idx: any) => idx < 1000)).map(
                (datas: any, index: any) => (
                  <button key={index} onClick={() => paginator(datas?.label == 'Next &raquo;' ? datas?.url?.charAt(datas?.url?.length - 1) : (datas?.label === '&laquo; Previous') ? datas?.url?.charAt(datas?.url?.length - 1) : datas?.label)} disabled={datas?.active} className={'mx-1 py-1 px-2 ' + ((datas?.active == false || datas?.url == null) ? 'bg-gray-300 text-black ' : 'bg-[#E04343] text-white')}>
                    {datas?.label == '&laquo; Previous' ? '< Previous' : (datas?.label === 'Next &raquo;') ? 'Next  >' : datas?.label}
                  </button>
                )
              )
            }

          </div>
        </div>
      </div>


      <section>
        <Modal
          visible={addModal}
          width="600"
          height="750"
          effect="fadeInUp"
          onClickAway={() => setAddModal(false)}
        >
          <div className=" " style={{ height: '100%', overflow: 'auto' }}>

            <div className="container  bg-[#fff] rounded-lg p-2">

              <div className="px-3">

                {/* <span className="flex justify-around">
                    <h1 className=" text-xs text-red-600" style={{ fontSize: '10px' }}>Link can’t be edited in free plan. <span style={{ color: '#61A24F' }} className="font-bold text-xs">Upgrade to Pro</span></h1>
                  </span> */}
                <span className="flex justify-between px-2 pt-3">
                  <p className='mt-2'>Create New Staff</p>
                  <p className="cursor-pointer font-bold" onClick={(e) => setAddModal(false)}><SvgElement type={icontypesEnum.CANCEL} /></p>
                </span>


                <form onSubmit={handleSubmit}>
                  <div className="flex flex-col lg:flex-row lg:justify-between max-w-screen-xl mx-auto  mt-5">
                    <div className="relative flex flex-col min-w-0 break-words w-full  ">
                      <div className="flex-auto   py-10 pt-0">
                        <div className="flex flex-wrap ">

                          <div className="lg:w-6/12 w-full lg:pr-4">
                            <div className="flex justify-between mb-3">
                              <label className="text-[#333333] text-[12px]">First Name</label>
                              <SvgElement type={icontypesEnum.QUESTIONMARK} />
                            </div>
                            <div className="relative mb-4">
                              <input type="text" name="first_name" value={userData?.first_name} onChange={handleChange} className=" border border-[#D1D1D1] text-gray-900 text-sm rounded-[5px] block w-full pl-6 p-2.5" placeholder="John" />
                            </div>
                          </div>

                          <div className="lg:w-6/12 w-full ">
                            <div className="flex justify-between mb-3">
                              <label className="text-[#333333] text-[12px]">Last Name</label>
                              <SvgElement type={icontypesEnum.QUESTIONMARK} />
                            </div>
                            <div className="relative mb-4">

                              <input type="text" name="last_name" value={userData?.last_name} onChange={handleChange} className=" border border-[#D1D1D1] text-gray-900 text-sm rounded-[5px] block w-full pl-6 p-2.5" placeholder="Doe" />
                            </div>
                          </div>

                          <div className="lg:w-6/12 w-full lg:pr-4">
                            <div className="flex justify-between mb-3">
                              <label className="text-[#333333] text-[12px] font-[400]">Email</label>
                              <SvgElement type={icontypesEnum.QUESTIONMARK} />
                            </div>
                            <div className="relative mb-4">

                              <input type="email" name="email" value={userData?.email} onChange={handleChange} className=" border border-[#D1D1D1] text-gray-900 text-sm rounded-[5px] block w-full pl-6 p-2.5" placeholder="john@gmail.com" />
                            </div>
                          </div>

                          {/* <div className="lg:w-6/12 w-full lg:pr-4">
                          <div className="flex justify-between mb-3">
                            <label className="text-[#333333] text-[12px] font-[400]">Branch</label>
                            <SvgElement type={icontypesEnum.QUESTIONMARK} />
                          </div>
                          <div className="relative mb-4">

                            <input type="text" name="office" defaultValue={userData?.office} onChange={handleChange} className=" border border-[#D1D1D1] text-gray-900 text-sm rounded-[5px] block w-full pl-6 p-2.5" placeholder="" />
                          </div>
                        </div> */}

                          <div className="lg:w-6/12 w-full ">
                            <div className="flex justify-between mb-3">
                              <label className="text-[#333333] text-[12px] font-[400]">Phone Number</label>
                              <SvgElement type={icontypesEnum.QUESTIONMARK} />
                            </div>
                            <div className="relative mb-4">

                              <input type="number" name="phone_number" value={userData?.phone_number} onChange={handleChange} className=" border border-[#D1D1D1] text-gray-900 text-sm rounded-[5px] block w-full pl-6 p-2.5" placeholder="+234..." />
                            </div>
                          </div>

                          <div className="lg:w-6/12 w-full lg:pr-4">
                            <div className="relative w-full mb-5">
                              <label className="block mb-2 text-sm  text-[#19213D]">
                                Department
                              </label>
                              <select required onChange={(e) => setRole(e.target.value)} id="countries" className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150">
                                <option disabled selected>--Select Department--</option>
                                <option value="operations">Operations</option>
                                <option value="customer service">Customer Service</option>
                                <option value="finance">Finance</option>
                                <option value="sales">Sales</option>
                                <option value="audit">Audit</option>
                                <option value="group head">Group Head</option>
                                <option value="general manager">General Manager</option>
                                  <option value="hr">HR</option>
                                {/* <option value="head of department">Head Of Department</option> */}
                              </select>
                            </div>
                          </div>

                          <div className="lg:w-6/12 w-full ">
                            <div className="relative w-full mb-5">
                              <label className="block mb-2 text-sm  text-[#19213D]">
                                Branch
                              </label>
                              <select required onChange={(e) => setBranchData(e.target.value)} id="countries" className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150">
                                <option disabled selected>--Select Branch--</option>
                                {branch?.data?.map((datas: any, index: any) => (
                                  <option key={index} value={datas?.state}>{datas?.branch_name}</option>
                                )
                                )}
                              </select>
                            </div>
                          </div>



                          <div className="lg:w-12/12 w-full ">
                            <label className="block mb-2 text-sm  text-[#19213D]">
                              Select Permission
                            </label>
                            <select onChange={(e) => { permissionDropdownChange(e?.target?.value) }} className="bg-gray-50 border cursor-pointer border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                              <option value={''}>Select Roles</option>
                              <option value="can_view_admin_stat">can_view_admin_stat</option>
                              <option value="can_view_users">can_view_users</option>
                              <option value="can_manage_credit_customers">can_manage_credit_customers</option>
                              <option value="can_create_expense_voucher">can_create_expense_voucher</option>
                              <option value="can_manage_shipment">can_manage_shipment</option>
                              <option value="can_manage_manifest">can_manage_manifest</option>
                              <option value="can_manage_runway_manifest">can_manage_runway_manifest</option>
                              <option value="can_manage_fleet">can_manage_fleet</option>
                              <option value="can_manage_branch">can_manage_branch</option>
                              <option value="can_view_audit">can_view_audit</option>
                              <option value="can_manage_staffs">can_manage_staffs</option>
                              <option value="can_manage_invoice">can_manage_invoice</option>
                              <option value="can_manage_rates">can_manage_rates</option>

                            </select>
                          </div>

                          <div className="lg:w-12/12 mb-4 w-full min-h-10  max-h-40 overflow-auto flex flex-wrap gap-3 mt-4 border rounded-md border-gray-800 p-2">
                            {permissionList?.map(
                              (data: any, index: any) => (
                                <div key={index} className=' overflow-auto'>
                                  <span
                                    style={{ backgroundColor: '#E04343' }}
                                    className="flex mx-0 gap-3 justify-between px-1 py-1 text-center text-white text-[10px] rounded-lg  "
                                  >
                                    <span>{data}</span>
                                    <span className=" rounded-lg px-1 cursor-pointer" onClick={() => remove(data, index)} style={{ backgroundColor: 'white', color: '#E04343' }}>X</span>

                                  </span>

                                </div>
                              ))}
                          </div>


                          <div className="lg:w-6/12 w-full lg:pr-4">
                            <div className="flex justify-between mb-3">
                              <label className="text-[#333333] text-[12px] font-[400]">Password</label>
                              <SvgElement type={icontypesEnum.QUESTIONMARK} />
                            </div>
                            <div className="relative mb-4">

                              <input type="password" name="password" value={userData?.password} onChange={handleChange} className=" border border-[#D1D1D1] text-gray-900 text-sm rounded-[5px] block w-full pl-6 p-2.5" placeholder="" />
                            </div>
                          </div>

                          <div className="lg:w-6/12 w-full ">
                            <div className="flex justify-between mb-3">
                              <label className="text-[#333333] text-[12px] font-[400]">Confirm Password</label>
                              <SvgElement type={icontypesEnum.QUESTIONMARK} />
                            </div>
                            <div className="relative mb-4">

                              <input type="password" name="confirm_password" value={userData?.confirm_password} onChange={handleChange} className=" border border-[#D1D1D1] text-gray-900 text-sm rounded-[5px] block w-full pl-6 p-2.5" placeholder="" />
                            </div>
                          </div>



                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-end pb-8">

                    {/* <div className="flex items-center mb-2 mt-4">
                    <input id="default-checkbox" type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-[#D1D1D1] rounded " />
                    <label htmlFor="default-checkbox" className="ml-3 text-sm font-medium text-[#333333]">I agree with <span className="text-[#E04343]">terms and conditins</span></label>
                  </div> */}

                    <div className="mt-1 ">
                      <button type="submit" disabled={loader} className="text-white bg-[#E04343] rounded-[30px] transition ease-out delay-300 py-[10px] px-[42px] font-[700] text-[14px]">
                        <div className="flex justify-center gap-3 ">
                          <span>Register</span>
                          <Oval
                            visible={loader}
                            height="20"
                            width="20"
                            color="#E04343"
                            secondaryColor="#FCBF94"
                            ariaLabel="oval-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                          />
                        </div>
                      </button>


                    </div>

                  </div>
                </form>
              </div>

            </div>

          </div>
        </Modal>
      </section>


      <section>
        <Modal
          visible={riderModal}
          width="550"
          height="650"
          effect="fadeInUp"
          onClickAway={() => { setRiderModal(false); setPermissionList([]) }}
        >
          <div className=" " style={{ height: '100%', overflow: 'auto' }}>

            <div className="container  bg-[#fff] rounded-lg p-2">

              <div className="px-3">

                {/* <span className="flex justify-around">
                    <h1 className=" text-xs text-red-600" style={{ fontSize: '10px' }}>Link can’t be edited in free plan. <span style={{ color: '#61A24F' }} className="font-bold text-xs">Upgrade to Pro</span></h1>
                  </span> */}
                <span className="flex justify-between px-2 pt-3">
                  <p className='mt-2'>Update Staff</p>
                  <p className="cursor-pointer font-bold" onClick={(e) => { setRiderModal(false); setPermissionList([]) }}><SvgElement type={icontypesEnum.CANCEL} /></p>
                </span>


                <form onSubmit={updateStaff}>
                  <div className="flex flex-col lg:flex-row lg:justify-between max-w-screen-xl mx-auto  mt-5">
                    <div className="relative flex flex-col min-w-0 break-words w-full  ">
                      <div className="flex-auto   py-10 pt-0">
                        <div className="flex flex-wrap ">

                          <div className="lg:w-6/12 w-full lg:pr-4">
                            <div className="flex justify-between mb-3">
                              <label className="text-[#333333] text-[12px]">First Name</label>
                              <SvgElement type={icontypesEnum.QUESTIONMARK} />
                            </div>
                            <div className="relative mb-4">
                              <input type="text" name="first_name" defaultValue={shipmentId?.first_name} onChange={handleChange2} className=" border border-[#D1D1D1] text-gray-900 text-sm rounded-[5px] block w-full pl-6 p-2.5" placeholder="John" />
                            </div>
                          </div>

                          <div className="lg:w-6/12 w-full ">
                            <div className="flex justify-between mb-3">
                              <label className="text-[#333333] text-[12px]">Last Name</label>
                              <SvgElement type={icontypesEnum.QUESTIONMARK} />
                            </div>
                            <div className="relative mb-4">
                              <input type="text" name="last_name" defaultValue={shipmentId?.last_name} onChange={handleChange2} className=" border border-[#D1D1D1] text-gray-900 text-sm rounded-[5px] block w-full pl-6 p-2.5" placeholder="role" />
                            </div>
                          </div>

                          <div className="lg:w-6/12 w-full lg:pr-4">
                            <div className="flex justify-between mb-3">
                              <label className="text-[#333333] text-[12px] font-[400]">Email</label>
                              <SvgElement type={icontypesEnum.QUESTIONMARK} />
                            </div>
                            <div className="relative mb-4">
                              <input type="email" disabled name="email" defaultValue={shipmentId?.email} onChange={handleChange2} className=" border border-[#D1D1D1] text-gray-900 text-sm rounded-[5px] block w-full pl-6 p-2.5" placeholder="john@gmail.com" />
                            </div>
                          </div>

                          <div className="lg:w-6/12 w-full">
                            <div className="flex justify-between mb-3">
                              <label className="text-[#333333] text-[12px] font-[400]">Phone Number</label>
                              <SvgElement type={icontypesEnum.QUESTIONMARK} />
                            </div>
                            <div className="relative mb-4">

                              <input type="text" name="phone_number" defaultValue={shipmentId?.phone_number} onChange={handleChange2} className=" border border-[#D1D1D1] text-gray-900 text-sm rounded-[5px] block w-full pl-6 p-2.5" placeholder="+234..." />
                            </div>
                          </div>

                          <div className="lg:w-6/12 w-full lg:pr-4">
                            <div className="relative w-full mb-5">
                              <label className="block mb-2 text-sm  text-[#19213D]">
                                Department
                              </label>
                              {/* <select required name="role" onChange={(e) => handleChange2(e)} id="countries" className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150">
                                */}
                                <select required name='role' onChange={(e) => handleChange2(e)} id="countries" className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150">
                                <option disabled selected value={shipmentId?.role}>{shipmentId?.role}</option>
                                  <option value="operations">Operations</option>
                                  <option value="customer service">Customer Service</option>
                                  <option value="finance">Finance</option>
                                  <option value="sales">Sales</option>
                                  <option value="audit">Audit</option>
                                  <option value="group head">Group Head</option>
                                  <option value="general manager">General Manager</option>
                                  <option value="hr">HR</option>
                                  {/* <option value="head of department">Head of Department</option> */}
                                {/* </select> */}
                              </select>
                            </div>
                          </div>

                          <div className="lg:w-6/12 w-full ">
                            <div className="relative w-full mb-5">
                              <label className="block mb-2 text-sm  text-[#19213D]">
                                Branch
                              </label>
                              <select required name="office" onChange={(e) => handleChange2(e)} id="countries2" className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150">
                                <option disabled selected value={shipmentId?.office}>{shipmentId?.office}</option>
                                {branch?.data?.map((datas: any, index: any) => (
                                  <option key={index} value={datas?.state}>{datas?.branch_name}</option>
                                )
                                )}
                              </select>
                            </div>
                          </div>



                          <div className="lg:w-12/12 w-full ">
                            <label className="block mb-2 text-sm  text-[#19213D]">
                              Select Permissions
                            </label>
                            <select onChange={(e) => { permissionDropdownChange(e?.target?.value) }} className="bg-gray-50 border cursor-pointer border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                              <option value={''}>Select Permissions</option>
                              <option value="can_view_admin_stat">can_view_admin_stat</option>
                              <option value="can_view_users">can_view_users</option>
                              <option value="can_manage_credit_customers">can_manage_credit_customers</option>
                              <option value="can_create_expense_voucher">can_create_expense_voucher</option>
                              <option value="can_manage_shipment">can_manage_shipment</option>
                              <option value="can_manage_manifest">can_manage_manifest</option>
                              <option value="can_manage_runway_manifest">can_manage_runway_manifest</option>
                              <option value="can_manage_fleet">can_manage_fleet</option>
                              <option value="can_manage_branch">can_manage_branch</option>
                              <option value="can_view_audit">can_view_audit</option>
                              <option value="can_manage_staffs">can_manage_staffs</option>
                              <option value="can_manage_invoice">can_manage_invoice</option>
                              <option value="can_manage_rates">can_manage_rates</option>

                            </select>
                          </div>

                          <div className="lg:w-12/12 mb-4 w-full min-h-10  max-h-40 overflow-auto flex flex-wrap gap-3 mt-4 border rounded-md border-gray-800 p-2">
                            {permissionList?.map(
                              (data: any, index: any) => (
                                <div key={index} className=' overflow-auto'>
                                  <span
                                 
                                    style={{ backgroundColor: '#E04343' }}
                                    className="flex mx-0 gap-3 justify-between px-1 py-1 text-center text-white text-[10px] rounded-lg  "
                                  >
                                    <span>{data}</span>
                                    <span className=" rounded-lg px-1 cursor-pointer" onClick={() => remove(data, index)} style={{ backgroundColor: 'white', color: '#E04343' }}>X</span>

                                  </span>

                                </div>
                              ))}
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-end pb-8">

                    {/* <div className="flex items-center mb-2 mt-4">
                    <input id="default-checkbox" type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-[#D1D1D1] rounded " />
                    <label htmlFor="default-checkbox" className="ml-3 text-sm font-medium text-[#333333]">I agree with <span className="text-[#E04343]">terms and conditins</span></label>
                  </div> */}

                    <div className="mt-1 ">
                      <button type="submit" disabled={loader} className="text-white bg-[#E04343] rounded-[30px] transition ease-out delay-300 py-[10px] px-[42px] font-[700] text-[14px]">
                        <div className="flex justify-center gap-3 ">
                          <span>update</span>
                          <Oval
                            visible={loader}
                            height="20"
                            width="20"
                            color="#E04343"
                            secondaryColor="#FCBF94"
                            ariaLabel="oval-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                          />
                        </div>
                      </button>
                    </div>

                  </div>
                </form>
              </div>

            </div>

          </div>
        </Modal>
      </section>


      <section>
        <Modal
          visible={deleteStaffModal}
          width="400"
          height="200"
          effect="fadeInUp"
          onClickAway={() => setDeleteStaffModal(false)}
        >
          <div className=" " style={{ overflow: 'auto' }}>

            <div className="container  bg-[#fff] rounded-lg p-2">

              <div className="px-3">

                {/* <span className="flex justify-around">
                    <h1 className=" text-xs text-red-600" style={{ fontSize: '10px' }}>Link can’t be edited in free plan. <span style={{ color: '#61A24F' }} className="font-bold text-xs">Upgrade to Pro</span></h1>
                  </span> */}
                <span className="flex justify-between px-2 pt-3">
                  <p className='mt-2 font-bold'>Delete Staff</p>
                  <p className="cursor-pointer font-bold" onClick={(e) => setDeleteStaffModal(false)}><SvgElement type={icontypesEnum.CANCEL} /></p>
                </span>


                <form onSubmit={deleteStaff}>
                  <div className="flex justify-center my-5">
                    <p>Are you sure you want to delete this staff?</p>
                  </div>

                  <div className="flex justify-center pb-8">
                    <div className="mt-1 ">
                      <button type="submit" disabled={loader} className="text-white bg-[#E04343] rounded-[30px] transition ease-out delay-300 py-[10px] px-[42px] font-[700] text-[14px]">
                        <div className="flex justify-center gap-3 ">
                          <span>Delete</span>
                          <Oval
                            visible={loader}
                            height="20"
                            width="20"
                            color="#E04343"
                            secondaryColor="#FCBF94"
                            ariaLabel="oval-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                          />
                        </div>
                      </button>
                    </div>
                  </div>





                </form>
              </div>

            </div>

          </div>
        </Modal>
      </section>

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
    </>
  )
}

export default ManageStaffs