import React from 'react'
import AdminNavbar from "../../Navbars/AdminNavbar";
import Sidebar from "../../Sidebar/Sidebar";
import { SuperAdminApis } from '../../../apis/superAdminApi';
import { useNavigate } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { convertUTCDateToLocalDate } from '../../Reusable/functions';
//@ts-ignore
import Modal from 'react-awesome-modal';
import { NavLink } from "react-router-dom";
import { SvgElement, icontypesEnum } from '../../assets/svgElement';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function AddLgaToModal(props: any) {

  const navigate = useNavigate();
  const [lgaName, setLgaName] = React.useState<any>('');
  const [cost, setCost] = React.useState<any>('');
  const [clientEmail, setClientEmail] = React.useState<any>('');


  // React.useEffect(() => {
  //   SuperAdminApis.getAllCountryFrom('').then(
  //     (response: AxiosResponse<any>) => {
  //       if (response?.data) {
  //         setUserList(response?.data?.data)
  //         console?.log(response?.data?.data)
  //       }
  //     }
  //   ).catch(function (error: any) {
  //     // handle error

  //   })
  // }, []);


  const handleSubmit = React.useCallback(
    (e: any) => {
      e.preventDefault();

      const formData = new FormData()
      formData.append('country_id', props?.data?.id)
      formData.append('state_id', props?.stateData?.id) 
      formData.append('lga_name', lgaName)
      formData.append('client_id', clientEmail)
      formData.append('cost', cost)

      SuperAdminApis.addLgaTo(formData).then(
        (response: any) => {
          if (response?.data) {

            toast.success(response?.data?.message);
            setLgaName('')
            setCost('')
            props?.handleClose()
          } else {
            toast.error(response?.response?.data?.message);
          }

          // toast.success(response?.data?.message);
        }
      ).catch(function (error) {
        // handle error
        // console.log(error.response);
        toast.error(error.response.data.message);

      })
    },
    [lgaName,cost,props,clientEmail]
  );


  return (
    <>

      <section>
        <Modal
          visible={props?.show}
          width="400"
          height="500"
          effect="fadeInUp"
          onClickAway={() => props?.handleClose}
        >
          <div className=" " style={{ overflow: 'auto' }}>
            <span className="flex justify-between px-3 pt-3 mb-3">
              <p className='font-bold'>Add LGA in {props?.stateData?.state_name}</p>
              <p className="cursor-pointer font-bold" onClick={props?.handleClose}><SvgElement type={icontypesEnum.CANCEL} /></p>

            </span>

            <form onSubmit={handleSubmit}>
              <div className=' px-3'>
                <div className="flex flex-col lg:flex-row lg:justify-between max-w-screen-xl mx-auto ">
                  <div className="relative flex flex-col min-w-0 break-words w-full  ">
                    <div className="flex-auto   py-10 pt-0">
                      <div className="flex flex-wrap ">   

                        <div className="w-full lg:w-12/12 lg:pr-4">
                          <div className="relative w-full mb-5">
                            <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                              State LGA Name
                            </label>
                            <input
                              type="text"
                              onChange={(e)=>setLgaName(e.target.value)}
                              value={lgaName}
                              className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                              placeholder=""
                              

                              // onChange={null}
                              required
                            />
                          </div>
                        </div>

                        <div className="w-full lg:w-12/12 lg:pr-4">
                          <div className="relative w-full mb-5">
                            <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                             Client Email (Optional)
                            </label>
                            <input
                              type="text"
                              onChange={(e)=>setClientEmail(e.target.value)}
                              className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                              placeholder=""
                              value={clientEmail}
                              name="DeadWeight"
                              // onChange={null}
                              
                            />
                          </div>
                        </div>


                        <div className="w-full lg:w-12/12 lg:pr-4">
                          <div className="relative w-full mb-5">
                            <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                              Cost
                            </label>
                            <input
                              type="number"
                              onChange={(e)=>setCost(e.target.value)}
                              className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                              placeholder=""
                              value={cost}
                              
                              // onChange={null}
                              required
                            />
                          </div>
                        </div>

                        <div className="w-full flex justify-end">
                          <button type='submit' className='bg-[#E04343] text-white py-[10px] rounded w-full'>Add LGA</button>
                        </div>


                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </form>




          </div>
        </Modal>
      </section>

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
    </>
  )
}

export default AddLgaToModal