import { AxiosGlobal } from "../shared/axios";
import{ AxiosPromise} from "axios";
import configs from "../../configs";
import { store } from "../../store/store";



export class PaymentLiveApis extends AxiosGlobal{ 
    
    payForProduct(data:any): AxiosPromise<any> {
        return this.axios.post(`${configs.context}/payment/pay-for-product`, data);
    }


   


    verifyShipmentPayment(data:any): AxiosPromise<any> {
        return this.axios.post(`${configs.context}/verify-shipment-payment`, data,{
            headers: { "Content-Type": "aplication/json","Accept":"aplication/json","Authorization":`Bearer ${store.getState().data.login.value.token}`,"Access-Control-Allow-Origin":"*" },
          });
    }


    verifyCreditShipmentPayment(data:any): AxiosPromise<any> {
        return this.axios.post(`${configs.context}/verify-credit-shipment-payment`, data,{
            headers: { "Content-Type": "aplication/json","Accept":"aplication/json","Authorization":`Bearer ${store.getState().data.login.value.token}`,"Access-Control-Allow-Origin":"*" },
          });
    }


    pushShipmentToGetonline(data:any): AxiosPromise<any> {
        return this.axios.post(`${configs.context}/add-shipment-to-getonline`, data,{
            headers: { "Content-Type": "aplication/json","Accept":"aplication/json","Authorization":`Bearer ${store.getState().data.login.value.token}`,"Access-Control-Allow-Origin":"*" },
          });
    }

   


}