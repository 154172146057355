import React from 'react'
import AdminNavbar from "../Navbars/AdminNavbar";
import Sidebar from "../Sidebar/Sidebar";
import { SuperAdminApis } from '../../apis/superAdminApi';
import { useNavigate } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { convertUTCDateToLocalDate } from '../Reusable/functions';
//@ts-ignore
import Modal from 'react-awesome-modal';
import { SvgElement, icontypesEnum } from '../assets/svgElement';
import { AuthApis } from '../../apis/authApis';
import { error } from 'console';
import AdminSidebar from '../Sidebar/AdminSidebar';
import { Oval } from 'react-loader-spinner';
function ManageBranch() {

  const navigate = useNavigate();
  const [userLists, setUserList] = React.useState<any>([]);
  const [searchText, setSearchText] = React.useState('');
  const [stateFrom, setStateFrom] = React.useState<any>([]);

  let [addModal, setAddModal] = React.useState<boolean>(false);
  let [loader, setLoader] = React.useState<boolean>(false);
  let [officeModal, setOfficeModal] = React.useState<boolean>(false);

  const [phone, setPhone] = React.useState<any>("");

  const [userData, setUserdata] = React.useState<any>({
    'branch_name': "",
    'state': "",
    'zone': ""
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target;
    setUserdata({ ...userData, [name]: value });
  };




  const handleSubmit = React.useCallback(
    (e: React.ChangeEvent<HTMLFormElement>) => {
      e.preventDefault();
      setLoader(true);
      const formData = new FormData()
      formData.append('branch_name', userData?.branch_name)
      formData.append('zone', userData?.zone)
      formData.append('state', userData?.state)

      SuperAdminApis.createBranch(formData).then(
        (response: AxiosResponse<any>) => {
          if (response?.data) {
            if (response?.data?.status === true) {
              // dispatch(login({ email: userData?.email, token: response.data.token, name: response.data.name }))
              toast.success(response?.data?.message);
              setLoader(false);
              setUserdata([]);
              // navigate('/email-verify');

            } else {
              // toast.warn("Something went wrong,Try Again");
            }

          } else {
            setLoader(false);
            toast.warn("Something went wrong,Try Again");
          }

          setAddModal(false)
          const query: any = {
            search: searchText,
          };
          SuperAdminApis.getAllBranch('', query).then(
            (response: AxiosResponse<any>) => {
              if (response?.data) {
                setLoader(false);
                setUserList(response?.data?.data)
              }
            }
          )
          setLoader(false);
          setUserdata([])

          // toast.success(response?.data?.message);
        }
      ).catch(function (error) {
        // handle error
        console.log(error.response.data);
        toast.warn(error.response?.data?.message);
        toast.error("Offfline");
      }).finally(() => {

      });
    },
    [userData, phone, userLists,false]
  );

  let [riderModal, setRiderModal] = React.useState<boolean>(false);

  let [riderDeleteModal, setRiderDeleteModal] = React.useState<boolean>(false);
  let [shipmentId, setShipmentId] = React.useState<any>([]);
  let [shipmentRider, setShipmentRider] = React.useState<any>('');
  let [riders, setRiders] = React.useState<any>([]);

  const updateRider = React.useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoader(true);
    SuperAdminApis.updateBranch(shipmentId)
      .then((response: any) => {
        if (response?.data) {
          if (response?.data?.status) {
            toast.success(response?.data?.message);
            setRiderModal(false);
            setLoader(false);
            setShipmentRider('')

          }
          const query: any = {
            search: '',
          };
          SuperAdminApis.getAllBranch('', query).then(
            (response: AxiosResponse<any>) => {
              if (response?.data) {
                setLoader(false);
                setUserList(response?.data?.data)
              }
            }
          )
          // console?.log(response?.data)
        }
      }).catch((err: any) => {
        console.log(err);
      })

  }, [shipmentId, shipmentRider,loader, userLists]);

  const deleteRider = React.useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoader(true);

    SuperAdminApis.deleteBranch(shipmentId?.id)
      .then((response: any) => {
        if (response?.data) {
          if (response?.data?.status) {
            toast.success(response?.data?.message);
            setRiderDeleteModal(false)
            setShipmentRider('')
            setLoader(false);

          }
          const query: any = {
            search: '',
          };
          SuperAdminApis.getAllBranch('', query).then(
            (response: AxiosResponse<any>) => {
              if (response?.data) {
                setLoader(false);
                setUserList(response?.data?.data)
              }
            }
          )
          // console?.log(response?.data)
        }
      }).catch((err: any) => {
        console.log(err);
      })

  }, [shipmentId, shipmentRider,loader, userLists]);


  const updateRiderStat = React.useCallback((e: React.FormEvent<HTMLFormElement>, status: any) => {
    e.preventDefault();

    let data = {
      "id": status?.id,
      "first_name": status?.first_name,
      "last_name": status?.last_name,
      "phone_number": status?.phone_number,
      "isVerified": status?.isVerified == 'false' ? 'true' : 'false',
    }
    SuperAdminApis.updateRider(data)
      .then((response: any) => {
        if (response?.data) {
          if (response?.data?.status) {
            toast.success(status?.isVerified == 'false' ? "Account Activated" : "Account Deactivated");
            setRiderModal(false);
            setShipmentRider('')

          }
          const query: any = {
            search: '',
          };
          SuperAdminApis.getAllBranch('', query).then(
            (response: AxiosResponse<any>) => {
              if (response?.data) {
                setUserList(response?.data?.data)
              }
            }
          )
          // console?.log(response?.data)
        }
      }).catch((err: any) => {
        console.log(err);
      })

  }, [shipmentId, shipmentRider, userLists]);


  React.useEffect(() => {
    const query: any = {
      search: searchText,
    };
    SuperAdminApis.getAllBranch('', query).then(
      (response: AxiosResponse<any>) => {
        if (response?.data) {
          setUserList(response?.data?.data)
        }
      }
    ).catch(function (error) {
      // handle error

    })



    let data = {
      "country_id": 1
    }
    SuperAdminApis.getAllStateTo('', data).then(
      (response: AxiosResponse<any>) => {
        if (response?.data) {
          setStateFrom(response?.data?.data)
          // console?.log(response?.data?.data)
        }
      }
    )
  }, []);



  const paginator = React.useCallback(
    (value: any) => {
      //   setLoader(true);
      let value2 = '';
      if (value !== null) {
        value2 = value;
      } else {
        value2 = ''
      }
      const query: any = {
        search: searchText,
      };

      SuperAdminApis.getAllBranch(value2, query).then(
        (response: AxiosResponse<any>) => {
          if (response?.data) {
            setUserList(response?.data?.data)
          }
        }
      ).catch(function (error) {
        console.log(error.response.data);
      })

    }, [userLists, searchText]);

  const handleChange2 = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target;
    setShipmentId({ ...shipmentId, [name]: value });
  };



  return (
    <>
      <AdminSidebar title="Branch" />
      <div className="relative md:ml-64 bg-[#FAFAFA] ">
        <AdminNavbar title="Branch" />
        <div className=' lg:px-10 pb-6 px-6 bg-[#FAFAFA] min-h-screen'>

          <div className='flex justify-between my-5'>
            <div>
              <h3 className='text-[15px] text-[#1C1C1C] font-semibold'>Branch List</h3>

            </div>
            <div>
              {/* <button className='bg-[#E04343] text-white text-[14px] px-[12px] py-[8px] rounded-[10px]'>+ Add Customers</button> */}
              <div className='flex justify-end'>
                <div className="relative flex w-full flex-wrap items-stretch">
                  <div className="absolute inset-y-0 right-2 flex items-center pl-3 pointer-events-none">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="#9da4aa" d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0a5.5 5.5 0 0 1 11 0z" /></svg>
                  </div>
                  <input type="text" onClick={() => paginator('')} onChange={e => setSearchText(e.target.value)} placeholder='search...' id="simple-search" className=" border border-gray-300 text-gray-500 text-sm rounded-md block w-full pl-4 p-1  " required />
                </div>

                <div className='mt-0.5 ml-2'><button type='button' onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => paginator('')} className={"font-normal text-white bg-[#E04343] px-3 py-0.5 rounded-md"}>Search</button> </div>

              </div>

              <div className='flex justify-end mt-4'>
                <div className='mt-0.5 ml-2'><button type='button' onClick={() => setAddModal(true)} className={"font-normal text-white bg-[#E04343] px-3 py-0.5 rounded-md"}>Add Branch</button> </div>
              </div>


            </div>
          </div>


          <div className="relative overflow-x-auto">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 border-separate border-spacing-y-3">
              <thead className="text-[12px] text-[#030229] bg-[#FAFAFA] ">
                <tr>

                  <th scope="col" className="px-2 py-2">
                    S/N
                  </th>

                  <th scope="col" className="px-2 py-2">
                    Name
                  </th>
                  <th scope="col" className="px-2 py-2">
                    State
                  </th>

                  <th scope="col" className="px-2 py-2">
                    Zone
                  </th>

                  <th scope="col" className="px-2 py-4">
                    Action
                  </th>

                </tr>
              </thead>


              <tbody className=''>

                {
                  userLists?.data?.length > 0 ?
                    userLists?.data?.map(
                      (datas: any, index: any) => (
                        <tr className="bg-white  ">

                          <td className="px-2 py-2">
                            {index + 1}
                          </td>

                          <td className="px-2 py-4">
                            {datas?.branch_name}
                          </td>
                          <td className="px-2 py-4 ">
                            {datas?.state}
                          </td>

                          <td className="px-2 py-4 ">
                            {datas?.zone}
                          </td>



                          <td className='flex justify-between px-2 py-4'>
                            <span className="hover:underline text-blue-500 cursor-pointer" onClick={() => { setRiderModal(true); setShipmentId(datas) }}>
                              Edit
                            </span>

                            <span className="hover:underline text-red-500 cursor-pointer" onClick={() => { setRiderDeleteModal(true); setShipmentId(datas) }}>
                              Delete
                            </span>


                          </td>






                        </tr>
                      )
                    )
                    :
                    <div className='flex justify-start mt-4'>
                      <td >No Record Found</td>
                    </div>


                }

              </tbody>
            </table>
          </div>
          <div className='m-4 flex justify-end mt-20'>
            {
              userLists?.links?.filter(((item: any, idx: any) => idx < 1000)).map(
                (datas: any, index: any) => (
                  <button onClick={() => paginator(datas?.label == 'Next &raquo;' ? datas?.url?.charAt(datas?.url?.length - 1) : (datas?.label === '&laquo; Previous') ? datas?.url?.charAt(datas?.url?.length - 1) : datas?.label)} disabled={datas?.active} className={'mx-1 py-1 px-2 ' + ((datas?.active == false || datas?.url == null) ? 'bg-gray-300 text-black ' : 'bg-[#E04343] text-white')}>
                    {datas?.label == '&laquo; Previous' ? '< Previous' : (datas?.label === 'Next &raquo;') ? 'Next  >' : datas?.label}
                  </button>
                )
              )
            }

          </div>
        </div>
      </div>


      <section>
        <Modal
          visible={addModal}
          width="500"
          height="400"
          effect="fadeInUp"
          onClickAway={() => setAddModal(false)}
        >
          <div className=" " style={{ height: '100%', overflow: 'auto' }}>

            <div className="container  bg-[#fff] rounded-lg p-2">

              <div className="px-3">

                {/* <span className="flex justify-around">
                    <h1 className=" text-xs text-red-600" style={{ fontSize: '10px' }}>Link can’t be edited in free plan. <span style={{ color: '#61A24F' }} className="font-bold text-xs">Upgrade to Pro</span></h1>
                  </span> */}
                <span className="flex justify-between px-2 pt-3">
                  <p className='mt-2'>Create New Branch</p>
                  <p className="cursor-pointer font-bold" onClick={(e) => setAddModal(false)}><SvgElement type={icontypesEnum.CANCEL} /></p>
                </span>


                <form onSubmit={handleSubmit}>
                  <div className="flex flex-col lg:flex-row lg:justify-between max-w-screen-xl mx-auto  mt-5">
                    <div className="relative flex flex-col min-w-0 break-words w-full  ">
                      <div className="flex-auto   py-10 pt-0">
                        <div className="flex flex-wrap ">

                          <div className="lg:w-12/12 w-full lg:pr-4">
                            <div className="flex justify-between mb-3">
                              <label className="text-[#333333] text-[12px]">Branch name</label>
                              <SvgElement type={icontypesEnum.QUESTIONMARK} />
                            </div>
                            <div className="relative mb-4">
                              <input type="text" required name="branch_name" onChange={handleChange} className=" border border-[#D1D1D1] text-gray-900 text-sm rounded-[5px] block w-full pl-6 p-2.5" placeholder="John" />
                            </div>
                          </div>


                          <div className="w-full lg:w-6/12 lg:pr-4">
                            <div className="relative w-full mb-5">
                              <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                Zone
                              </label>
                              <select id="countries" name='zone' onChange={(e: any) => handleChange(e)} className="border border-[#D1D1D1] bg-white text-[#333333] text-sm rounded-[5px] block w-full p-2.5 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150">
                                <option disabled selected>--Select Zone--</option>
                                <option value="north-central">North Central</option>
                                <option value="north-east">North East</option>
                                <option value="north-west">North West</option>
                                <option value="south-east">South East</option>
                                <option value="south-south">South South</option>
                                <option value="south-west">South West</option>
                              </select>

                            </div>
                          </div>

                          {/* <div className="lg:w-6/12 w-full lg:pr-4">
                            <div className="flex justify-between mb-3">
                              <label className="text-[#333333] text-[12px]">Zone</label>
                              <SvgElement type={icontypesEnum.QUESTIONMARK} />
                            </div>
                            <div className="relative mb-4">

                              <input type="text" required name="zone" onChange={handleChange} className=" border border-[#D1D1D1] text-gray-900 text-sm rounded-[5px] block w-full pl-6 p-2.5" placeholder="Smith" />
                            </div>
                          </div> */}


                          <div className="w-full lg:w-6/12 lg:pr-4">
                            <div className="relative w-full mb-5">
                              <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                State
                              </label>
                              <select id="countries" name='state' onChange={(e: any) => handleChange(e)} className="border border-[#D1D1D1] bg-white text-[#333333] text-sm rounded-[5px] block w-full p-2.5 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150">
                                <option disabled selected>--Select State--</option>
                                {stateFrom?.data?.filter((data: any) => data?.client_id == null)?.map((datas: any, index: any) => (
                                  <option value={datas?.state_name}>{datas?.state_name}</option>
                                )
                                )}
                              </select>

                            </div>
                          </div>

                          {/* <div className="lg:w-6/12 w-full lg:pr-4">
                          <div className="flex justify-between mb-3">
                            <label className="text-[#333333] text-[12px] font-[400]">State</label>
                            <SvgElement type={icontypesEnum.QUESTIONMARK} />
                          </div>
                          <div className="relative mb-4">

                            <input type="text" required name="state"  onChange={handleChange} className=" border border-[#D1D1D1] text-gray-900 text-sm rounded-[5px] block w-full pl-6 p-2.5" placeholder="Ondo State..." />
                          </div>
                        </div> */}

                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-end pb-8">

                    {/* <div className="flex items-center mb-2 mt-4">
                    <input id="default-checkbox" type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-[#D1D1D1] rounded " />
                    <label htmlFor="default-checkbox" className="ml-3 text-sm font-medium text-[#333333]">I agree with <span className="text-[#E04343]">terms and conditins</span></label>
                  </div> */}

                    <div className="mt-1 ">
                      <button type="submit" disabled={loader} className="text-white bg-[#E04343] rounded-[30px] transition ease-out delay-300 py-[10px] px-[42px] font-[700] text-[14px]">

                        <div className="flex justify-center gap-3 ">
                          <span> Register </span>
                          <Oval
                            visible={loader}
                            height="20"
                            width="20"
                            color="#E04343"
                            secondaryColor="#FCBF94"
                            ariaLabel="oval-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                          />
                        </div>
                        
                      </button>
                    </div>

                  </div>





                </form>
              </div>

            </div>

          </div>
        </Modal>
      </section>


      <section>
        <Modal
          visible={riderModal}
          width="550"
          height="400"
          effect="fadeInUp"
          onClickAway={() => setRiderModal(false)}
        >
          <div className=" " style={{ height: '100%', overflow: 'auto' }}>

            <div className="container  bg-[#fff] rounded-lg p-2">

              <div className="px-3">

                {/* <span className="flex justify-around">
                    <h1 className=" text-xs text-red-600" style={{ fontSize: '10px' }}>Link can’t be edited in free plan. <span style={{ color: '#61A24F' }} className="font-bold text-xs">Upgrade to Pro</span></h1>
                  </span> */}
                <span className="flex justify-between px-2 pt-3">
                  <p className='mt-2'>Update Branch</p>
                  <p className="cursor-pointer font-bold" onClick={(e) => setRiderModal(false)}><SvgElement type={icontypesEnum.CANCEL} /></p>
                </span>


                <form onSubmit={updateRider}>
                  <div className="flex flex-col lg:flex-row lg:justify-between max-w-screen-xl mx-auto  mt-5">
                    <div className="relative flex flex-col min-w-0 break-words w-full  ">
                      <div className="flex-auto   py-10 pt-0">
                        <div className="flex flex-wrap ">

                          <div className="lg:w-12/12 w-full ">
                            <div className="flex justify-between mb-3">
                              <label className="text-[#333333] text-[12px]">Branch Name</label>
                              <SvgElement type={icontypesEnum.QUESTIONMARK} />
                            </div>
                            <div className="relative mb-4">
                              <input type="text" name="branch_name" defaultValue={shipmentId?.branch_name} onChange={handleChange2} className=" border border-[#D1D1D1] text-gray-900 text-sm rounded-[5px] block w-full pl-6 p-2.5" placeholder="John" />
                            </div>
                          </div>

                          {/* <div className="lg:w-6/12 w-full lg:pr-4">
                            <div className="flex justify-between mb-3">
                              <label className="text-[#333333] text-[12px]">Zone</label>
                              <SvgElement type={icontypesEnum.QUESTIONMARK} />
                            </div>
                            <div className="relative mb-4">

                              <input type="text" name="zone" defaultValue={shipmentId?.zone} onChange={handleChange2} className=" border border-[#D1D1D1] text-gray-900 text-sm rounded-[5px] block w-full pl-6 p-2.5" placeholder="Smith" />
                            </div>
                          </div> */}

                          <div className="w-full lg:w-6/12 lg:pr-4">
                            <div className="relative w-full mb-5">
                              <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                Zone
                              </label>
                              <select id="countries" name='zone' onChange={(e: any) => handleChange(e)} className="border border-[#D1D1D1] bg-white text-[#333333] text-sm rounded-[5px] block w-full p-2.5 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150">
                                <option disabled selected>{shipmentId?.zone}</option>
                                <option value="north-central">North Central</option>
                                <option value="north-east">North East</option>
                                <option value="north-west">North West</option>
                                <option value="south-east">South East</option>
                                <option value="south-south">South South</option>
                                <option value="south-west">South West</option>
                              </select>

                            </div>
                          </div>

                          <div className="w-full lg:w-6/12 ">
                            <div className="relative w-full mb-5">
                              <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                State
                              </label>
                              <select id="countries" name='state' onChange={(e: any) => handleChange(e)} className="border border-[#D1D1D1] bg-white text-[#333333] text-sm rounded-[5px] block w-full p-2.5 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150">
                                <option disabled selected>{shipmentId?.state}</option>
                                {stateFrom?.data?.map((datas: any, index: any) => (
                                  <option value={datas?.state_name}>{datas?.state_name}</option>
                                )
                                )}
                              </select>

                            </div>
                          </div>

                          {/* <div className="lg:w-6/12 w-full lg:pr-4">
                          <div className="flex justify-between mb-3">
                            <label className="text-[#333333] text-[12px] font-[400]">State</label>
                            <SvgElement type={icontypesEnum.QUESTIONMARK} />
                          </div>
                          <div className="relative mb-4">

                            <input type="text"  name="state" defaultValue={shipmentId?.state} onChange={handleChange2} className=" border border-[#D1D1D1] text-gray-900 text-sm rounded-[5px] block w-full pl-6 p-2.5" placeholder="john@gmail.com" />
                          </div>
                        </div> */}





                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-end pb-8">

                    {/* <div className="flex items-center mb-2 mt-4">
                    <input id="default-checkbox" type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-[#D1D1D1] rounded " />
                    <label htmlFor="default-checkbox" className="ml-3 text-sm font-medium text-[#333333]">I agree with <span className="text-[#E04343]">terms and conditins</span></label>
                  </div> */}

                    <div className="mt-1 ">
                      <button type="submit" disabled={loader} className="text-white bg-[#E04343] rounded-[30px] transition ease-out delay-300 py-[10px] px-[42px] font-[700] text-[14px]">

                      <div className="flex justify-center gap-3 ">
                          <span> Update </span>
                          <Oval
                            visible={loader}
                            height="20"
                            width="20"
                            color="#E04343"
                            secondaryColor="#FCBF94"
                            ariaLabel="oval-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                          />
                        </div>
                      </button>
                    </div>

                  </div>





                </form>
              </div>

            </div>

          </div>
        </Modal>
      </section>


      <section>
        <Modal
          visible={riderDeleteModal}
          width="350"
          height="250"
          effect="fadeInUp"
          onClickAway={() => setRiderDeleteModal(false)}
        >
          <div className=" " style={{ height: '100%', overflow: 'auto' }}>

            <div className="container  bg-[#fff] rounded-lg p-2">

              <div className="px-3">

                {/* <span className="flex justify-around">
                    <h1 className=" text-xs text-red-600" style={{ fontSize: '10px' }}>Link can’t be edited in free plan. <span style={{ color: '#61A24F' }} className="font-bold text-xs">Upgrade to Pro</span></h1>
                  </span> */}
                <span className="flex justify-between px-2 pt-3">
                  <p className='mt-2'>Delete Branch</p>
                  <p className="cursor-pointer font-bold" onClick={(e) => setRiderDeleteModal(false)}><SvgElement type={icontypesEnum.CANCEL} /></p>
                </span>


                <form onSubmit={deleteRider}>
                  <div className="flex flex-col lg:flex-row lg:justify-between max-w-screen-xl mx-auto  mt-5">
                    <div className="relative flex flex-col min-w-0 break-words w-full  ">
                      <div className="flex-auto   py-10 pt-0">
                        Are you sure you want to delete this Branch?
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-center pb-8">

                    <div className="mt-1 ">
                      <button type="submit" className="text-white bg-[#E04343] rounded-[30px] transition ease-out delay-300 py-[10px] px-[42px] font-[700] text-[14px]">

                      <div className="flex justify-center gap-3 ">
                          <span> Delete </span>
                          <Oval
                            visible={loader}
                            height="20"
                            width="20"
                            color="#E04343"
                            secondaryColor="#FCBF94"
                            ariaLabel="oval-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                          />
                        </div>
                      </button>
                    </div>

                  </div>





                </form>
              </div>

            </div>

          </div>
        </Modal>
      </section>

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
    </>
  )
}

export default ManageBranch