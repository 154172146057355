import React from 'react'
import Navbar from '../Navbars/Navbar';
import { NavLink } from "react-router-dom";
import Footer from '../Footer';

function Blog() {
  return (
   <>
    <Navbar />

    <div className='bg-[#FFFFFF] sm:px-16 px-6 md:flex md:justify-center items-start py-10' >
                <div className='xl:max-w-[1200px] w-full mx-auto'>
                    

                    <div className='grid grid-cols-1 lg:grid-cols-3 gap-6 mt-10'>


                        <div>
                            <NavLink to="/how-to-ship-goods-from-nigeria-to-usa">
                            <div className="flex justify-center relative overflow-hidden bg-cover bg-no-repeat">
                                <img src="/images/blog1.jpg" alt="hero" />
                                <div className='absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-black bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-70'></div>
                            </div>
                            <h3 className="text-[20px] font-[700] text-[#282828] mt-3">How To Send Goods To USA From Nigeria </h3>  
                            </NavLink>
                                  
                        </div>
                        <div>
                        <NavLink to="/how-to-export-foodstuffs-from-nigeria-to-uk">
                            <div className="flex justify-center relative overflow-hidden bg-cover bg-no-repeat">
                                <img src="/images/blog1.jpg" alt="hero" />
                                <div className='absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-black bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-70'></div>
                            </div>
                            <h3 className="text-[20px] font-[700] text-[#282828] mt-3">How To Export Foodstuffs From Nigeria to UK </h3>   
                            </NavLink>
                            
                        </div>
                        <div>
                        <NavLink to="/how-to-ship-goods-from-nigeria-to-usa">
                            <div className="flex justify-center relative overflow-hidden bg-cover bg-no-repeat">
                                <img src="/images/blog1.jpg" alt="hero" />
                                <div className='absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-black bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-70'></div>
                            </div>
                            <h3 className="text-[20px] font-[700] text-[#282828] mt-3">Shipping From China To Nigeria - Easy Guide </h3> 
                            </NavLink>       
                        </div>

                       

                    </div>
                </div>
            </div>
   </>
  )
}

export default Blog