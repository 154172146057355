import React from 'react'
import AdminNavbar from "../../Navbars/AdminNavbar";
import Sidebar from "../../Sidebar/Sidebar";
import { SuperAdminApis } from '../../../apis/superAdminApi';
import { useNavigate } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { convertUTCDateToLocalDate } from '../../Reusable/functions';
//@ts-ignore
import Modal from 'react-awesome-modal';
import { NavLink } from "react-router-dom";
import { SvgElement, icontypesEnum } from '../../assets/svgElement';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function DeleteCountryToModal(props: any) {



  const handleSubmit = React.useCallback(
    (e: any) => {
      e.preventDefault();
      SuperAdminApis.deleteCountryTo(props?.data?.id).then(
        (response: any) => {
          if (response?.data) {

            toast.success(response?.data?.message);
            props?.handleClose()
          } else {
            toast.error(response?.response?.data?.message);
          }
        }
      ).catch(function (error) {
        toast.error(error.response.data.message);

      })
    },
    [props]
  );


  return (
    <>

      <section>
        <Modal
          visible={props?.show}
          width="400"
          height="300"
          effect="fadeInUp"
          onClickAway={() => props?.handleClose}
        >
          <div className="p-5" style={{ overflow: 'auto' }}>
            <span className="flex justify-between px-3 pt-3 mb-3">
              <p className='font-bold'>Country To</p>
              <p className="cursor-pointer font-bold" onClick={props?.handleClose}><SvgElement type={icontypesEnum.CANCEL} /></p>

            </span>

            <form onSubmit={handleSubmit} >
              <div className='my-10 flex justify-center'>
                <span>Are you sure you want to delete {props?.data?.country_name} ?</span>
              </div>
              <div className="w-full flex justify-end">
                <button type='submit' className='bg-[#E04343] text-white py-[6px] rounded w-full'>Delete Country</button>
              </div>
            </form>




          </div>
        </Modal>
      </section>

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
    </>
  )
}

export default DeleteCountryToModal