import React from 'react'
import AdminNavbar from "../Navbars/AdminNavbar";
import Sidebar from "../Sidebar/Sidebar.jsx";
import { SuperAdminApis } from '../../apis/superAdminApi';
import { useNavigate } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { convertUTCDateToLocalDate } from '../Reusable/functions';
import AdminSidebar from '../Sidebar/AdminSidebar';
//@ts-ignore
import Modal from 'react-awesome-modal';
import { SvgElement, icontypesEnum } from '../assets/svgElement';
import { ToastContainer, toast } from 'react-toastify';

function ManageExpense() {
  let [invoiceModal, setInvoiceModal] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const [userLists, setUserList] = React.useState<any>([]);
  const [searchText, setSearchText] = React.useState('');
  const [from, setFrom] = React.useState('');
  const [to, setTo] = React.useState('');

  const [fromDate, setFromDate] = React.useState('');
  const [toDate, setToDate] = React.useState('');
  const [userEmail, setUserEmail] = React.useState('');


  React.useEffect(() => {
    const query: any = {
      search: searchText,
      from: from,
      to: to
    };
    SuperAdminApis.getAllExpense('', query).then(
      (response: AxiosResponse<any>) => {
        if (response?.data) {
          setUserList(response?.data?.data)
          console?.log(response?.data?.data)
        }
      }
    ).catch(function (error) {
      // handle error

    })
  }, []);



  const paginator = React.useCallback(
    (value: any) => {
      //   setLoader(true);
      let value2 = '';
      if (value !== null) {
        value2 = value;
      } else {
        value2 = ''
      }
      const query: any = {
        search: searchText,
        from: from,
        to: to
      };

      SuperAdminApis.getAllExpense(value2, query).then(
        (response: AxiosResponse<any>) => {
          if (response?.data) {
            setUserList(response?.data?.data)
            console?.log(response?.data?.data)
          }
        }
      ).catch(function (error) {
        console.log(error.response.data);
      })

    }, [userLists, searchText, from, to]);


  const generateInvoice = React.useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {

      e.preventDefault();
      const query: any = {
        date_from: fromDate,
        date_to: toDate,
        email: userEmail
      };

      SuperAdminApis.sendInvoice(query).then(
        (response: AxiosResponse<any>) => {
          if (response?.data?.status) {
            toast.success("Invoice Sent to Customer")
            setInvoiceModal(false)
            // console?.log(response?.data?.data)
          } else {
            toast.error("Invoice not valid for the date range")
            setInvoiceModal(false)
          }
        }
      ).catch(function (error) {
        // console.log(error.response.data);
      })

    }, [fromDate, toDate, userEmail, invoiceModal]);




  return (
    <>
      <AdminSidebar title="Expenses" />
      <div className="relative md:ml-64 bg-[#FAFAFA] ">
        <AdminNavbar title="Expenses" />
        <div className=' lg:px-10 pb-6 px-6 bg-[#FAFAFA] min-h-screen'>

          <div className='grid grid-cols-1 md:flex justify-between gap-3'>
            <div>
              <h3 className='text-[15px] text-[#1C1C1C] font-semibold'>Expense&nbsp;List</h3>

            </div>
            <span></span><span></span> <span></span><span></span> <span></span><span></span>

            {/* <div> */}


            {/* <button className='bg-[#E04343] text-white text-[14px] px-[12px] py-[8px] rounded-[10px]'>+ Add Customers</button> */}
            {/* <div className='flex justify-end '> */}
            {/* <div className=" lg:ml-auto mr-3 flex justify-end gap-3"> */}
            <div className=" flex justify-start w-full gap-2">
              <h2 className='text-sm '>From: &nbsp;</h2>
              <span className='w-full'>
                <input type="date" onClick={() => paginator('')} onChange={e => setFrom(e.target.value)} placeholder='' className=" border border-gray-300 text-gray-500 text-sm rounded-md  w-full pl-4 py-1 px-4  " required />
              </span>

            </div>

            <div className=" flex justify-start w-full">
              <span className='text-sm'>To: &nbsp;</span>
              <span className='w-full'>
                <input type="date" onClick={() => paginator('')} onChange={e => setTo(e.target.value)} placeholder='' className=" border border-gray-300 text-gray-500 text-sm rounded-md  w-full pl-4 px-4 py-1  " required />
              </span>

            </div>

            <div className="relative flex w-full ">
              {/* <div className="absolute inset-y-0 right-2 flex items-center pl-3 pointer-events-none">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="#9da4aa" d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0a5.5 5.5 0 0 1 11 0z" /></svg>
              </div> */}

              <span className='w-full'>
                <input type="text" onClick={() => paginator('')} onChange={e => setSearchText(e.target.value)} placeholder='search...' className=" border border-gray-300 text-gray-500 text-sm rounded-md  w-full pl-4 px-4 py-1  " required />
              </span>

            </div>

            <div className='flex justify-between'>
              <div className='mt-0.5 ml-2'><button type='button' onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => paginator('')} className={"font-normal text-white bg-[#E04343] px-3 py-0.5 rounded-md"}>Search</button> </div>
             
              
            </div>


          </div>

         


          <div className="relative overflow-x-auto">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 border-separate border-spacing-y-3">
              <thead className="text-[12px] text-[#808080] bg-[#FAFAFA] ">
                <tr>

                  <th scope="col" className="px-2 py-2">
                    S/N
                  </th>

                  <th scope="col" className="px-2 py-2">
                    Invoice ID
                  </th>

                  <th scope="col" className="px-2 py-2">
                    Expense Type
                  </th>
                  {/* <th scope="col" className="px-2 py-2">
                    Name
                  </th> */}
                  <th scope="col" className="px-2 py-2">
                    Email
                  </th>
                  <th scope="col" className="px-2 py-2">
                    Amount
                  </th>
                  {/* <th scope="col" className="px-2 py-2">
                    Customer Type
                  </th> */}
                  <th scope="col" className="px-2 py-2">
                    Date
                  </th>
                  <th scope="col" className="px-4">
                    Status
                  </th>
                  {/* <th scope="col" className="px-2 py-2">
                    Action
                  </th> */}

                </tr>
              </thead>

              <tbody className=''>

                {
                  userLists?.data?.length > 0 ?
                    userLists?.data?.map(
                      (datas: any, index: any) => (
                        <tr className="bg-white  ">

                          <td className="px-2 py-2">
                            {index + 1}
                          </td>

                          <td className="px-2 ">
                            {datas?.invoice_id}
                          </td>
                          <td className="px-2 ">
                            {datas?.shipment_id}
                          </td>
                          {/* <td className="px-6 py-4 "> 
                            {datas?.email}
                          </td> */}
                          <td className="px-2 ">
                            {datas?.user_email}
                          </td>
                          <td className="px-2  ">
                            {(new Intl.NumberFormat('en-US', { style: 'currency', currency: 'NGN' }).format((datas?.amount) ? (datas?.amount) : 0.0))}
                          </td>
                          {/* <td className="px-2 ">
                            {datas?.customer_type}
                          </td> */}
                          <td className="px-2  ">
                            {convertUTCDateToLocalDate(datas?.created_at)}
                          </td>
                          <td className="px-2 ">

                            {datas?.payment_status == 'successful' ? <p><b style={{ fontWeight: "500", fontSize: '14px', backgroundColor: '#C9F0D0', color: '#51CF66', borderRadius: '10px', padding: '2px 10px' }}>{datas?.payment_status}</b></p> : ''}
                            {datas?.payment_status == 'pending' ? <p><b style={{ fontWeight: "500", fontSize: '14px', backgroundColor: '#FFDDBD', color: '#FF922B', borderRadius: '10px', padding: '2px 10px' }}>{datas?.payment_status}</b></p> : ''}
                            {datas?.payment_status == 'failed' ? <p><b style={{ fontWeight: "500", fontSize: '14px', backgroundColor: '#E6BDFF', color: '#B24BF1', borderRadius: '10px', padding: '2px 10px' }}>{datas?.payment_status}</b></p> : ''}
                            {datas?.payment_status == 'Missed' ? <p><b style={{ fontWeight: "500", fontSize: '14px', backgroundColor: '#CAD8FA', color: '#4979ED', borderRadius: '10px', padding: '2px 10px' }}>{datas?.payment_status}</b></p> : ''}
                            {datas?.Spayment_statustatus == 'Closed' ? <p><b style={{ fontWeight: "500", fontSize: '14px', backgroundColor: '#CAD8FA', color: '#4979ED', borderRadius: '10px', padding: '2px 10px' }}>{datas?.payment_status}</b></p> : ''}

                          </td>



                          {/* <td className="px-6 py-4 cursor-pointer">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <rect width="24" height="24" rx="4" fill="#EBEBEB" />
                              <path d="M12 13.5C12.8284 13.5 13.5 12.8284 13.5 12C13.5 11.1716 12.8284 10.5 12 10.5C11.1716 10.5 10.5 11.1716 10.5 12C10.5 12.8284 11.1716 13.5 12 13.5Z" fill="#3F83F8" />
                              <path d="M15.2536 9.30891C14.2289 8.60625 13.1323 8.25 11.9944 8.25C10.9704 8.25 9.97219 8.55469 9.02742 9.15187C8.07469 9.75539 7.1407 10.8516 6.375 12C6.99422 13.0312 7.84125 14.0916 8.72344 14.6995C9.73547 15.3966 10.8359 15.75 11.9944 15.75C13.1428 15.75 14.2409 15.3968 15.2592 14.7002C16.1557 14.0859 17.0088 13.027 17.625 12C17.0067 10.9821 16.1508 9.92437 15.2536 9.30891ZM12 14.25C11.555 14.25 11.12 14.118 10.75 13.8708C10.38 13.6236 10.0916 13.2722 9.92127 12.861C9.75097 12.4499 9.70642 11.9975 9.79323 11.561C9.88005 11.1246 10.0943 10.7237 10.409 10.409C10.7237 10.0943 11.1246 9.88005 11.561 9.79323C11.9975 9.70642 12.4499 9.75097 12.861 9.92127C13.2722 10.0916 13.6236 10.38 13.8708 10.75C14.118 11.12 14.25 11.555 14.25 12C14.2493 12.5965 14.012 13.1684 13.5902 13.5902C13.1684 14.012 12.5965 14.2493 12 14.25Z" fill="#3F83F8" />
                            </svg>
                          </td> */}




                        </tr>
                      )
                    )
                    :
                    <div className='flex justify-start mt-4'>
                      <td >No Record Found</td>
                    </div>


                }

              </tbody>

            </table>
          </div>
          <div className='m-4 flex justify-end mt-20'>
            {
              userLists?.links?.filter(((item: any, idx: any) => idx < 1000)).map(
                (datas: any, index: any) => (
                  <button onClick={() => paginator(datas?.label == 'Next &raquo;' ? datas?.url?.charAt(datas?.url?.length - 1) : (datas?.label === '&laquo; Previous') ? datas?.url?.charAt(datas?.url?.length - 1) : datas?.label)} disabled={datas?.active} className={'mx-1 py-1 px-2 ' + ((datas?.active == false || datas?.url == null) ? 'bg-gray-300 text-black ' : 'bg-[#E04343] text-white')}>
                    {datas?.label == '&laquo; Previous' ? '< Previous' : (datas?.label === 'Next &raquo;') ? 'Next  >' : datas?.label}
                  </button>
                )
              )
            }

          </div>
        </div>
      </div>



      <section>
        <Modal
          visible={invoiceModal}
          width="550"
          height="500"
          effect="fadeInUp"
          onClickAway={() => setInvoiceModal(false)}
        >
          <div className=" " style={{ height: '100%', overflow: 'auto' }}>

            <div className="container  bg-[#fff] rounded-lg p-2">
              <span className="flex justify-between px-2 pt-1">
                <p className='mt-2 font-bold'>Generate Invoice</p>
                <p className="cursor-pointer font-bold" onClick={(e) => setInvoiceModal(false)}><SvgElement type={icontypesEnum.CANCEL} /></p>
              </span>

              <form onSubmit={generateInvoice}>
                <div className="flex flex-col lg:flex-row lg:justify-between max-w-screen-xl mx-auto  mt-5">
                  <div className="relative flex flex-col min-w-0 break-words w-full  ">
                    <div className="flex-auto   py-10 pt-0">
                      <div className="flex flex-wrap ">

                        <div className="lg:w-12/12 w-full lg:pr-4">
                          <div className="flex justify-between mb-3">
                            <label className="text-[#333333] text-[12px]">From</label>
                            <SvgElement type={icontypesEnum.QUESTIONMARK} />
                          </div>
                          <div className="relative mb-4">
                            <input type="date" onChange={e => setFromDate(e.target.value)} placeholder='' className=" border border-gray-300 text-gray-500 text-sm rounded-md block w-full pl-4 p-2  " required />
                          </div>
                        </div>

                        <div className="lg:w-12/12 w-full lg:pr-4">
                          <div className="flex justify-between mb-3">
                            <label className="text-[#333333] text-[12px]">To</label>
                            <SvgElement type={icontypesEnum.QUESTIONMARK} />
                          </div>
                          <div className="relative mb-4">
                            <input type="date" onChange={e => setToDate(e.target.value)} placeholder='' className=" border border-gray-300 text-gray-500 text-sm rounded-md block w-full pl-4 p-2  " required />
                          </div>
                        </div>

                        <div className="lg:w-12/12 w-full lg:pr-4">
                          <div className="flex justify-between mb-3">
                            <label className="text-[#333333] text-[12px] font-[400]">Email</label>
                            <SvgElement type={icontypesEnum.QUESTIONMARK} />
                          </div>
                          <div className="relative mb-4">
                            <input type="email" name="email" onChange={e => setUserEmail(e.target.value)} className=" border border-[#D1D1D1] text-gray-900 text-sm rounded-[5px] block w-full pl-6 p-2" placeholder="john@gmail.com" required />
                          </div>
                        </div>





                      </div>
                    </div>
                  </div>
                </div>



                {/* <div className="flex items-center mb-2 mt-4">
                    <input id="default-checkbox" type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-[#D1D1D1] rounded " />
                    <label htmlFor="default-checkbox" className="ml-3 text-sm font-medium text-[#333333]">I agree with <span className="text-[#E04343]">terms and conditins</span></label>
                  </div> */}

                {/* <div className="mt-1 "> */}
                <button type="submit" className="text-white bg-[#E04343] rounded-[30px] w-full transition ease-out delay-300 py-[10px] px-[42px] font-[700] text-[14px]">Generate Invoice</button>
                {/* </div> */}







              </form>



            </div>

          </div>
        </Modal>
      </section>

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
    </>
  )
}

export default ManageExpense