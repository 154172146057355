import React from 'react'
import Navbar from '../../Navbars/Navbar'
import Footer from '../../Footer'
import { NavLink } from 'react-router-dom'

function UkToNigeria() {
  return (
    <>
     <Navbar />
     <div className='bg-[#FFFFFF] sm:px-16 px-6 md:flex md:justify-center items-start py-10' >
                <div className='xl:max-w-[1200px] w-full mx-auto'>
                    {/* <!-- blog Header --> */}
                    <div>
                        <h1 className="text-[24px] font-[700] text-[#282828] mb-3">How To Export Foodstuffs From Nigeria to UK</h1>
                        <img src="/images/blog1.jpg" alt="hero" />
                    </div>

                    {/* <!-- blog body --> */}
                    <div>
                        <p className='mt-4 text-[#000000] text-[16px] md:leading-[25px]'>Why should you consider how to export foodstuffs from Nigeria to the UK? If you’ve ever had to ship from Nigeria to the United Kingdom, you know how tedious the process can be.</p>
                        <p className='mt-4 text-[#000000] text-[16px] md:leading-[25px]'>Increased foodstuff exports from Nigeria to the UK are beneficial to both countries. While agriculture in Nigeria, which has been subservient to oil for many years, is being restored, the UK is looking for new, reliable, and cost-effective sources of agricultural products to meet rising demand. As a result, the two countries should be able to collaborate in mutually beneficial ways regarding the export of Nigerian agricultural produce.</p>
                        <p className='mt-4 text-[#000000] text-[16px] md:leading-[25px]'>The devaluation of the Naira as a result of the drop in oil prices, combined with other economic variables, has created a favorable environment for exporters of foodstuffs in terms of profit. As an aspiring entrepreneur looking to enter the foodstuff exportation business, make sure you have a strong interest in the food industry and a strong passion for it, as this is a key factor that will determine your success.</p>
                        <p className='mt-4 text-[#000000] text-[16px] md:leading-[25px]'>The opportunities in this sector are enormous, just as they are in importation because the number of Nigerians who travel outside of the country is increasing daily.</p>
                    </div>

                    <div className='mt-6'>
                        <h1 className="text-[24px] font-[700] text-[#282828] mb-3">REQUIREMENTS FOR FOODSTUFF EXPORT TO THE UK</h1>
                        <p className='mt-3 text-[#000000] text-[16px] md:leading-[25px]'>We at Skynet Express have made it a priority to handle the stringent protocol of exporting foodstuff from Nigeria to the UK. This is done to alleviate stress for our customers when attempting to send food items.</p>
                    </div>

                    <div className='mt-5'>
                        <h1 className="text-[22px] font-[700] text-[#282828] mb-3">
                            1. Commercial Invoice
                        </h1>
                        <p className='mt-3 text-[#000000] text-[16px] md:leading-[25px]'>A commercial invoice is the document used to describe the entire export transaction from beginning to end, including the shipping terms, when the international sale is completed and goods are ready to ship out. It is one of the most important documents because it provides critical information and instructions to all parties involved.</p>
                    </div>

                    <div className='mt-5'>
                    <h1 className="text-[22px] font-[700] text-[#282828] mb-3">
                            2. Address & Contact Details of the Sender and Receiver
                        </h1>
                        <p className='mt-3 text-[#000000] text-[16px] md:leading-[25px]'>An accurate sender and receiver contact information (including zip code) to ensure that your package arrives on time and safely. </p>
                    </div>

                    <div className='mt-6'>
                        <h1 className="text-[24px] font-[700] text-[#282828] mb-3">PROHIBITED ITEMS</h1>
                    </div>

                    <div className='mt-5'>
                    <h1 className="text-[22px] font-[700] text-[#282828] mb-3">
                    1. Perishable Foods
                        </h1>
                        <p className='mt-3 text-[#000000] text-[16px] md:leading-[25px]'>Meat, poultry, fish, milk, eggs, and raw fruits and vegetables are examples of perishable foods. All cooked foods are also classified as perishable. Semi-perishable foods can be kept fresh for six months to a year if properly stored. Flour, grain products, dried fruits, and dry mixes are examples of these foods.</p>
                        <p className='mt-3 text-[#000000] text-[16px] md:leading-[25px]'>Certain countries have additional items that are either prohibited or subject to shipment restrictions. It is the responsibility of the shipper to comply with current government regulations or laws in each country. Shippers should also consult each country’s list of Export Prohibited Articles.</p>
                        <p className='mt-3 text-[#000000] text-[16px] md:leading-[25px]'>Skynet will not transport any goods that are prohibited by law or regulation of any federal, state, or local government in the origin or destination countries, or that may violate any applicable export, import, or other laws, or that may endanger the safety of our employees, agents, and subcontractors or the means of transportation, or that, in our opinion, soil, taint, or otherwise damage other goods or equipment.</p>
                    </div>

                    <div className='mt-6'>
                        <h1 className="text-[24px] font-[700] text-[#282828] mb-3">BENEFITS OF EXPORTING FOODSTUFF TO UK THROUGH SKYNET EXPRESS</h1>
                        <p className='mt-3 text-[#000000] text-[16px] md:leading-[25px]'>– Door-to-door delivery that is both timely and secure. The delivery time ranges between 3-6 working days.</p>
                        <p className='mt-3 text-[#000000] text-[16px] md:leading-[25px]'>– Competitive rates</p>
                        <p className='mt-3 text-[#000000] text-[16px] md:leading-[25px]'>– A modern tracking system that keeps you up to date on the status of your delivery. The item is entered into the site for tracking purposes. You will be given a tracking number to assist you in tracking the delivery process.</p>
                        <p className='mt-3 text-[#000000] text-[16px] md:leading-[25px]'>– Exceptional customer support</p>
                    </div>

                   

                    <div className='mt-4'>
                    <h1 className="text-[24px] font-[700] text-[#282828] mb-3">
                    CONCLUSION 
                        </h1>
                        <p className='mt-3 text-[#000000] text-[16px] md:leading-[25px]'>Our services at SkyNet Worldwide Express are guided by our complete adherence and conformity to the laws governing import and export. This has allowed us to successfully export thousands of foodstuff from Nigeria to UK.</p>
                        <p className='mt-3 text-[#000000] text-[16px] md:leading-[25px]'>Do you have any foodstuffs you’d like to send to the UK through us? Sign up by <NavLink to="/register" className={"text-blue-500"}>clicking here..</NavLink></p>
                    </div>
                </div>
            </div>

            <Footer/>
    </>
  )
}

export default UkToNigeria