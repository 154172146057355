import React from 'react'
import AdminNavbar from "../Navbars/AdminNavbar";
import Sidebar from "../Sidebar/Sidebar";
import { SuperAdminApis } from '../../apis/superAdminApi';
import { useNavigate } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector, useDispatch } from 'react-redux';

import { convertUTCDateToLocalDate } from '../Reusable/functions';
//@ts-ignore
import Modal from 'react-awesome-modal';
import { SvgElement, icontypesEnum } from '../assets/svgElement';
import { AuthApis } from '../../apis/authApis';
import { error } from 'console';
import AdminSidebar from '../Sidebar/AdminSidebar';
import { STATUSTYPES } from '../Reusable/Status';
import { Oval } from 'react-loader-spinner';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { MdDownload } from "react-icons/md";
import * as XLSX from 'xlsx';

interface ShipmentData {
  created_at: string;
  fleet_name: string;
  manifest_id: string;
  destination_state: string;
  manifest_name: string;
  shipments: any[];
}

interface ShipmentExport {
  shipmentNumber: string;
  senderName: string;
  receiverName: string;
  senderAdd1: string;
  receiverAdd1: string;
  weight: number;
  goodsDescription: string;
  status: string;
  deliveryInstructions: string;
  freightCost: number;
  shipments: any[];
}

const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 10,
  },
  section: {
    marginBottom: 10,

  },
  header: {
    fontSize: 14,
    marginBottom: 10,
    textAlign: 'center',
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  table: {
    display: 'flex',
    paddingTop: 40,
    flexDirection: 'column',
    width: '100%',
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1px solid #000',
    paddingBottom: 4,
    marginBottom: 4,
  },
  tableCol: {
    flex: 1,
    padding: 4,
  },
  tableColHeader: {
    flex: 1,
    padding: 4,
    fontWeight: 'bold',
  },
  boldText: {
    fontWeight: 'bold',
  },
  footer: {
    marginTop: 20,
    // borderTop: '1px solid #000',
    paddingTop: '50px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  footer2: {
    marginTop: 20,
    // borderTop: '1px solid #000',
    paddingTop: '10px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  footerText: {
    fontSize: 10
  },
  logo: {
    width: 100,
    height: 'auto',
    marginBottom: 20,
  }
});



const exportManifestData = (manifestData: any) => {
  // Prepare the container for the export data
  // let exportData:any = [];

  const exportData = manifestData.flatMap((manifest: any) => {
    // Map manifest data to a flat structure, with each shipment as a new row
    return manifest.shipments.map((shipment: any, index: any) => ({
      manifestId: manifest.manifest_id,
      manifestName: manifest.manifest_name,
      createdBy: manifest.created_by,
      eventLocation: manifest.event_location,
      shipmentStatus: manifest.shipment_status,
      fleetName: manifest.fleet_name,
      vehicleName: manifest.vehicle_name,
      created_at: manifest.created_at,
      updated_at: manifest.updated_at,
      riderName: manifest.rider_name,
      shipmentNumber: shipment.ShipmentNumber,
      senderName: shipment.SenderName,
      receiverName: shipment.ReceiverName,
      senderAdd1: shipment.SenderAdd1,
      'Reciever Address': shipment.ReceiverAdd1,
      weight: shipment.Weight,
      goodsDescription: shipment.GoodsDescription,
      status: shipment.Status,
      deliveryInstructions: shipment.DeliveryInstructions,
      freightCost: shipment.FreightCost,
    }));
  });

  // Export data as needed (e.g., PDF, CSV, or JSON)
  // Example: export as JSON

  // Create a new workbook
  const workbook = XLSX.utils.book_new();

  // Convert the JSON data to a sheet
  const worksheet = XLSX.utils.json_to_sheet(exportData);

  // Append the worksheet to the workbook
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Manifests');

  // Export the workbook to an Excel file
  XLSX.writeFile(workbook, 'runway_manifest.xlsx');
  // console.log(JSON.stringify(exportData, null, 2));
};







const ShipmentManifest = ({ data }: { data: ShipmentData }) => (
  <Document>
    <Page style={styles.page}>
      <View>
        <Image
          style={styles.logo}
          src="/images/Auth/logo.png"
        />
      </View>
      <Text style={styles.header}>Runway Manifest</Text>

      <View style={styles.section}>
        <Text style={{ paddingBottom: 8 }}><Text style={{ fontWeight: 'bold' }}>Date</Text> : {convertUTCDateToLocalDate(data.created_at)}</Text>
        <Text style={{ paddingBottom: 8 }}><Text style={{ fontWeight: 'bold' }}>Fleet</Text> : {data.fleet_name}</Text>
        <Text style={{ paddingBottom: 8 }}> <Text style={{ fontWeight: 'bold' }}>Manifest Name</Text> : {data.manifest_name}</Text>
        <Text style={{ paddingBottom: 8 }}> <Text style={{ fontWeight: 'bold' }}>Manifest Id</Text> : {data.manifest_id}</Text>

      </View>

      <View style={styles.table}>

        <View style={styles.tableRow}>
          <View style={styles.tableColHeader}>
            <Text>Shipment Id #</Text>
          </View>
          <View style={styles.tableCol}>
            <Text>Shipper</Text>
          </View>
          <View style={styles.tableCol}>
            <Text>Receiver</Text>
          </View>
          <View style={styles.tableCol}>
            <Text>Goods Desc</Text>
          </View>
          <View style={styles.tableCol}>
            <Text>Weight</Text>
          </View>
          <View style={styles.tableCol}>
            <Text>No of Pcs.</Text>
          </View>
          <View style={styles.tableCol}>
            <Text>Address </Text>
          </View>
          <View style={styles.tableCol}>
              <Text>Custom Value</Text>
            </View>
        </View>
        {data.shipments.map((shipment, index) => (
          <View key={index} style={styles.tableRow}>
            <View style={styles.tableColHeader}>
              <Text style={styles.boldText}>{shipment.ShipmentNumber}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text>{shipment.SenderName},{shipment.SenderAddState},{shipment.SenderCountryCode} </Text>
            </View>
            <View style={styles.tableCol}>
              <Text>{shipment.ReceiverName},{shipment.ReceiverAddState},{shipment.ReceiverCountryCode} </Text>
            </View>
            <View style={styles.tableCol}>
              <Text>{shipment.GoodsDescription}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text>{shipment.Weight} {shipment.WeightMeasurement} </Text>
            </View>
            <View style={styles.tableCol}>
              <Text>{shipment.NoOfItems} </Text>
            </View>
            <View style={styles.tableCol}>
              <Text>{shipment.ReceiverAdd1} </Text>
            </View>
            <View style={styles.tableCol}>
              <Text>{shipment.CustomValue} </Text>
            </View>
          </View>
        ))}
      </View>

      <View style={styles.footer2}>
        <Text style={styles.footerText}>Total Shipments: {data.shipments.length} </Text><br />
      </View>

      <View style={styles.footer}>
        {/* <Text style={styles.footerText}>Summary for Docket 01 Bag #: {data.shipments.length} connotes</Text><br/> */}
        <Text style={styles.footerText}>Driver's Signature: ______________________</Text>
        <Text style={styles.footerText}>Collected date/time: ______________________</Text>
      </View>
    </Page>
  </Document>
);


function RunwayManifest() {

  const navigate = useNavigate();
  const [userLists, setUserList] = React.useState<any>([]);
  const [stateFrom, setStateFrom] = React.useState<any>([]);
  let [statusModal, setStatusModal] = React.useState<boolean>(false);

  // let [loading, setLoading] = React.useState<boolean>(false);


  function toggleStatus() {
    setStatusModal(true)
  }

  let [shipmentId, setShipmentId] = React.useState<any>([]);
  let [shipmentStatus, setShipmentStatus] = React.useState<any>('');

  const userLoginData = useSelector((state: any) => state.data.login.value);
  const [manifestData, setManifestData] = React.useState<any>([]);
  const [nextPageId, setNextPageId] = React.useState<any>([]);

  const [searchText, setSearchText] = React.useState('');
  const [searchText2, setSearchText2] = React.useState('');

  let [riderModal, setRiderModal] = React.useState<boolean>(false);
  let [shipmentRider, setShipmentRider] = React.useState<any>('');
  let [riders, setRiders] = React.useState<any>([]);
  let [vehicle, setVehicle] = React.useState<any>([]);
  const [shipmentInfo, setShipmentInfo] = React.useState<any>([]);
  const [runmanifestListData, setrunmanifestListData] = React.useState<any>([]);
  let [shipmentStatusId, setShipmentStatusId] = React.useState<any>('');
  let [delieveryInstruction, setDelieveryInstruction] = React.useState<any>([]);
  let [delieveryCode, setDelieveryCode] = React.useState<any>('');

  const [shipid, setShipId] = React.useState<any>({
    shipids: [],
  });

  let [addModal, setAddModal] = React.useState<boolean>(false);
  const [tabNo, setTabNo] = React.useState<any>('1');
  const [fleetData, setFleetData] = React.useState<any>([]);
  const [fleetData2, setFleetData2] = React.useState<any>([]);

  const handleChange2 = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFleetData2({ ...fleetData2, [name]: value });

    // if (name == 'shipment_status') {
    //   setFleetData2({ ...fleetData2, [name]: value });
    //   STATUSTYPES?.filter((data: any) => data?.EventName == value)?.map((datas: any, index: any) => {
    //     setShipmentStatusId(datas?.EventCode)
    //     setFleetData2({ ...fleetData2, 'status_id': datas?.EventCode, shipment_status: value });
    //   }
    //   )
    // }
  };


  const [loading, setLoading] = React.useState<boolean>(false);


  const [userData, setUserdata] = React.useState<any>({
    'manifest_name': "",
    'manifest_id': " ",
    'created_by': "",
    'sender_state': "",
    'destination_state': "",
    'shipment_status': "",
    'fleet_name': "null",
    'vehicle_name': "null",
    'goods_description': "null",
    'pcs': "null",
    'weight': "null",
    'custom_value': "null",
    // 'checked': false
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target;
    setUserdata({ ...userData, [name]: value });
  };



  const updateShipment = React.useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let eventCode = '';
    setLoading(true);

    STATUSTYPES?.filter((data: any) => data?.EventName == shipmentStatus)?.map((datas: any, index: any) => {
      setShipmentStatusId(datas?.EventCode)
      eventCode = datas?.EventCode
    }
    )

    let shiData = shipmentId;

    let shipment = [
      {
        "ThirdPartyToken": "null",
        "SenderDetails": {
          "SenderName": shiData?.SenderName ? shiData?.SenderName : 'null',
          "SenderCompanyName": shiData?.SenderCompanyName ? shiData?.SenderCompanyName : "null",
          "SenderCountryCode": shiData?.SenderCountryCode ? shiData?.SenderCountryCode : "null",
          "SenderAdd1": shiData?.SenderAdd1 ? shiData?.SenderAdd1 : "null",
          "SenderAdd2": shiData?.SenderAdd2 ? shiData?.SenderAdd2 : 'null',
          "SenderAdd3": shiData?.SenderAdd3 ? shiData?.SenderAdd3 : 'null',
          "SenderAddCity": shiData?.SenderAddCity ? shiData?.SenderAddCity : 'null',
          "SenderAddState": shiData?.SenderAddState ? shiData?.SenderAddState : 'null',
          "SenderAddPostcode": shiData?.SenderAddPostcode ? shiData?.SenderAddPostcode : 'null',
          "SenderPhone": shiData?.SenderPhone ? shiData?.SenderPhone : 'null',
          "SenderEmail": shiData?.SenderEmail ? shiData?.SenderEmail : 'null',
          "SenderFax": shiData?.SenderFax ? shiData?.SenderFax : 'null',
          "SenderKycType": shiData?.SenderKycType ? shiData?.SenderKycType : 'null',
          "SenderKycNumber": shiData?.SenderKycNumber ? shiData?.SenderKycNumber : 'null',
          "SenderReceivingCountryTaxID": shiData?.SenderReceivingCountryTaxID ? shiData?.SenderReceivingCountryTaxID : 'null'
        },
        "ReceiverDetails": {
          "ReceiverName": shiData?.ReceiverName ? shiData?.ReceiverName : 'null',
          "ReceiverCompanyName": shiData?.ReceiverCompanyName ? shiData?.ReceiverCompanyName : 'null',
          "ReceiverCountryCode": shiData?.ReceiverCountryCode ? shiData?.ReceiverCountryCode : 'null',
          "ReceiverAdd1": shiData?.ReceiverAdd1 ? shiData?.ReceiverAdd1 : 'null',
          "ReceiverAdd2": shiData?.ReceiverAdd2 ? shiData?.ReceiverAdd2 : 'null',
          "ReceiverAdd3": shiData?.ReceiverAdd3 ? shiData?.ReceiverAdd3 : 'null',
          "ReceiverAddCity": shiData?.ReceiverAddCity ? shiData?.ReceiverAddCity : 'null',
          "ReceiverAddState": shiData?.ReceiverAddState ? shiData?.ReceiverAddState : 'null',
          "ReceiverAddPostcode": shiData?.ReceiverAddPostcode ? shiData?.ReceiverAddPostcode : 'null',
          "ReceiverMobile": shiData?.ReceiverMobile ? shiData?.ReceiverMobile : 'null',
          "ReceiverPhone": shiData?.ReceiverPhone ? shiData?.ReceiverPhone : 'null',
          "ReceiverEmail": shiData?.ReceiverEmail ? shiData?.ReceiverEmail : 'null',
          "ReceiverAddResidential": shiData?.ReceiverAddResidential ? shiData?.ReceiverAddResidential : 'null',
          "ReceiverFax": shiData?.ReceiverFax ? shiData?.ReceiverFax : 'null',
          "ReceiverKycType": shiData?.ReceiverKycType ? shiData?.ReceiverKycType : 'null',
          "ReceiverKycNumber": shiData?.ReceiverKycNumber ? shiData?.ReceiverKycNumber : 'null'
        },
        "PackageDetails": {
          "GoodsDescription": shiData?.GoodsDescription ? shiData?.GoodsDescription : 'null',
          "CustomValue": shiData?.CustomValue ? shiData?.CustomValue : 0,
          "CustomCurrencyCode": shiData?.CustomCurrencyCode ? shiData?.CustomCurrencyCode : 'null',
          "InsuranceValue": shiData?.InsuranceValue ? shiData?.InsuranceValue : 0,
          "InsuranceCurrencyCode": shiData?.InsuranceCurrencyCode ? shiData?.InsuranceCurrencyCode : 'null',
          "ShipmentTerm": shiData?.ShipmentTerm ? shiData?.ShipmentTerm : 'null',
          "GoodsOriginCountryCode": shiData?.GoodsOriginCountryCode ? shiData?.GoodsOriginCountryCode : 'null',
          "DeliveryInstructions": delieveryInstruction ? delieveryInstruction : 'null',
          "Weight": shiData?.Weight ? shiData?.Weight : 0,
          "WeightMeasurement": shiData?.WeightMeasurement ? shiData?.WeightMeasurement : 'null',
          "NoOfItems": shiData?.NoOfItems ? shiData?.NoOfItems : 0,
          "CubicL": shiData?.CubicL ? shiData?.CubicL : 0,
          "CubicW": shiData?.CubicW ? shiData?.CubicW : 0,
          "CubicH": shiData?.CubicH ? shiData?.CubicH : 0,
          "CubicWeight": shiData?.CubicWeight ? shiData?.CubicWeight : 0,
          "ServiceTypeName": shiData?.ServiceTypeName ? shiData?.ServiceTypeName : 'null',
          "BookPickUP": false,
          "AlternateRef": shiData?.AlternateRef ? shiData?.AlternateRef : 'null',
          "SenderRef1": shiData?.SenderRef1 ? shiData?.SenderRef1 : 'null',
          "SenderRef2": shiData?.SenderRef2 ? shiData?.SenderRef2 : 'null',
          "SenderRef3": shiData?.SenderRef3 ? shiData?.SenderRef3 : 'null',
          "DeliveryAgentCode": shiData?.DeliveryAgentCode ? shiData?.DeliveryAgentCode : 'null',
          "DeliveryRouteCode": shiData?.DeliveryRouteCode ? shiData?.DeliveryRouteCode : 'null',
          "BusinessType": shiData?.BusinessType ? shiData?.BusinessType : 'B2B',
          "ShipmentResponseItem": [
            {
              "ItemAlt": "null",
              "ItemNoOfPcs": shiData?.NoOfItems ? shiData?.NoOfItems : 'null',
              "ItemCubicL": shiData?.CubicL ? shiData?.CubicL : 'null',
              "ItemCubicW": shiData?.CubicW ? shiData?.CubicW : 'null',
              "ItemCubicH": shiData?.CubicH ? shiData?.CubicH : 'null',
              "ItemWeight": shiData?.Weight ? shiData?.Weight : 'null',
              "ItemCubicWeight": shiData?.CubicWeight ? shiData?.CubicWeight : 'null',
              "ItemDescription": shiData?.GoodsDescription ? shiData?.GoodsDescription : 'null',
              "ItemCustomValue": shiData?.CustomValue ? shiData?.CustomValue : 'null',
              "ItemCustomCurrencyCode": shiData?.CustomCurrencyCode ? shiData?.CustomCurrencyCode : 'null',
              "Notes": shiData?.Notes ? shiData?.Notes : 'null'
            }
          ],
          "CODAmount": shiData?.CODAmount ? shiData?.CODAmount : 0,
          "CODCurrencyCode": shiData?.CODCurrencyCode ? shiData?.CODCurrencyCode : 'null',
          "Bag": shiData?.Bag ? shiData?.Bag : 0,
          "Notes": shiData?.Notes ? shiData?.Notes : 'null',
          "OriginLocCode": shiData?.OriginLocCode ? shiData?.OriginLocCode : 'null',
          "BagNumber": shiData?.BagNumber ? shiData?.BagNumber : 'null',
          "DeadWeight": shiData?.DeadWeight ? shiData?.DeadWeight : 0,
          "ReasonExport": shiData?.ReasonExport ? shiData?.ReasonExport : 'null',
          "DestTaxes": shiData?.DestTaxes ? shiData?.DestTaxes : 0,
          "Security": shiData?.Security ? shiData?.Security : 0,
          "Surcharge": shiData?.Surcharge ? shiData?.Surcharge : 0,
          "ReceiverTaxID": shiData?.ReceiverTaxID ? shiData?.ReceiverTaxID : 'null',
          "OrderNumber": shiData?.OrderNumber ? shiData?.OrderNumber : 0,
          "Incoterms": shiData?.Incoterms ? shiData?.Incoterms : 'null',
          "ClearanceReference": shiData?.ClearanceReference ? shiData?.ClearanceReference : 'null'
        }

      }
    ];


    let data = {
      'id': shipmentId?.id,
      "Status": (shipmentStatus !== '' ? shipmentStatus : shipmentId?.status),
      "status_id": eventCode,
      "del_instruction": delieveryInstruction,
      'shiData': shipment,
      'delievery_code': delieveryCode
    }

    SuperAdminApis.updateShipmentStatus(data)
      .then((response: any) => {
        if (response?.data) {
          if (response?.data?.status) {
            toast.success(response?.data?.message);
            setStatusModal(false)
            setLoading(false);
            setShipmentStatus('')
            const query: any = {
              search: '',
            };


            SuperAdminApis.getSingleRunManifest(nextPageId, query).then(
              (response: AxiosResponse<any>) => {
                if (response?.data) {
                  setManifestData(response?.data?.data)

                }
              }
            );

          }else{
            toast.warn(response?.data?.message);
            setStatusModal(false)
            setLoading(false);
            setShipmentStatus('')
          }
          // console?.log(response?.data)
        }
      }).catch((err: any) => {
        console.log(err);
        setLoading(false);
      })

  }, [statusModal, shipmentInfo,delieveryCode, shipmentId, shipmentStatus, manifestData, delieveryInstruction, loading]);


  const handleSubmit = React.useCallback(
    (e: React.ChangeEvent<HTMLFormElement>) => {
      e.preventDefault();

      let eventCode = '';
      setLoading(true)

      STATUSTYPES?.filter((data: any) => data?.EventName == userData?.shipment_status)?.map((datas: any, index: any) => {
        setShipmentStatusId(datas?.EventCode)
        eventCode = datas?.EventCode
      }
      )
      const formData = new FormData()
      formData.append('manifest_name', userData?.manifest_name)
      formData.append('manifest_id', Math.floor(Math.random() * Date.now()).toString(32))
      formData.append('created_by', userLoginData?.data?.email)
      formData.append('event_location', userLoginData?.data?.office)
      formData.append('shipment_status', userData?.shipment_status)
      formData.append('status_id', eventCode)
      formData.append('fleet_name', shipmentRider?.target?.value)
      formData.append('shipment_ids', shipid?.shipids?.toString())
      formData.append('vehicle_name', userData?.vehicle_name)
      formData.append('notes', userData?.goods_description)
      formData.append('pcs', shipid?.shipids?.length)


      SuperAdminApis.createRunManifest(formData).then(
        (response: AxiosResponse<any>) => {
          if (response?.data) {
            if (response?.data?.status === true) {
              toast.success(response?.data?.message);
            }

          } else {
            console?.log(response)
            toast.warn("Something went wrong,Try Again");
          }
          setLoading(false)
          setAddModal(false)
          const query: any = {
            search: searchText,
          };
          SuperAdminApis.getAllRunManifest('', query).then(
            (response: AxiosResponse<any>) => {
              if (response?.data) {
                setUserList(response?.data?.data)
              }
            }
          )

          setUserdata([])

          // toast.success(response?.data?.message);
        }
      ).catch(function (error) {
        // handle error
        setLoading(false)
        console.log(error.response.data);
        toast.warn(error.response?.data?.message);
        toast.error("Offfline");
      }).finally(() => {
        setLoading(false)
      });
    },
    [userData, userLists, shipid, shipmentRider]
  );

  const updateManifest = React.useCallback(
    (e: React.ChangeEvent<HTMLFormElement>) => {
      e.preventDefault();
      setLoading(true)
      const formData = fleetData2
      SuperAdminApis.updateRunManifest(formData).then(
        (response: AxiosResponse<any>) => {
          if (response?.data) {
            if (response?.data?.status === true) {
              toast.success(response?.data?.message);
            }

          } else {

            toast.warn("Something went wrong,Try Again");
          }
          setLoading(false)
          setRiderModal(false)
          const query: any = {
            search: searchText,
          };
          SuperAdminApis.getAllRunManifest('', query).then(
            (response: AxiosResponse<any>) => {
              if (response?.data) {
                setUserList(response?.data?.data)
              }
            }
          )

          setUserdata([])

          // toast.success(response?.data?.message);
        }
      ).catch(function (error) {
        // handle error
        setLoading(false)
        toast.warn(error.response?.data?.message);
        toast.error("Offfline");
      }).finally(() => {
        setLoading(false)
      });
    },
    [userLists, fleetData2, riderModal]
  );



  React.useEffect(() => {
    const query: any = {
      search: searchText,
    };
    SuperAdminApis.getAllRiders('', query).then(
      (response: AxiosResponse<any>) => {
        if (response?.data) {
          setRiders(response?.data?.data?.data)
        }
      }
    ).catch(function (error) {
      // handle error

    })

    SuperAdminApis.getAllRunManifest('', query).then(
      (response: AxiosResponse<any>) => {
        if (response?.data) {
          setUserList(response?.data?.data)
          console?.log(response?.data?.data?.data)
          // setRiders(response?.data?.data?.data)
        }
      }
    ).catch(function (error) {
      // handle error

    })


    let data = {
      "country_id": 2
    }
    SuperAdminApis.getAllStateFrom('', data).then(
      (response: AxiosResponse<any>) => {
        if (response?.data) {
          setStateFrom(response?.data?.data)
        }
      }
    )

    SuperAdminApis.getAllShipments('', '').then(
      (response: any) => {
        if (response?.data) {
          setShipmentInfo(response?.data?.in_bound?.data);
          setrunmanifestListData(response?.data?.run_manifest)
          console?.log(response?.data?.in_bound?.data)
        }
      }
    )
  }, []);

  const selectShipment = (e: any) => {
    // Destructuring
    const { value, checked } = e.target;
    const { shipids } = shipid;



    // Case 1 : The user checks the box
    if (checked) {
      setShipId({
        shipids: [...shipids, value],
      });
    }

    // Case 2  : The user unchecks the box
    else {
      setShipId({
        shipids: shipids.filter((e: any) => e !== value)
      });
    }
  };


  //Vehicles
  const nextPage = React.useCallback((id: any) => {
    const query: any = {
      search: '',
    };
    setNextPageId(id)
    SuperAdminApis.getSingleRunManifest(id, query).then(
      (response: AxiosResponse<any>) => {
        if (response?.data) {
          setManifestData(response?.data?.data)
          console?.log(response?.data?.data)
          setTabNo(2)
        }
      }
    ).catch(function (error) {
      // handle error

    })
  }, [manifestData, tabNo, nextPageId]);

  const getAllVehicles = React.useCallback((id: any) => {
    const query: any = {
      search: searchText,
    };
    SuperAdminApis.getAllVehicle('', query, id).then(
      (response: AxiosResponse<any>) => {
        if (response?.data) {
          setVehicle(response?.data?.data?.data)

        }
      }
    ).catch(function (error) {
      // handle error

    })
  }, [vehicle]);




  const paginator = React.useCallback(
    (value: any) => {
      //   setLoader(true);
      let value2 = '';
      if (value !== null) {
        value2 = value;
      } else {
        value2 = ''
      }
      const query: any = {
        search: searchText,
      };

      SuperAdminApis.getAllRunManifest(value2, query).then(
        (response: AxiosResponse<any>) => {
          if (response?.data) {
            setUserList(response?.data?.data)
          }
        }
      ).catch(function (error) {
        console.log(error.response.data);
      })

    }, [userLists, searchText]);


  const paginator2 = React.useCallback(
    () => {
      const query: any = {
        search: searchText2,
      };
      console?.log(searchText2)

      SuperAdminApis.getSingleRunManifest(nextPageId, query).then(
        (response: AxiosResponse<any>) => {
          if (response?.data) {
            setManifestData(response?.data?.data)
            console?.log(response?.data?.data)
            setTabNo(2)
          }
        }
      );

    }, [manifestData, searchText2, nextPageId]);

  const [shipType, setShipType] = React.useState<any>('event_location');


  type StateMapping = {
    [key: string]: string[];
  };

  const stateMappings: StateMapping = {
    "Ibadan": ["Kwara", "Ondo", "Ekiti", "Ogun", "Osun", "Oyo"],
    "Port Harcourt": ["Edo", "Cross River", "Akwa Ibom", "Delta", "Bayelsa"],
    "Enugu": ["Abia", "Anambra", "Imo", "Delta Asaba", "Ebonyi"],
    "Abuja": [
      "Kano", "Niger", "Kaduna", "Adamawa", "Kebbi", "Gombe", "Borno",
      "Bauchi", "Sokoto", "Zamfara", "Benue", "Jigawa", "Katsina",
      "Plateau", "Nassarawa", "Kogi", "Taraba", "Yobe"
    ]
  };


  const capitalize = (str: string): string =>
    str.replace(/\b\w/g, char => char.toUpperCase());

  const isSubStateUnderMainState = (mainState: string, subState: string): boolean => {

    if (mainState && subState) {
      const capitalizedMainState = capitalize(mainState.trim());
      const capitalizedSubState = capitalize(subState.trim());

      if (stateMappings[capitalizedMainState]) {
        return stateMappings[capitalizedMainState].includes(capitalizedSubState);
      }
    }
    return false;
  };


  return (
    <>
      <AdminSidebar title="Run Way Manifests" />
      <div className="relative md:ml-64 bg-[#FAFAFA] ">
        <AdminNavbar title="Run Way Manifest" />

        {tabNo == "1" ?
          <div className=' lg:px-10 pb-6 px-6 bg-[#FAFAFA] min-h-screen'>
            <div className='flex justify-between my-5'>
              <div>
                <h3 className='text-[15px] text-[#1C1C1C] font-semibold'>Runway Manifest List</h3>

              </div>
              <div>
                {/* <button className='bg-[#E04343] text-white text-[14px] px-[12px] py-[8px] rounded-[10px]'>+ Add Customers</button> */}
                <div className='flex justify-end'>
                  <div className="relative flex w-full flex-wrap items-stretch">
                    <div className="absolute inset-y-0 right-2 flex items-center pl-3 pointer-events-none">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="#9da4aa" d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0a5.5 5.5 0 0 1 11 0z" /></svg>
                    </div>
                    <input type="text" onClick={() => paginator('')} onChange={e => setSearchText(e.target.value)} placeholder='search...' id="simple-search" className=" border border-gray-300 text-gray-500 text-sm rounded-md block w-full pl-4 p-1  " required />
                  </div>

                  <div className='mt-0.5 ml-2'><button type='button' onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => paginator('')} className={"font-normal text-white bg-[#E04343] px-3 py-0.5 rounded-md"}>Search</button> </div>
                  <div className='mt-0.5 ml-2'><button type='button' onClick={() => exportManifestData(userLists?.data)} className={"font-normal text-white bg-[#E04343] px-3 py-0.5 rounded-md"}>Export&nbsp;Report</button> </div>

                </div>

                <div className='flex justify-end mt-4'>
                  <div className='mt-0.5 ml-2'><button type='button' onClick={() => setAddModal(true)} className={"font-normal text-white bg-[#E04343] px-3 py-0.5 rounded-md"}>Create Run Manifest</button> </div>
                </div>


              </div>
            </div>


            <div className="relative overflow-x-auto">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 border-separate border-spacing-y-3">
                <thead className="text-[12px] text-[#030229] bg-[#FAFAFA] ">
                  <tr>

                    <th scope="col" className="px-2 py-2">
                      S/N
                    </th>

                    <th scope="col" className="px-2 py-2">
                      Manifest&nbsp;Name
                    </th>
                    <th scope="col" className="px-2 py-2">
                      Manifest &nbsp;Id
                    </th>
                    <th scope="col" className="px-2 py-2">
                      Created By
                    </th>
                    <th scope="col" className="px-2 py-2">
                      Event Location
                    </th>

                    <th scope="col" className="px-2 py-2">
                      Shipment&nbsp;Status
                    </th>

                    <th scope="col" className="px-2 py-4">
                      Fleet
                    </th>

                    <th scope="col" className="px-2 py-4">
                      Notes
                    </th>

                    <th scope="col" className="px-2 py-4">
                      Pcs
                    </th>



                    <th scope="col" className="px-8 py-4">
                      Action
                    </th>


                  </tr>
                </thead>


                <tbody className=''>
                  {
                    (userLoginData?.data?.role == 'admin' || userLoginData?.data?.role == 'fleet' || userLoginData?.data?.role == 'driver' ?
                      (userLists?.data?.length > 0 ?
                        userLists?.data?.map(
                          (datas: any, index: any) => (
                            <tr className="bg-white  ">

                              <td className="px-2 py-2">
                                {index + 1}
                              </td>

                              <td className="px-2 py-4">
                                {datas?.manifest_name}
                              </td>

                              <td className="px-2 py-4">
                                {datas?.manifest_id}
                              </td>

                              <td className="px-2 py-4">
                                {datas?.created_by}
                              </td>

                              <td className="px-2 py-4">
                                {datas?.event_location}
                              </td>

                              <td className="px-2 py-4">
                                {datas?.shipment_status}
                              </td>

                              <td className="px-2 py-4">
                                {datas?.fleet_name}
                              </td>


                              <td className="px-2 py-4 ">
                                {datas?.Notes}
                              </td>
                              <td className="px-2 py-4 ">
                                {datas?.pcs}
                              </td>



                              <td className='flex justify-between gap-3 px-6 py-4'>

                                {
                                  userLoginData?.data?.role == 'fleet' || userLoginData?.data?.role == 'driver' ?
                                    ''
                                    :
                                    <span className="hover:underline text-blue-500 cursor-pointer" onClick={() => { setRiderModal(true); setFleetData2(datas) }}>
                                      Edit
                                    </span>
                                }


                                <div>
                                  <PDFDownloadLink document={<ShipmentManifest data={datas} />} fileName="runway-manifest.pdf">
                                    {({ loading }) => (loading ? 'Loading document...' : <MdDownload size={24} />)}
                                  </PDFDownloadLink>
                                </div>


                                <span className=" cursor-pointer" onClick={(e: any) => { nextPage(datas?.id); }}>
                                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="24" height="24" rx="4" fill="#EBEBEB" />
                                    <path d="M12 13.5C12.8284 13.5 13.5 12.8284 13.5 12C13.5 11.1716 12.8284 10.5 12 10.5C11.1716 10.5 10.5 11.1716 10.5 12C10.5 12.8284 11.1716 13.5 12 13.5Z" fill="#3F83F8" />
                                    <path d="M15.2536 9.30891C14.2289 8.60625 13.1323 8.25 11.9944 8.25C10.9704 8.25 9.97219 8.55469 9.02742 9.15187C8.07469 9.75539 7.1407 10.8516 6.375 12C6.99422 13.0312 7.84125 14.0916 8.72344 14.6995C9.73547 15.3966 10.8359 15.75 11.9944 15.75C13.1428 15.75 14.2409 15.3968 15.2592 14.7002C16.1557 14.0859 17.0088 13.027 17.625 12C17.0067 10.9821 16.1508 9.92437 15.2536 9.30891ZM12 14.25C11.555 14.25 11.12 14.118 10.75 13.8708C10.38 13.6236 10.0916 13.2722 9.92127 12.861C9.75097 12.4499 9.70642 11.9975 9.79323 11.561C9.88005 11.1246 10.0943 10.7237 10.409 10.409C10.7237 10.0943 11.1246 9.88005 11.561 9.79323C11.9975 9.70642 12.4499 9.75097 12.861 9.92127C13.2722 10.0916 13.6236 10.38 13.8708 10.75C14.118 11.12 14.25 11.555 14.25 12C14.2493 12.5965 14.012 13.1684 13.5902 13.5902C13.1684 14.012 12.5965 14.2493 12 14.25Z" fill="#3F83F8" />
                                  </svg>
                                </span>

                              </td>

                            </tr>
                          )
                        )
                        :
                        <div className='flex justify-start mt-4'>
                          <td >No Record Found</td>
                        </div>)

                      :
                      (userLists?.data?.length > 0 ?
                        userLists?.data?.map(
                          (datas: any, index: any) => (
                            <tr className="bg-white  ">

                              <td className="px-2 py-2">
                                {index + 1}
                              </td>

                              <td className="px-2 py-4">
                                {datas?.manifest_name}
                              </td>

                              <td className="px-2 py-4">
                                {datas?.manifest_id}
                              </td>

                              <td className="px-2 py-4">
                                {datas?.created_by}
                              </td>

                              <td className="px-2 py-4">
                                {datas?.event_location}
                              </td>

                              <td className="px-2 py-4">
                                {datas?.shipment_status}
                              </td>

                              <td className="px-2 py-4">
                                {datas?.fleet_name}
                              </td>


                              <td className="px-2 py-4 ">
                                {datas?.Notes}
                              </td>
                              <td className="px-2 py-4 ">
                                {datas?.pcs}
                              </td>


                              <td className='flex justify-between gap-3 px-6 py-4'>
                                <span className="hover:underline text-blue-500 cursor-pointer" onClick={() => { setRiderModal(true); setFleetData2(datas) }}>
                                  Edit
                                </span>

                                <div>
                                  <PDFDownloadLink document={<ShipmentManifest data={datas} />} fileName="runway-manifest.pdf">
                                    {({ loading }) => (loading ? 'Loading document...' : <MdDownload size={24} />)}
                                  </PDFDownloadLink>
                                </div>


                                <span className=" cursor-pointer" onClick={(e: any) => { nextPage(datas?.id); }}>
                                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="24" height="24" rx="4" fill="#EBEBEB" />
                                    <path d="M12 13.5C12.8284 13.5 13.5 12.8284 13.5 12C13.5 11.1716 12.8284 10.5 12 10.5C11.1716 10.5 10.5 11.1716 10.5 12C10.5 12.8284 11.1716 13.5 12 13.5Z" fill="#3F83F8" />
                                    <path d="M15.2536 9.30891C14.2289 8.60625 13.1323 8.25 11.9944 8.25C10.9704 8.25 9.97219 8.55469 9.02742 9.15187C8.07469 9.75539 7.1407 10.8516 6.375 12C6.99422 13.0312 7.84125 14.0916 8.72344 14.6995C9.73547 15.3966 10.8359 15.75 11.9944 15.75C13.1428 15.75 14.2409 15.3968 15.2592 14.7002C16.1557 14.0859 17.0088 13.027 17.625 12C17.0067 10.9821 16.1508 9.92437 15.2536 9.30891ZM12 14.25C11.555 14.25 11.12 14.118 10.75 13.8708C10.38 13.6236 10.0916 13.2722 9.92127 12.861C9.75097 12.4499 9.70642 11.9975 9.79323 11.561C9.88005 11.1246 10.0943 10.7237 10.409 10.409C10.7237 10.0943 11.1246 9.88005 11.561 9.79323C11.9975 9.70642 12.4499 9.75097 12.861 9.92127C13.2722 10.0916 13.6236 10.38 13.8708 10.75C14.118 11.12 14.25 11.555 14.25 12C14.2493 12.5965 14.012 13.1684 13.5902 13.5902C13.1684 14.012 12.5965 14.2493 12 14.25Z" fill="#3F83F8" />
                                  </svg>
                                </span>

                              </td>

                            </tr>
                          )
                        )
                        :
                        <div className='flex justify-start mt-4'>
                          <td >No Record Found</td>
                        </div>)
                    )
                  }

                </tbody>
              </table>
            </div>
            <div className='m-4 flex justify-end mt-20'>
              {
                userLists?.links?.filter(((item: any, idx: any) => idx < 1000)).map(
                  (datas: any, index: any) => (
                    <button onClick={() => paginator(datas?.label == 'Next &raquo;' ? datas?.url?.charAt(datas?.url?.length - 1) : (datas?.label === '&laquo; Previous') ? datas?.url?.charAt(datas?.url?.length - 1) : datas?.label)} disabled={datas?.active} className={'mx-1 py-1 px-2 ' + ((datas?.active == false || datas?.url == null) ? 'bg-gray-300 text-black ' : 'bg-[#E04343] text-white')}>
                      {datas?.label == '&laquo; Previous' ? '< Previous' : (datas?.label === 'Next &raquo;') ? 'Next  >' : datas?.label}
                    </button>
                  )
                )
              }

            </div>
          </div>
          :
          ''
        }

        {tabNo == "2" ?
          <div className=' lg:px-10 pb-6 px-6 bg-[#FAFAFA] min-h-screen'>

            <div className='flex justify-between my-5'>
              <div>
                <h3 className='text-[15px] text-[#1C1C1C] font-semibold capitalize'>{fleetData?.first_name} Shipments under {manifestData?.manifest?.manifest_name} List</h3>

              </div>
              <div>
                {/* <button className='bg-[#E04343] text-white text-[14px] px-[12px] py-[8px] rounded-[10px]'>+ Add Customers</button> */}
                <div className='flex justify-end'>
                  <div className="relative flex w-full flex-wrap items-stretch">
                    <div className="absolute inset-y-0 right-2 flex items-center pl-3 pointer-events-none">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="#9da4aa" d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0a5.5 5.5 0 0 1 11 0z" /></svg>
                    </div>
                    <input type="text" onClick={() => paginator2()} onChange={e => setSearchText2(e.target.value)} placeholder='search...' className=" border border-gray-300 text-gray-500 text-sm rounded-md block w-full pl-4 p-1  " required />
                  </div>

                  <div className='mt-0.5 ml-2'><button type='button' onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => paginator2()} className={"font-normal text-white bg-[#E04343] px-3 py-0.5 rounded-md"}>Search</button> </div>

                </div>




              </div>

            </div>

            <div className='flex justify-start mt-4'>
              <div className='mt-0.5 ml-2'><button type='button' onClick={() => setTabNo(1)} className={"font-normal text-white bg-[#E04343] px-3 py-0.5 rounded-md"}>Go back</button> </div>
            </div>
            <div className="relative overflow-x-auto">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 border-separate border-spacing-y-3">
                <thead className="text-[12px] text-[#808080] bg-[#FAFAFA] ">
                  <tr>

                    <th scope="col" className="px-2 py-2">
                      S/N
                    </th>
                    <th scope="col" className="px-2 py-2">
                      Reciever&nbsp;Name
                    </th>
                    <th scope="col" className="px-2 py-2">
                      Reciever&nbsp;Phone&nbsp;Number
                    </th>
                    <th scope="col" className="px-2 py-2">
                      Reciever&nbsp;Address
                    </th>
                    <th scope="col" className="px-2 py-2">
                      Status
                    </th>

                    <th scope="col" className="px-2 py-2">
                      Shipment&nbsp;Number
                    </th>


                    <th scope="col" className="px-2 py-2">
                      Fleet
                    </th>

                    <th scope="col" className="px-2 py-2">
                      Vehicle
                    </th>

                  </tr>
                </thead>


                <tbody className=''>

                  {
                    manifestData?.shipments?.length > 0 ?
                      manifestData?.shipments?.map(
                        (datas: any, index: any) => (
                          <tr className="bg-white  ">

                            <td className="px-2 py-2">
                              {index + 1}
                            </td>
                            <td className="px-6 py-4">
                              {datas?.ReceiverName}
                            </td>
                            <td className="px-6 py-4">
                              {datas?.ReceiverPhone}
                            </td>

                            <td className="px-6 py-4">
                              {datas?.ReceiverAdd1}
                            </td>

                            <td className="px-2 py-2 cursor-pointer" onClick={() => { toggleStatus(); setShipmentId(datas); setDelieveryInstruction(datas?.DeliveryInstructions) }}>
                              {(datas?.Status == 'inReview' || datas?.Status == 'InReview') ? <p><b style={{ fontWeight: "500", fontSize: '14px', backgroundColor: '#FFDDBD', color: '#F29339', borderRadius: '10px', padding: '2px 10px' }}>{datas?.Status}</b></p> : <p><b style={{ fontWeight: "500", fontSize: '14px', backgroundColor: '#C9F0D0', color: '#51CF66', borderRadius: '10px', padding: '2px 10px' }}>{datas?.Status}</b></p>}
                            </td>

                            <td className="px-6 py-4 ">
                              {datas?.ShipmentNumber ? datas?.ShipmentNumber : `Not Available`}
                            </td>

                            <td className="px-6 py-4 cursor-pointer">
                              {datas?.fleet_name ? datas?.fleet_name : "No Fleet"}
                            </td>

                            <td className="px-6 py-4" >
                              {datas?.vehicle_name ? datas?.vehicle_name : "No Vehicle"}
                            </td>

                          </tr>
                        )
                      )
                      :
                      <div className='flex justify-start mt-4'>
                        <td >No Record Found</td>
                      </div>


                  }

                </tbody>
              </table>
            </div>

          </div>
          :
          ''
        }
      </div>


      <section>
        <Modal
          visible={addModal}
          width="80%"
          height="630"
          effect="fadeInUp"
          onClickAway={() => setAddModal(false)}
        >

          {
            loading ?
              <div className="flex justify-center mt-[30vh]">
                <Oval
                  visible={true}
                  height="80"
                  width="80"
                  color="#E04343"
                  secondaryColor="#FCBF94"
                  ariaLabel="oval-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </div>
              :
              <div className=" " style={{ height: '100%', overflow: 'auto' }}>

                <div className="container  bg-[#fff] rounded-lg p-2">

                  <div className="px-3">

                    {/* <span className="flex justify-around">
                      <h1 className=" text-xs text-red-600" style={{ fontSize: '10px' }}>Link can’t be edited in free plan. <span style={{ color: '#61A24F' }} className="font-bold text-xs">Upgrade to Pro</span></h1>
                    </span> */}
                    <span className="flex justify-between px-2 pt-3">
                      <p className='mt-2'>Create Runway Manifest</p>
                      <p className="cursor-pointer font-bold" onClick={(e) => setAddModal(false)}><SvgElement type={icontypesEnum.CANCEL} /></p>
                    </span>

                    <form onSubmit={handleSubmit}>
                      <div className="flex flex-col lg:flex-row lg:justify-between lg:max-w-screen-xl mx-auto  mt-5">
                        <div className="relative flex flex-col min-w-0 break-words w-full  ">
                          <div className="flex-auto   py-5 pt-0">
                            <div className="flex flex-wrap ">

                              <div className="md:w-4/12 md:pr-4">
                                <div className="flex justify-between mb-3 ">
                                  <label className="block text-sm font-semibold text-[#19213D]">Manifest Name</label>
                                  <SvgElement type={icontypesEnum.QUESTIONMARK} />
                                </div>
                                <div className="relative mb-4">
                                  <input type="text" name='manifest_name' onChange={(e: any) => handleChange(e)} className=" border border-[#D1D1D1] text-gray-900 text-sm rounded-[5px] block w-full pl-6 p-2.5" placeholder="" />
                                </div>
                              </div>

                              <div className="md:w-8/12 w-full md:pr-4">
                                <div className="flex justify-between mb-3">
                                  <label className="block text-sm font-semibold text-[#19213D]">Notes</label>
                                  <SvgElement type={icontypesEnum.QUESTIONMARK} />
                                </div>
                                <div className="relative mb-4">
                                  <textarea name='goods_description' onChange={(e: any) => handleChange(e)} className=" border border-[#D1D1D1] text-gray-900 text-sm rounded-[5px] block w-full pl-6 p-2.5" placeholder="" />
                                </div>
                              </div>





                              <div className="w-full md:w-3/12 md:pr-4">
                                <div className="relative w-full mb-5">
                                  <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                    Select Status
                                  </label>
                                  <select id="countries" name='shipment_status' onChange={(e: any) => handleChange(e)} className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-r-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150">
                                    {
                                      STATUSTYPES?.map((datas: any, index: any) => (
                                        <option value={datas?.EventName}>{datas?.EventName}</option>
                                      )
                                      )}
                                  </select>
                                </div>
                              </div>


                              <div className="w-full md:w-3/12 md:pr-4">
                                <div className="relative w-full mb-5">
                                  <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                    Select Fleet
                                  </label>
                                  <select id="countries" onChange={(e: any) => { setShipmentRider(e); getAllVehicles(e?.target?.value) }} className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-r-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150">
                                    <option selected>{'Select Fleet'}</option>

                                    {riders?.map((datas: any, index: any) => (
                                      <option value={datas?.id}>{datas?.first_name}</option>
                                    )
                                    )}
                                  </select>
                                </div>
                              </div>

                              <div className="w-full md:w-3/12  md:pr-4">
                                <div className="relative w-full mb-5">
                                  <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                    Select Vehicle
                                  </label>
                                  <select id="countries" name='vehicle_name' onChange={(e: any) => handleChange(e)} className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-r-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150">
                                    <option selected>{'Select Vehicle'}</option>
                                    {vehicle?.filter((data: any) => data?.status == 'Available')?.map((datas: any, index: any) => (
                                      <option value={datas?.vehicle_name}>{datas?.vehicle_name}</option>
                                    )
                                    )}
                                  </select>
                                </div>
                              </div>

                              <div className="md:w-3/12 w-full md:pr-4">
                                <div className="flex justify-between mb-2">
                                  <label className="block text-sm font-semibold text-[#19213D]">No. of Items</label>
                                  <SvgElement type={icontypesEnum.QUESTIONMARK} />
                                </div>
                                <div className="relative mb-4">
                                  <input type="number" name='pcs' value={shipid?.shipids?.length} onChange={(e: any) => handleChange(e)} className=" border border-[#D1D1D1] text-gray-900 text-sm rounded-[5px] block w-full pl-6 p-2.5" placeholder="" />
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>



                      <div className=" h-[250px] overflow-auto">
                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 border-separate border-spacing-y-3">
                          <thead className="text-[12px] text-[#808080] bg-[#FAFAFA] ">
                            <tr>

                              <th scope="col" className="px-2 py-2">
                                S/N
                              </th>
                              <th scope="col" className="px-2 py-2">
                                Reciever&nbsp;Name
                              </th>
                              <th scope="col" className="px-2 py-2">
                                Reciever&nbsp;Phone&nbsp;Number
                              </th>
                              <th scope="col" className="px-2 py-2">
                                Reciever&nbsp;Address
                              </th>
                              <th scope="col" className="px-2 py-2">
                                Status
                              </th>

                              <th scope="col" className="px-2 py-2">
                                Shipment&nbsp;Number
                              </th>

                              <th scope="col" className="px-2 py-2">
                                Fleet
                              </th>

                              <th scope="col" className="px-2 py-2">
                                Vehicle
                              </th>

                            </tr>
                          </thead>
                          <tbody className=''>

                            {runmanifestListData?.filter((e: any) => e?.payment_status == 'successful')?.map(
                              (datas: any, index: any) => (
                                <tr className="bg-white  ">

                                  {/* <td className="px-2 py-2">
                                  {index + 1}
                                </td> */}

                                  <td scope="row" className="px-6 py-4 font-medium text-[#6C757D] whitespace-nowrap">
                                    <div className="flex space-x-3">
                                      <div className="flex items-center ">
                                        <input id="default-checkbox" type="checkbox" value={datas?.ShipmentNumber} onChange={selectShipment} className="w-4 h-4 accent-[#E04343] bg-gray-100 border-gray-300 rounded focus:ring-[#E04343] cursor-pointer" />
                                      </div>
                                    </div>
                                  </td>
                                  <td className="px-6 py-4">
                                    {datas?.ReceiverName}
                                  </td>
                                  <td className="px-6 py-4">
                                    {datas?.ReceiverPhone}
                                  </td>

                                  <td className="px-6 py-4">
                                    {datas?.ReceiverAdd1}
                                  </td>

                                  <td className="px-2 py-2 cursor-pointer">
                                    {(datas?.Status == 'inReview' || datas?.Status == 'InReview') ? <p><b style={{ fontWeight: "500", fontSize: '14px', backgroundColor: '#FFDDBD', color: '#F29339', borderRadius: '10px', padding: '2px 10px' }}>{datas?.Status}</b></p> : <p><b style={{ fontWeight: "500", fontSize: '14px', backgroundColor: '#C9F0D0', color: '#51CF66', borderRadius: '10px', padding: '2px 10px' }}>{datas?.Status}</b></p>}
                                  </td>

                                  <td className="px-6 py-4 ">
                                    {datas?.ShipmentNumber ? datas?.ShipmentNumber : `Not Available`}
                                  </td>

                                  <td className="px-6 py-4 cursor-pointer">
                                    {datas?.fleet_name ? datas?.fleet_name : "No Fleet"}
                                  </td>

                                  <td className="px-6 py-4" >
                                    {datas?.vehicle_name ? datas?.vehicle_name : "No Vehicle"}
                                  </td>

                                </tr>
                              )
                            )
                            }
                          </tbody>
                        </table>

                      </div>

                      <div className="flex justify-end pb-8">
                        <div className="mt-1 ">
                          <button type="submit" disabled={loading} className="text-white bg-[#E04343] rounded-[30px] transition ease-out delay-300 py-[10px] px-[42px] font-[700] text-[14px]">
                            <div className="flex justify-center gap-3 ">
                              <span>Create Run Manifest</span>
                              <Oval
                                visible={loading}
                                height="20"
                                width="20"
                                color="#E04343"
                                secondaryColor="#FCBF94"
                                ariaLabel="oval-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                              />
                            </div>
                          </button>
                        </div>

                      </div>
                    </form>
                  </div>

                </div>

              </div>

          }

        </Modal>
      </section>


      <section>
        <Modal
          visible={riderModal}
          width="900"
          height="400"
          effect="fadeInUp"
          onClickAway={() => setRiderModal(false)}
        >

          {
            loading ?
              <div className="flex justify-center mt-[20vh]">
                <Oval
                  visible={true}
                  height="80"
                  width="80"
                  color="#E04343"
                  secondaryColor="#FCBF94"
                  ariaLabel="oval-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </div>
              :
              <div className=" " style={{ height: '100%', overflow: 'auto' }}>

                <div className="container  bg-[#fff] rounded-lg p-2">

                  <div className="px-3">

                    {/* <span className="flex justify-around">
                      <h1 className=" text-xs text-red-600" style={{ fontSize: '10px' }}>Link can’t be edited in free plan. <span style={{ color: '#61A24F' }} className="font-bold text-xs">Upgrade to Pro</span></h1>
                    </span> */}
                    <span className="flex justify-between px-2 pt-3">
                      <p className='mt-2'>Update Manifest</p>
                      <p className="cursor-pointer font-bold" onClick={(e) => setRiderModal(false)}><SvgElement type={icontypesEnum.CANCEL} /></p>
                    </span>

                    <form onSubmit={updateManifest}>
                      <div className="flex flex-col lg:flex-row lg:justify-between max-w-screen-xl mx-auto  mt-5">
                        <div className="relative flex flex-col min-w-0 break-words w-full  ">
                          <div className="flex-auto   py-5 pt-0">
                            <div className="flex flex-wrap ">

                              <div className="lg:w-4/12 w-full lg:pr-4">
                                <div className="flex justify-between mb-3 ">
                                  <label className="block text-sm font-semibold text-[#19213D]">Manifest Name</label>
                                  <SvgElement type={icontypesEnum.QUESTIONMARK} />
                                </div>
                                <div className="relative mb-4">
                                  <input type="text" name='manifest_name' defaultValue={fleetData2?.manifest_name} onChange={(e: any) => handleChange2(e)} className=" border border-[#D1D1D1] text-gray-900 text-sm rounded-[5px] block w-full pl-6 p-2.5" placeholder="" />
                                </div>
                              </div>

                              <div className="lg:w-8/12 w-full lg:pr-4">
                                <div className="flex justify-between mb-3">
                                  <label className="block text-sm font-semibold text-[#19213D]">Notes</label>
                                  <SvgElement type={icontypesEnum.QUESTIONMARK} />
                                </div>
                                <div className="relative mb-4">
                                  <textarea name='Notes' defaultValue={fleetData2?.Notes} onChange={(e: any) => handleChange2(e)} className=" border border-[#D1D1D1] text-gray-900 text-sm rounded-[5px] block w-full pl-6 p-2.5" placeholder="" />
                                </div>
                              </div>




                              <div className="w-full lg:w-3/12 lg:pr-4">
                                <div className="relative w-full mb-5">
                                  <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                    Select Status
                                  </label>
                                  <select id="countries" name='shipment_status' onChange={(e: any) => handleChange2(e)} className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-r-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150">
                                    <option value={fleetData2?.shipment_status}>{fleetData2?.shipment_status}</option>
                                    {
                                      STATUSTYPES?.map((datas: any, index: any) => (
                                        <option value={datas?.EventName}>{datas?.EventName}</option>
                                      )
                                      )}
                                  </select>
                                </div>
                              </div>


                              <div className="w-full lg:w-3/12 lg:pr-4">
                                <div className="relative w-full mb-5">
                                  <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                    Select Fleet
                                  </label>
                                  <select id="countries" name='rider_name' onChange={(e: any) => { handleChange2(e); getAllVehicles(e?.target?.value) }} className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-r-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150">
                                    <option value={fleetData2?.rider_name}>{fleetData2?.rider_name}</option>

                                    {riders?.map((datas: any, index: any) => (
                                      <option value={datas?.first_name}>{datas?.first_name}</option>
                                    )
                                    )}
                                  </select>
                                </div>
                              </div>

                              <div className="w-full lg:w-3/12 lg:pr-4">
                                <div className="relative w-full mb-5">
                                  <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                    Select Vehicle
                                  </label>
                                  <select id="countries" name='vehicle_name' onChange={(e: any) => handleChange2(e)} className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-r-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150">
                                    <option value={fleetData2?.vehicle_name}>{fleetData2?.vehicle_name}</option>
                                    {vehicle?.filter((data: any) => data?.status == 'Available')?.map((datas: any, index: any) => (
                                      <option value={datas?.vehicle_name}>{datas?.vehicle_name}</option>
                                    )
                                    )}
                                  </select>
                                </div>
                              </div>





                              <div className="lg:w-3/12 w-full ">
                                <div className="flex justify-between mb-2">
                                  <label className="block text-sm font-semibold text-[#19213D]">No. of Items</label>
                                  <SvgElement type={icontypesEnum.QUESTIONMARK} />
                                </div>
                                <div className="relative mb-4">
                                  <input type="number" disabled name='pcs' defaultValue={fleetData2?.pcs} onChange={(e: any) => handleChange2(e)} className=" border border-[#D1D1D1] text-gray-900 text-sm rounded-[5px] block w-full pl-6 p-2.5" placeholder="" />
                                </div>
                              </div>





                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="flex justify-end pb-3">
                        <div className="mt-1 ">
                          <button type="submit" disabled={loading} className="text-white bg-[#E04343] rounded-[30px] transition ease-out delay-300 py-[10px] px-[42px] font-[700] text-[14px]">

                            <div className="flex justify-center gap-3 ">
                              <span>Update Run Manifest</span>
                              <Oval
                                visible={loading}
                                height="20"
                                width="20"
                                color="#E04343"
                                secondaryColor="#FCBF94"
                                ariaLabel="oval-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                              />
                            </div>
                          </button>
                        </div>

                      </div>
                    </form>
                  </div>

                </div>

              </div>

          }

        </Modal>
      </section>



      {/* Add Vehicle */}


      <section>
        <Modal
          visible={statusModal}
          width="350"
          height="450"
          effect="fadeInUp"
          onClickAway={() => setStatusModal(false)}
        >
          <div className=" " style={{ height: '100%', overflow: 'auto' }}>

            <div className="container  bg-[#fff] rounded-lg p-2">

              <div className="px-3">

                {/* <span className="flex justify-around">
                    <h1 className=" text-xs text-red-600" style={{ fontSize: '10px' }}>Link can’t be edited in free plan. <span style={{ color: '#61A24F' }} className="font-bold text-xs">Upgrade to Pro</span></h1>
                  </span> */}
                <span className="flex justify-between px-2 pt-1">
                  <p className='mt-2'>Change Status</p>
                  <p className="cursor-pointer font-bold" onClick={(e) => setStatusModal(false)}><SvgElement type={icontypesEnum.CANCEL} /></p>
                </span>
                <form className="pb-4 rounded-lg" onSubmit={updateShipment}>

                  <div className="relative w-full mt-5 mb-4">
                    <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                      Status {shipmentId?.Status}
                    </label>



                    {
                      userLoginData?.data?.role == 'fleet' || userLoginData?.data?.role == 'driver' ?
                        <div>
                          <select id="countries" name='SenderCountryCode' defaultValue={shipmentId?.Status} onChange={(e: any) => setShipmentStatus(e?.target?.value)} className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-r-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150">
                            <option selected>{shipmentId?.Status}</option>
                            <option value="Proof of Delivery">Proof of Delivery</option>
                            <option value="In Transit to Destination">In Transit to Destination</option>
                            <option value="Out for Delivery">Out for Delivery</option>
                            <option value="Attempted Delivery - Inaccurate / Incomplete Address">Attempted Delivery - Inaccurate / Incomplete Address</option>

                          </select>

                          {(shipmentStatus == 'Proof of Delivery' || shipmentId?.Status == 'Proof of Delivery') ?

                            <div>
                              <div className="w-full lg:w-12/12 mt-2 ">
                                <div className="relative w-full mb-5">
                                  <label htmlFor="message" className="block mb-2 text-sm font-semibold text-[#19213D]">Delivery Notes</label>
                                  <textarea onChange={(e: any) => setDelieveryInstruction(e.target.value)} name='DeliveryInstructions' rows={3} className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150" placeholder="Write here..." defaultValue={shipmentId?.DeliveryInstructions}></textarea>
                                </div>
                              </div>

                              <div className="w-full lg:w-12/12 ">
                                <div className="relative w-full mb-5">
                                  <label htmlFor="message" className="block mb-2 text-sm font-semibold text-[#19213D]">Delivery Code</label>
                                  <input onChange={(e: any) => setDelieveryCode(e.target.value)} name='DeliveryInstructions' className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150" placeholder="Write here..." />
                                </div>
                              </div>
                            </div>
                            :
                            ''
                          }

                        </div>


                        :
                        <select id="countries" name='SenderCountryCode' defaultValue={shipmentId?.Status} onChange={(e: any) => setShipmentStatus(e?.target?.value)} className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-r-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150">
                          <option selected>{shipmentId?.Status}</option>
                          <option value="InReview">InReview</option>
                          <option value="Verified">Verified</option>
                          {
                            STATUSTYPES?.map((datas: any, index: any) => (
                              <option value={datas?.EventName}>{datas?.EventName}</option>
                            )
                            )}
                        </select>
                    }



                  </div>


                  <span className="flex justify-center pt-4">
                    <button
                      type="submit"
                      style={{ borderRadius: '50px', color: '#F52424' }}
                      className=" text-red-700 bg-red-200 focus:ring-4 focus:outline-none focus:ring-grredeen-300 font-medium rounded-lg text-sm w-full px-2 py-2.5 text-center "
                    >



                      <div className="flex justify-center gap-3 ">
                        <span>Update</span>
                        <Oval
                          visible={loading}
                          height="20"
                          width="20"
                          color="#E04343"
                          secondaryColor="#FCBF94"
                          ariaLabel="oval-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                        />
                      </div>

                    </button>
                  </span>

                  <span className="flex justify-center pt-4">
                    <button
                      type="button"
                      onClick={(e) => setStatusModal(false)}
                      style={{ borderRadius: '50px' }}
                      className=" text-black   focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm w-full px-2 py-1.5 text-center "
                    >
                      Cancel
                    </button>
                  </span>

                </form>
              </div>

            </div>

          </div>
        </Modal>
      </section>


      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
    </>
  )
}

export default RunwayManifest