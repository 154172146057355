import React from 'react'
import AdminNavbar from "../Navbars/AdminNavbar";
import Sidebar from "../Sidebar/Sidebar";
import { AxiosResponse } from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//@ts-ignore
import Modal from 'react-awesome-modal';
import { SvgElement, icontypesEnum } from '../assets/svgElement';
import { SuperAdminApis } from '../../apis/superAdminApi';
import { useParams } from 'react-router-dom';
import AdminSidebar from '../Sidebar/AdminSidebar';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FaPen } from "react-icons/fa";
import { Oval } from 'react-loader-spinner';
import { MdDownload } from "react-icons/md";



function EditExpenseVoucher() {
  let [showScreen, setShowScreen] = React.useState<any>(1);

  const navigate = useNavigate();

  const userLoginData = useSelector((state: any) => state.data.login.value);

  let [voucherData, setVoucherData] = React.useState<any>([]);
  let [loader, setLoader] = React.useState<boolean>(false);
  let [loader2, setLoader2] = React.useState<boolean>(false);

  let [hodStatusModal, setHodStatusModal] = React.useState<boolean>(false);
  let [gmStatusModal, setGmStatusModal] = React.useState<boolean>(false);
  let [auditStatusModal, setAuditStatusModal] = React.useState<boolean>(false);
  let [groupheadStatusModal, setGroupheadStatusModal] = React.useState<boolean>(false);
  let [financeStatusModal, setFinanceStatusModal] = React.useState<boolean>(false);
  let [data, setData] = React.useState<any>([]);




  const [img1, setImg1] = React.useState('No selected file');
  const [img12, setImg12] = React.useState('empty');

  function uploadImg1(e: any) {
    let size = (e.target.files[0].size / 1048576.0)
    if (e.target.files && e.target.files[0]) {
      if (size > 4) {
        if (e.target.name == 'uploadImg1') {
          e.target.value = ''
          toast.warn('Image too large')
        }
      }
      if (size <= 4) {
        if (e.target.name == 'uploadImg1') {
          setImg1(e.target.files[0]);
          setImg12(URL.createObjectURL(e.target.files[0]))
        }
      }
    }
  };

  const params: any = useParams();
  let cusId: string = params?.id;


  React.useEffect(() => {
    SuperAdminApis.getSingleExpenseVoucher(cusId).then(
      (response: AxiosResponse<any>) => {
        if (response?.data) {
          setVoucherData(response?.data?.data)
          setImg1(response?.data?.data?.supporting_document)

          if (userLoginData?.data?.role !== 'hod' && userLoginData?.data?.role !== 'audit' && userLoginData?.data?.role !== 'finance' && userLoginData?.data?.role !== 'group head') {
            if (response?.data?.data?.hod_approval_status == 'approved') {
              setLoader(true);
            }
          }


          if (userLoginData?.data?.role == 'hod') {
            if (response?.data?.data?.audit_approval_status == 'approved' || response?.data?.data?.hod_approval_status == 'approved') {
              setLoader(true);
            }
          }


          if (userLoginData?.data?.role == 'general manager') {
            if (response?.data?.data?.general_manager_approval_status == 'approved' || response?.data?.data?.audit_approval_status == 'approved') {
              setLoader(true);
            }
          }

          if (userLoginData?.data?.role == 'audit') {
            if (response?.data?.data?.group_head_approval_status == 'approved' || response?.data?.data?.audit_approval_status == 'approved') {
              setLoader(true);
            }
          }

          if (userLoginData?.data?.role == 'group head') {
            if (response?.data?.data?.finance_approval_status == 'completed' || response?.data?.data?.group_head_approval_status == 'approved' || response?.data?.data?.audit_approval_status !== 'approved') {
              setLoader(true);
            }
          }

          if (userLoginData?.data?.role == 'finance') {
            if (response?.data?.data?.finance_approval_status == 'completed' || response?.data?.data?.group_head_approval_status !== 'approved') {
              setLoader(true);
            }
          }

          if (userLoginData?.data?.role == 'admin') {
            if (response?.data?.data?.finance_approval_status == 'completed') {
              setLoader(true);
            }
          }


        }
      }
    )
  }, []);



  function toggleFinanceStatus(data: any) {
    setData(data)
    setFinanceStatusModal(true)
  }

  function toggleHodStatus(data: any) {
    setData(data)
    setHodStatusModal(true)
  }

  function toggleAuditStatus(data: any) {
    setData(data)
    setAuditStatusModal(true)
  }

  function toggleGmStatus(data: any) {
    setData(data)
    setGmStatusModal(true)
  }

  function toggleGroupheadStatus(data: any) {
    setData(data)
    setGroupheadStatusModal(true)
  }





  const approveByHod = React.useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoader(true);
    SuperAdminApis.approveByHod(data?.id)
      .then((response: any) => {
        if (response?.data) {
          if (response?.data?.status) {
            toast.success(response?.data?.message);
            setHodStatusModal(false)
            setTimeout(() => {
              refreshPage();
            }, 1000);
            SuperAdminApis.getSingleExpenseVoucher(cusId).then(
              (response: AxiosResponse<any>) => {
                if (response?.data) {
                  setVoucherData(response?.data?.data)
                  setImg1(response?.data?.data?.supporting_document)
                }
              }
            )
          }
          // console?.log(response?.data)
        }
      }).catch((err: any) => {
        console.log(err);
      })

  }, [voucherData, hodStatusModal, data, img1, loader]);



  const rejectByHod = React.useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoader(true);
    SuperAdminApis.rejectByHod(data?.id)
      .then((response: any) => {
        if (response?.data) {
          if (response?.data?.status) {
            toast.success(response?.data?.message);
            setHodStatusModal(false)
            setTimeout(() => {
              refreshPage();
            }, 1000);
            SuperAdminApis.getSingleExpenseVoucher(cusId).then(
              (response: AxiosResponse<any>) => {
                if (response?.data) {
                  setVoucherData(response?.data?.data)
                  setImg1(response?.data?.data?.supporting_document)
                }
              }
            )
          }
          // console?.log(response?.data)
        }
      }).catch((err: any) => {
        console.log(err);
      })

  }, [voucherData, hodStatusModal, data, img1, loader]);

  const approveByGrouphead = React.useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoader(true);
    SuperAdminApis.approveBygrouphead(data?.id)
      .then((response: any) => {
        if (response?.data) {
          if (response?.data?.status) {
            toast.success(response?.data?.message);
            setGroupheadStatusModal(false)
            setTimeout(() => {
              refreshPage();
            }, 1000);
            SuperAdminApis.getSingleExpenseVoucher(cusId).then(
              (response: AxiosResponse<any>) => {
                if (response?.data) {
                  setVoucherData(response?.data?.data)
                  setImg1(response?.data?.data?.supporting_document)
                }
              }
            )
          }
          // console?.log(response?.data)
        }
      }).catch((err: any) => {
        console.log(err);
      })

  }, [voucherData, hodStatusModal, data, img1, loader]);

  const rejectByGrouphead = React.useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoader(true);
    SuperAdminApis.rejectByGrouphead(data?.id)
      .then((response: any) => {
        if (response?.data) {
          if (response?.data?.status) {
            toast.success(response?.data?.message);
            setHodStatusModal(false)
            setTimeout(() => {
              refreshPage();
            }, 1000);
            SuperAdminApis.getSingleExpenseVoucher(cusId).then(
              (response: AxiosResponse<any>) => {
                if (response?.data) {
                  setVoucherData(response?.data?.data)
                  setImg1(response?.data?.data?.supporting_document)
                }
              }
            )
          }
          // console?.log(response?.data)
        }
      }).catch((err: any) => {
        console.log(err);
      })

  }, [voucherData, hodStatusModal, data, img1, loader]);

  const approveByAudit = React.useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoader(true);
    SuperAdminApis.approveByAudit(data?.id)
      .then((response: any) => {
        if (response?.data) {
          if (response?.data?.status) {
            toast.success(response?.data?.message);
            setAuditStatusModal(false)
            setTimeout(() => {
              refreshPage();
            }, 1000);
            SuperAdminApis.getSingleExpenseVoucher(cusId).then(
              (response: AxiosResponse<any>) => {
                if (response?.data) {
                  setVoucherData(response?.data?.data)
                  setImg1(response?.data?.data?.supporting_document)
                }
              }
            )
          }
          // console?.log(response?.data)
        }
      }).catch((err: any) => {
        console.log(err);
      })

  }, [voucherData, hodStatusModal, data, img1, loader]);

  const approveByGm = React.useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoader(true);
    SuperAdminApis.approveByGm(data?.id)
      .then((response: any) => {
        if (response?.data) {
          if (response?.data?.status) {
            toast.success(response?.data?.message);
            setGmStatusModal(false)
            setTimeout(() => {
              refreshPage();
            }, 1000);
            SuperAdminApis.getSingleExpenseVoucher(cusId).then(
              (response: AxiosResponse<any>) => {
                if (response?.data) {
                  setVoucherData(response?.data?.data)
                  setImg1(response?.data?.data?.supporting_document)
                }
              }
            )
          }
          // console?.log(response?.data)
        }
      }).catch((err: any) => {
        console.log(err);
      })

  }, [voucherData, hodStatusModal, data, img1, loader]);

  const rejectByGm = React.useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoader(true);
    SuperAdminApis.rejectByGm(data?.id)
      .then((response: any) => {
        if (response?.data) {
          if (response?.data?.status) {
            toast.success(response?.data?.message);
            setGmStatusModal(false)
            setTimeout(() => {
              refreshPage();
            }, 1000);
            SuperAdminApis.getSingleExpenseVoucher(cusId).then(
              (response: AxiosResponse<any>) => {
                if (response?.data) {
                  setVoucherData(response?.data?.data)
                  setImg1(response?.data?.data?.supporting_document)
                }
              }
            )
          }
          // console?.log(response?.data)
        }
      }).catch((err: any) => {
        console.log(err);
      })

  }, [voucherData, hodStatusModal, data, img1, loader]);

  const rejectByAudit = React.useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoader(true);
    SuperAdminApis.rejectByAudit(data?.id)
      .then((response: any) => {
        if (response?.data) {
          if (response?.data?.status) {
            toast.success(response?.data?.message);
            setAuditStatusModal(false)
            setTimeout(() => {
              refreshPage();
            }, 1000);
            SuperAdminApis.getSingleExpenseVoucher(cusId).then(
              (response: AxiosResponse<any>) => {
                if (response?.data) {
                  setVoucherData(response?.data?.data)
                  setImg1(response?.data?.data?.supporting_document)
                }
              }
            )
          }
          // console?.log(response?.data)
        }
      }).catch((err: any) => {
        console.log(err);
      })

  }, [voucherData, hodStatusModal, data, img1, loader]);


  const disburseByFinance = React.useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoader(true);
    SuperAdminApis.disburseByFinance(data?.id)
      .then((response: any) => {
        if (response?.data) {
          if (response?.data?.status) {
            toast.success(response?.data?.message);
            setFinanceStatusModal(false)
            setTimeout(() => {
              refreshPage();
            }, 1000);
            SuperAdminApis.getSingleExpenseVoucher(cusId).then(
              (response: AxiosResponse<any>) => {
                if (response?.data) {
                  setVoucherData(response?.data?.data)
                  setImg1(response?.data?.data?.supporting_document)
                }
              }
            )
          }
          // console?.log(response?.data)
        }
      }).catch((err: any) => {
        console.log(err);
      })

  }, [voucherData, hodStatusModal, data, img1, loader]);





  let [statusModal, setStatusModal] = React.useState<boolean>(false);
  let [approveModal, setApproveModal] = React.useState<boolean>(false);

  const handleShipmentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVoucherData({ ...voucherData, [e.target.name]: e.target.value });
  }


  const updateExpenseVoucher = React.useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoader(true)
    setLoader2(true)
    const formData = new FormData()
    formData.append('full_name', voucherData?.full_name)
    formData.append('department', voucherData?.department)
    formData.append('email', voucherData?.email)
    formData.append('description_of_purpose', voucherData?.description_of_purpose)
    formData.append('category_of_expenses', voucherData?.category_of_expenses)
    formData.append('justification_of_request', voucherData?.justification_of_request)
    formData.append('amount_requested', voucherData?.amount_requested)
    formData.append('approved_amount', voucherData?.approved_amount)
    formData.append('cost_breakdown', voucherData?.cost_breakdown)
    formData.append('supporting_document', img1)
    formData.append('comment', voucherData?.comment)
    formData.append('hod_email', voucherData?.hod_email)
    formData.append('audit_email', voucherData?.audit_email)
    formData.append('group_head_email', voucherData?.group_head_email)
    formData.append('finance_email', voucherData?.finance_email)
    formData.append('id', voucherData?.id)

    SuperAdminApis.updateVoucher(formData)
      .then((response: any) => {
        if (response?.data) {
          if (response?.data?.status) {
            toast.success(response?.data?.message);
            setLoader(false)
            setTimeout(() => {
              navigate('/admin/expense-voucher');
            }, 2000);
          }
          // console?.log(response?.data)
        }
      }).catch((err: any) => {
        setLoader(false)
        console.log(err);
      })

  }, [voucherData, loader, img1, loader2]);





  // console?.log(shipmentRider)
  const [searchText, setSearchText] = React.useState('');
  function refreshPage() {
    window.location.reload();
  }

  return (
    <>
      <AdminSidebar title="Expense Voucher" />
      <div className="relative md:ml-64 bg-[#FAFAFA] ">
        <AdminNavbar title={`Expense Voucher (${voucherData?.voucher_id})`} />


        <div>
          <form onSubmit={updateExpenseVoucher}>
            <div >
              <div className=' lg:px-10 pb-6 px-6 bg-[#FAFAFA] min-h-screen'>
                {/* <!-- Sender Information --> */}
                <div className="px-6 lg:mt-5 bg-white pt-[24px] rounded-[10px]">

                  <div>

                    <div className="flex flex-col lg:flex-row lg:justify-between max-w-screen-xl mx-auto  mt-5">
                      <div className="relative flex flex-col min-w-0 break-words w-full  ">
                        <div className="flex-auto   py-10 pt-0">
                          <div className="flex flex-wrap ">

                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full mb-5">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  Full Name
                                </label>
                                <input
                                  type="text"
                                  disabled
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  placeholder="Company Name"
                                  name="full_name"
                                  defaultValue={voucherData?.full_name}
                                  onChange={handleShipmentChange}
                                  required
                                />
                              </div>
                            </div>



                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full mb-5">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  Department
                                </label>
                                <input
                                  type="text"
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  placeholder="Company Phone Number"
                                  name="department"
                                  disabled
                                  defaultValue={voucherData?.department}
                                  onChange={handleShipmentChange}
                                  required
                                />
                              </div>
                            </div>



                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full mb-5">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  E-Mail Address
                                </label>
                                <input
                                  type="email"
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  placeholder="Company Email"
                                  name="email"
                                  disabled
                                  defaultValue={voucherData?.email}
                                  onChange={handleShipmentChange}
                                  required
                                />
                              </div>
                            </div>

                            <div className="w-full lg:w-8/12 lg:pr-4">
                              <div className="relative w-full mb-5">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  Description Of Purpose
                                </label>
                                <textarea
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  placeholder="Purpose"
                                  defaultValue={voucherData?.description_of_purpose}
                                  name="description_of_purpose"
                                  onChange={(e: any) => handleShipmentChange(e)}
                                  required
                                />
                              </div>
                            </div>


                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full mb-5">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  Category Of Expenses
                                </label>
                                <input
                                  type="text"
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  placeholder=""
                                  defaultValue={voucherData?.category_of_expenses}
                                  name="category_of_expenses"
                                  onChange={handleShipmentChange}
                                  required
                                />
                              </div>
                            </div>


                            <div className="w-full lg:w-8/12 lg:pr-4">
                              <div className="relative w-full mb-5">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  Justification Of Request
                                </label>
                                <textarea
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  placeholder="Request justification"
                                  name="justification_of_request"
                                  defaultValue={voucherData?.justification_of_request}
                                  onChange={(e: any) => handleShipmentChange(e)}
                                  required
                                />
                              </div>
                            </div>

                            <div className=" w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full ">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  Supporting Document
                                </label>
                                <div className='flex justify-between'>
                                  <input id="dropzone-file" onChange={uploadImg1} accept="image/x-png,image/gif,image/jpeg" name='uploadImg1' type="file" className={" mb-2 text-sm text-[#6C757D] font-medium"} />

                                  {img1 == "no image" || !img1 ?
                                    ''
                                    :
                                    <a href={img1} target='_blank' className='text-[12px] mt-1 text-blue-500 underline'>
                                      View
                                      {/* <MdDownload size={17} /> */}
                                    </a>
                                  }

                                </div>

                              </div>
                              <span className="text-[10px] text-[#dc143c]">File should not be more than 4MB.</span>
                            </div>


                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full mb-5">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  Amount Requested
                                </label>
                                <input
                                  type="text"
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  placeholder="NG"
                                  name="amount_requested"
                                  defaultValue={voucherData?.amount_requested}
                                  onChange={handleShipmentChange}
                                  required
                                />
                              </div>
                            </div>


                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full mb-5">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  Amount Approved
                                </label>
                                <input
                                  type="text"
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  placeholder="NG"
                                  name="approved_amount"
                                  defaultValue={voucherData?.approved_amount}
                                  onChange={handleShipmentChange}
                                  required
                                />
                              </div>
                            </div>


                            <div className="w-full lg:w-8/12 lg:pr-4">
                              <div className="relative w-full mb-5">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  Cost Breakdown
                                </label>
                                <textarea
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  placeholder="Breakdown"
                                  name="cost_breakdown"
                                  defaultValue={voucherData?.cost_breakdown}
                                  onChange={(e: any) => handleShipmentChange(e)}
                                  required
                                />
                              </div>
                            </div>

                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  Head Of Department E-mail
                                </label>
                                <input
                                  type="text"
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  placeholder=""
                                  disabled
                                  name="hod_email"
                                  defaultValue={voucherData?.hod_email}
                                  onChange={handleShipmentChange}
                                  required
                                />
                              </div>
                            </div>


                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  General Manager E-mail
                                </label>
                                <input
                                  type="text"
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  placeholder=""
                                  disabled
                                  name="general_manager_email"
                                  defaultValue={voucherData?.general_manager_email}
                                  onChange={handleShipmentChange}

                                />
                              </div>
                            </div>


                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full">
                                <label className="block mb-2 text-[12px] font-semibold text-[#19213D]">
                                  Audit Email
                                </label>
                                <input
                                  type="text"
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  placeholder=""
                                  disabled
                                  name="audit_email"
                                  defaultValue={voucherData?.audit_email}
                                  onChange={handleShipmentChange}
                                  required
                                />
                              </div>
                            </div>


                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full">
                                <label className="block mb-2 text-[12px] font-semibold text-[#19213D]">
                                  Group Head Email
                                </label>
                                <input
                                  type="text"
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  placeholder=""
                                  disabled
                                  name="group_head_email"
                                  defaultValue={voucherData?.group_head_email}
                                  onChange={handleShipmentChange}
                                  required
                                />
                              </div>
                            </div>



                            <div className="w-full lg:w-4/12 lg:pr-4">
                              <div className="relative w-full">
                                <label className="block mb-2 text-[12px] font-semibold text-[#19213D]">
                                  Finance Email
                                </label>
                                <input
                                  type="text"
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  placeholder=""
                                  disabled
                                  name="finance_email"
                                  defaultValue={voucherData?.finance_email}
                                  onChange={handleShipmentChange}
                                  required
                                />
                              </div>
                            </div>


                            <div className="w-full lg:w-8/12 lg:pr-4">
                              <div className="relative w-full mb-5">
                                <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                  Comments
                                </label>
                                <textarea
                                  className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-3 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                                  placeholder="comment"
                                  name="comment"
                                  rows={5}
                                  defaultValue={voucherData?.comment}
                                  onChange={(e: any) => handleShipmentChange(e)}
                                  required
                                />
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>

                  </div>

                </div>

                {/* <!-- Receiver  Information --> */}


                <div className='flex justify-end mt-6'>
                  <div className='flex space-x-10'>
                    <div className='flex justify-between'>

                      <div>
                      {(userLoginData?.email == voucherData?.hod_email && voucherData?.hod_approval_status !== 'approved') ?
                          <div onClick={() => toggleHodStatus(voucherData)} className="mt-1 cursor-pointer mr-5">
                            <button
                              type='button'
                              disabled={loader}
                              className={loader ? 'bg-[#808080] rounded-[40px] py-[5px] px-[20px] text-white inline-flex' : 'bg-[#E04343] rounded-[40px] py-[5px] px-[20px] text-white inline-flex'}  >
                              Approve/Reject
                            </button>
                          </div>
                          :
                          ''
                        }

                        {(userLoginData?.data?.role == 'general manager' || userLoginData?.email == voucherData?.general_manager_email) && voucherData?.general_manager_approval_status !== 'approved' ?
                          <div onClick={() => toggleGmStatus(voucherData)} className="mt-1 cursor-pointer">
                             <button
                              type='button'
                              disabled={loader}
                              className={loader ? 'bg-[#808080] rounded-[40px] py-[5px] px-[20px] text-white inline-flex' : 'bg-[#E04343] rounded-[40px] py-[5px] px-[20px] text-white inline-flex'}  >
                              Approve/Reject
                            </button>
                          </div>
                          :
                          ''
                        }



                        {(voucherData?.audit_approval_status !== 'approved' && userLoginData?.data?.role == 'audit') ?
                          <div onClick={() => toggleAuditStatus(voucherData)} className="mt-1 cursor-pointer">
                             <button
                              type='button'
                              disabled={loader}
                              className={loader ? 'bg-[#808080] rounded-[40px] py-[5px] px-[20px] text-white inline-flex' : 'bg-[#E04343] rounded-[40px] py-[5px] px-[20px] text-white inline-flex'}  >
                              Approve/Reject
                            </button>
                          </div>
                          :
                          ''
                        }


                        {voucherData?.audit_approval_status == 'approved' && voucherData?.group_head_approval_status !== 'approved' && userLoginData?.data?.role == 'group head' ?
                          <div onClick={() => toggleGroupheadStatus(voucherData)} className="mt-1 cursor-pointer">
                             <button
                              type='button'
                              disabled={loader}
                              className={loader ? 'bg-[#808080] rounded-[40px] py-[5px] px-[20px] text-white inline-flex' : 'bg-[#E04343] rounded-[40px] py-[5px] px-[20px] text-white inline-flex'}  >
                              Approve/Reject
                            </button>
                          </div>
                          :
                          ''
                        }

                        {(voucherData?.group_head_approval_status == 'approved' && userLoginData?.data?.role == 'finance' && voucherData?.finance_approval_status !== 'approved') ?
                          <div onClick={() => toggleFinanceStatus(voucherData)} className="mt-1 cursor-pointer">
                             <button
                              type='button'
                              disabled={loader}
                              className={loader ? 'bg-[#808080] rounded-[40px] py-[5px] px-[20px] text-white inline-flex' : 'bg-[#E04343] rounded-[40px] py-[5px] px-[20px] text-white inline-flex'}  >
                              Approve/Reject
                            </button>
                          </div>
                          :
                          ''
                        }
                      </div>



                      <button
                        // onClick={() => setShowScreen(2)}
                        type='submit'
                        disabled={loader}
                        className={loader ? 'bg-[#808080] rounded-[40px] py-[5px] px-[20px] text-white inline-flex' : 'bg-[#E04343] rounded-[40px] py-[5px] px-[20px] text-white inline-flex'}  >
                        <div className="flex justify-center gap-3 ">
                          <span> Update Voucher</span>
                          <Oval
                            visible={loader2}
                            height="20"
                            width="20"
                            color="#E04343"
                            secondaryColor="#FCBF94"
                            ariaLabel="oval-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                          />
                        </div>
                      </button>
                    </div>
                  </div>
                </div>



              </div>
            </div>

            {/* <!-- Shipments  Information --> */}

          </form>
        </div>

      </div>


      <section>
        <Modal
          visible={hodStatusModal}
          width="350"
          height="400"
          effect="fadeInUp"
          onClickAway={() => setHodStatusModal(false)}
        >
          <div className=" " style={{ height: '100%', overflow: 'auto' }}>

            <div className="container  bg-[#fff] rounded-lg p-2">

              <div className="px-3">

                {/* <span className="flex justify-around">
                    <h1 className=" text-xs text-red-600" style={{ fontSize: '10px' }}>Link can’t be edited in free plan. <span style={{ color: '#61A24F' }} className="font-bold text-xs">Upgrade to Pro</span></h1>
                  </span> */}
                <span className="flex justify-between px-2 pt-3">
                  <p className='mt-2 font-bold'>Voucher Approval by HOD</p>
                  <p className="cursor-pointer font-bold" onClick={(e) => setHodStatusModal(false)}><SvgElement type={icontypesEnum.CANCEL} /></p>
                </span>

                <div className='my-10 text-[14px] text-gray-600'>
                  Please review the details of the voucher request below carefully before making your decision. Your action will determine the next steps in the approval process.
                </div>
                <form className="pb-4 rounded-lg">
                  <div className="flex justify-between gap-2 pt-4">
                    <button
                      type="button"
                      disabled={loader}
                      onClick={(e: any) => approveByHod(e)}
                      style={{ borderRadius: '50px' }}
                      className=" text-green-700 bg-green-200 focus:ring-4 focus:outline-none focus:ring-grredeen-300 font-medium rounded-lg text-sm w-full px-2 py-2.5 text-center "
                    >
                      Approve
                    </button>

                    <button
                      type="button"
                      disabled={loader}
                      onClick={(e: any) => rejectByHod(e)}
                      style={{ borderRadius: '50px', color: '#F52424' }}
                      className=" text-red-700 bg-red-200 focus:ring-4 focus:outline-none focus:ring-grredeen-300 font-medium rounded-lg text-sm w-full px-2 py-2.5 text-center "
                    >
                      Reject
                    </button>


                  </div>



                  <div >
                    {loader ?
                      <span className="flex justify-center pt-4">
                        <Oval
                          visible={true}
                          height="30"
                          width="30"
                          color="#F52424"
                          secondaryColor=""
                          ariaLabel="oval-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                        />
                      </span>
                      :
                      <span className="flex justify-center pt-4">
                        <button
                          type="button"
                          onClick={(e) => setHodStatusModal(false)}
                          style={{ borderRadius: '50px' }}
                          className=" text-black   focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm w-full px-2 py-1.5 text-center "
                        >
                          Cancel
                        </button>
                      </span>
                    }
                  </div>




                </form>
              </div>

            </div>

          </div>
        </Modal>
      </section>


      <section>
        <Modal
          visible={gmStatusModal}
          width="350"
          height="400"
          effect="fadeInUp"
          onClickAway={() => setGmStatusModal(false)}
        >
          <div className=" " style={{ height: '100%', overflow: 'auto' }}>

            <div className="container  bg-[#fff] rounded-lg p-2">

              <div className="px-3">

                {/* <span className="flex justify-around">
                    <h1 className=" text-xs text-red-600" style={{ fontSize: '10px' }}>Link can’t be edited in free plan. <span style={{ color: '#61A24F' }} className="font-bold text-xs">Upgrade to Pro</span></h1>
                  </span> */}
                <span className="flex justify-between px-2 pt-3">
                  <p className='mt-2 text-[13px] font-bold'>Voucher Approval by General Manager</p>
                  <p className="cursor-pointer font-bold" onClick={(e) => setGmStatusModal(false)}><SvgElement type={icontypesEnum.CANCEL} /></p>
                </span>

                <div className='my-10 text-[14px] text-gray-600'>
                  Please review the details of the voucher request below carefully before making your decision. Your action will determine the next steps in the approval process.
                </div>
                <form className="pb-4 rounded-lg" onSubmit={approveByGm}>
                  <div className="flex justify-between gap-2 pt-4">
                    <button
                      type="button"
                      disabled={loader}
                      onClick={(e: any) => approveByGm(e)}
                      style={{ borderRadius: '50px' }}
                      className=" text-green-700 bg-green-200 focus:ring-4 focus:outline-none focus:ring-grredeen-300 font-medium rounded-lg text-sm w-full px-2 py-2.5 text-center "
                    >
                      Approve
                    </button>

                    <button
                      type="button"
                      disabled={loader}
                      onClick={(e: any) => rejectByGm(e)}
                      style={{ borderRadius: '50px', color: '#F52424' }}
                      className=" text-red-700 bg-red-200 focus:ring-4 focus:outline-none focus:ring-grredeen-300 font-medium rounded-lg text-sm w-full px-2 py-2.5 text-center "
                    >
                      Reject
                    </button>


                  </div>



                  <div >
                    {loader ?
                      <span className="flex justify-center pt-4">
                        <Oval
                          visible={true}
                          height="30"
                          width="30"
                          color="#F52424"
                          secondaryColor=""
                          ariaLabel="oval-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                        />
                      </span>
                      :
                      <span className="flex justify-center pt-4">
                        <button
                          type="button"
                          onClick={(e) => setAuditStatusModal(false)}
                          style={{ borderRadius: '50px' }}
                          className=" text-black   focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm w-full px-2 py-1.5 text-center "
                        >
                          Cancel
                        </button>
                      </span>
                    }
                  </div>




                </form>
              </div>

            </div>

          </div>
        </Modal>
      </section>

      <section>
        <Modal
          visible={auditStatusModal}
          width="350"
          height="400"
          effect="fadeInUp"
          onClickAway={() => setAuditStatusModal(false)}
        >
          <div className=" " style={{ height: '100%', overflow: 'auto' }}>

            <div className="container  bg-[#fff] rounded-lg p-2">

              <div className="px-3">

                {/* <span className="flex justify-around">
                    <h1 className=" text-xs text-red-600" style={{ fontSize: '10px' }}>Link can’t be edited in free plan. <span style={{ color: '#61A24F' }} className="font-bold text-xs">Upgrade to Pro</span></h1>
                  </span> */}
                <span className="flex justify-between px-2 pt-3">
                  <p className='mt-2 text-[13px] font-bold'>Voucher Approval by Audit</p>
                  <p className="cursor-pointer font-bold" onClick={(e) => setAuditStatusModal(false)}><SvgElement type={icontypesEnum.CANCEL} /></p>
                </span>

                <div className='my-10 text-[14px] text-gray-600'>
                  Please review the details of the voucher request below carefully before making your decision. Your action will determine the next steps in the approval process.
                </div>
                <form className="pb-4 rounded-lg" onSubmit={approveByAudit}>
                  <div className="flex justify-between gap-2 pt-4">
                    <button
                      type="button"
                      disabled={loader}
                      onClick={(e: any) => approveByAudit(e)}
                      style={{ borderRadius: '50px' }}
                      className=" text-green-700 bg-green-200 focus:ring-4 focus:outline-none focus:ring-grredeen-300 font-medium rounded-lg text-sm w-full px-2 py-2.5 text-center "
                    >
                      Approve
                    </button>

                    <button
                      type="button"
                      disabled={loader}
                      onClick={(e: any) => rejectByAudit(e)}
                      style={{ borderRadius: '50px', color: '#F52424' }}
                      className=" text-red-700 bg-red-200 focus:ring-4 focus:outline-none focus:ring-grredeen-300 font-medium rounded-lg text-sm w-full px-2 py-2.5 text-center "
                    >
                      Reject
                    </button>


                  </div>



                  <div >
                    {loader ?
                      <span className="flex justify-center pt-4">
                        <Oval
                          visible={true}
                          height="30"
                          width="30"
                          color="#F52424"
                          secondaryColor=""
                          ariaLabel="oval-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                        />
                      </span>
                      :
                      <span className="flex justify-center pt-4">
                        <button
                          type="button"
                          onClick={(e) => setAuditStatusModal(false)}
                          style={{ borderRadius: '50px' }}
                          className=" text-black   focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm w-full px-2 py-1.5 text-center "
                        >
                          Cancel
                        </button>
                      </span>
                    }
                  </div>




                </form>
              </div>

            </div>

          </div>
        </Modal>
      </section>

      <section>
        <Modal
          visible={groupheadStatusModal}
          width="350"
          height="400"
          effect="fadeInUp"
          onClickAway={() => setGroupheadStatusModal(false)}
        >
          <div className=" " style={{ height: '100%', overflow: 'auto' }}>

            <div className="container  bg-[#fff] rounded-lg p-2">

              <div className="px-3">

                {/* <span className="flex justify-around">
                    <h1 className=" text-xs text-red-600" style={{ fontSize: '10px' }}>Link can’t be edited in free plan. <span style={{ color: '#61A24F' }} className="font-bold text-xs">Upgrade to Pro</span></h1>
                  </span> */}
                <span className="flex justify-between px-2 pt-3">
                  <p className='mt-2 text-[13px] font-bold'>Voucher Approval by Group Head</p>
                  <p className="cursor-pointer font-bold" onClick={(e) => setGroupheadStatusModal(false)}><SvgElement type={icontypesEnum.CANCEL} /></p>
                </span>

                <div className='my-10 text-[14px] text-gray-600'>
                  Please review the details of the voucher request below carefully before making your decision. Your action will determine the next steps in the approval process.
                </div>
                <form className="pb-4 rounded-lg" onSubmit={approveByGrouphead}>
                  <div className="flex justify-between gap-2 pt-4">
                    <button
                      type="button"
                      disabled={loader}
                      onClick={(e: any) => approveByGrouphead(e)}
                      style={{ borderRadius: '50px' }}
                      className=" text-green-700 bg-green-200 focus:ring-4 focus:outline-none focus:ring-grredeen-300 font-medium rounded-lg text-sm w-full px-2 py-2.5 text-center "
                    >
                      Approve
                    </button>

                    <button
                      type="button"
                      disabled={loader}
                      onClick={(e: any) => rejectByGrouphead(e)}
                      style={{ borderRadius: '50px', color: '#F52424' }}
                      className=" text-red-700 bg-red-200 focus:ring-4 focus:outline-none focus:ring-grredeen-300 font-medium rounded-lg text-sm w-full px-2 py-2.5 text-center "
                    >
                      Reject
                    </button>


                  </div>



                  <div >
                    {loader ?
                      <span className="flex justify-center pt-4">
                        <Oval
                          visible={true}
                          height="30"
                          width="30"
                          color="#F52424"
                          secondaryColor=""
                          ariaLabel="oval-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                        />
                      </span>
                      :
                      <span className="flex justify-center pt-4">
                        <button
                          type="button"
                          onClick={(e) => setHodStatusModal(false)}
                          style={{ borderRadius: '50px' }}
                          className=" text-black   focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm w-full px-2 py-1.5 text-center "
                        >
                          Cancel
                        </button>
                      </span>
                    }
                  </div>




                </form>
              </div>

            </div>

          </div>
        </Modal>
      </section>


      <section>
        <Modal
          visible={financeStatusModal}
          width="350"
          height="400"
          effect="fadeInUp"
          onClickAway={() => setFinanceStatusModal(false)}
        >
          <div className=" " style={{ height: '100%', overflow: 'auto' }}>

            <div className="container  bg-[#fff] rounded-lg p-2">

              <div className="px-3">

                {/* <span className="flex justify-around">
                    <h1 className=" text-xs text-red-600" style={{ fontSize: '10px' }}>Link can’t be edited in free plan. <span style={{ color: '#61A24F' }} className="font-bold text-xs">Upgrade to Pro</span></h1>
                  </span> */}
                <span className="flex justify-between px-2 pt-3">
                  <p className='mt-2 text-[13px] font-bold'>Voucher Approval by Finance</p>
                  <p className="cursor-pointer font-bold" onClick={(e) => setFinanceStatusModal(false)}><SvgElement type={icontypesEnum.CANCEL} /></p>
                </span>

                <div className='my-10 text-[14px] text-gray-600'>
                  Please review the details of the voucher request below carefully before making your decision. Your action will determine the next steps in the approval process.
                </div>
                <form className="pb-4 rounded-lg" onSubmit={disburseByFinance}>
                  <div className="flex justify-center gap-2 pt-4">
                    <button
                      type="button"
                      disabled={loader}
                      onClick={(e: any) => disburseByFinance(e)}
                      style={{ borderRadius: '50px' }}
                      className=" text-green-700 bg-green-200 focus:ring-4 focus:outline-none focus:ring-grredeen-300 font-medium rounded-lg text-sm w-full px-2 py-2.5 text-center "
                    >
                      Disburse
                    </button>




                  </div>



                  <div >
                    {loader ?
                      <span className="flex justify-center pt-4">
                        <Oval
                          visible={true}
                          height="30"
                          width="30"
                          color="#F52424"
                          secondaryColor=""
                          ariaLabel="oval-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                        />
                      </span>
                      :
                      <span className="flex justify-center pt-4">
                        <button
                          type="button"
                          onClick={(e) => setHodStatusModal(false)}
                          style={{ borderRadius: '50px' }}
                          className=" text-black   focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm w-full px-2 py-1.5 text-center "
                        >
                          Cancel
                        </button>
                      </span>
                    }
                  </div>




                </form>
              </div>

            </div>

          </div>
        </Modal>
      </section>

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
    </>
  )
}

export default EditExpenseVoucher