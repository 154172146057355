import React from 'react'
import AdminNavbar from "../../Navbars/AdminNavbar";
import Sidebar from "../../Sidebar/Sidebar";
import { SuperAdminApis } from '../../../apis/superAdminApi';
import { useNavigate } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { convertUTCDateToLocalDate } from '../../Reusable/functions';
//@ts-ignore
import Modal from 'react-awesome-modal';
import { NavLink } from "react-router-dom";
import { SvgElement, icontypesEnum } from '../../assets/svgElement';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function AddStateToModal(props: any) {

  const navigate = useNavigate();
  const [userLists, setUserList] = React.useState<any>([]);
  const [stateName, setStateName] = React.useState<any>('');
  const [StateCode, setStateCode] = React.useState<any>('');
  const [zone, setZone] = React.useState<any>('');
  const [baseValue, setBaseValue] = React.useState<any>('');
  const [perKg, setPerKg] = React.useState<any>('');
  const [tenKg, setTenKg] = React.useState<any>('');
  const [twentyKg, setTwentyKg] = React.useState<any>('');
  const [thirtyKg, setthirtyKg] = React.useState<any>('');
  const [fortyKg, setFortyKg] = React.useState<any>('');
  const [fiftyKg, setFiftyKg] = React.useState<any>('');
  const [clientEmail, setClientEmail] = React.useState<any>('');


  // React.useEffect(() => {
  //   SuperAdminApis.getAllCountryFrom('').then(
  //     (response: AxiosResponse<any>) => {
  //       if (response?.data) {
  //         setUserList(response?.data?.data)
  //         console?.log(response?.data?.data)
  //       }
  //     }
  //   ).catch(function (error: any) {
  //     // handle error

  //   })
  // }, []);

  // console?.log(zone)


  const handleSubmit = React.useCallback(
    (e: any) => {
      e.preventDefault();

      const formData = new FormData()
      formData.append('country_id', props?.data?.id)
      formData.append('state_name', stateName) 
      formData.append('state_code', StateCode)
      formData.append('client_id', clientEmail)
      formData.append('zone', zone)
      formData.append('base_value', baseValue)
      formData.append('per_kg', perKg)
      formData.append('ten_kg', tenKg)
      formData.append('twenty_kg', twentyKg)
      formData.append('thirty_kg', thirtyKg)
      formData.append('forty_kg', fortyKg)
      formData.append('fifty_kg', fiftyKg)
      
      SuperAdminApis.addStateTo(formData).then(
        (response: any) => {
          if (response?.data) {

            toast.success(response?.data?.message);
            setStateName('')
            setStateCode('')
            setZone('')
            props?.handleClose()
          } else {
            toast.error(response?.response?.data?.message);
          }

          // toast.success(response?.data?.message);
        }
      ).catch(function (error) {
        // handle error
        // console.log(error.response);
        toast.error(error.response.data.message);

      })
    },
    [stateName,StateCode,zone,props,baseValue,perKg,clientEmail,tenKg,twentyKg,thirtyKg,fortyKg,fiftyKg]
  );


  return (
    <>

      <section>
        <Modal
          visible={props?.show}
          width="600"
          height="700"
          effect="fadeInUp"
          onClickAway={() => props?.handleClose}
        >
          <div className=" " style={{ overflow: 'auto' }}>
            <span className="flex justify-between px-3 pt-3 mb-3">
              <p className='font-bold'>Add State in {props?.data?.country_name}</p>
              <p className="cursor-pointer font-bold" onClick={props?.handleClose}><SvgElement type={icontypesEnum.CANCEL} /></p>

            </span>

            <form onSubmit={handleSubmit}>
              <div className=' px-3'>
                <div className="flex flex-col lg:flex-row lg:justify-between max-w-screen-xl mx-auto ">
                  <div className="relative flex flex-col min-w-0 break-words w-full  ">
                    <div className="flex-auto   py-10 pt-0">
                      <div className="flex flex-wrap ">

                        <div className="w-full lg:w-6/12 lg:pr-4">
                          <div className="relative w-full mb-5">
                            <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                              State Name
                            </label>
                            <input
                              type="text"
                              onChange={(e)=>setStateName(e.target.value)}
                              value={stateName}
                              className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                              placeholder=""
                              

                              // onChange={null}
                              required
                            />
                          </div>
                        </div>
                        <div className="w-full lg:w-6/12 lg:pr-4">
                          <div className="relative w-full mb-5">
                            <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                            State Code
                            </label>
                            <input
                              type="text"
                              onChange={(e)=>setStateCode(e.target.value)}
                              className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                              placeholder=""
                              value={StateCode}
                              
                              // onChange={null}
                              required
                            />
                          </div>
                        </div>

                       

                        <div className="w-full lg:w-6/12 lg:pr-4">
                            <div className="relative w-full mb-5">
                              <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                                Zone
                              </label>
                              <select id="countries" name='zone' onChange={(e: any) => setZone(e.target.value)} className="border border-[#D1D1D1] bg-white text-[#333333] text-sm rounded-[5px] block w-full p-2.5 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150">
                              <option disabled selected>{zone}</option>
                                <option value="north-central">North Central</option>
                                <option value="north-east">North East</option>
                                <option value="north-west">North West</option>
                                <option value="south-east">South East</option>
                                <option value="south-south">South South</option>
                                <option value="south-west">South West</option>
                              </select>

                            </div>
                          </div>

                        {/* <div className="w-full lg:w-12/12 lg:pr-4">
                          <div className="relative w-full mb-5">
                            <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                               Zone
                            </label>
                            <input
                              type="text"
                              onChange={(e)=>setZone(e.target.value)}
                              className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                              placeholder=""
                              value={zone}
                              
                              // onChange={null}
                              required
                            />
                          </div>
                        </div> */}

                        <div className="w-full lg:w-6/12 lg:pr-4">
                          <div className="relative w-full mb-5">
                            <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                               Base value
                            </label>
                            <input
                              type="number"
                              onChange={(e)=>setBaseValue(e.target.value)}
                              className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                              placeholder=""
                              value={baseValue}
                              
                              // onChange={null}
                              required
                            />
                          </div>
                        </div>

                        <div className="w-full lg:w-4/12 lg:pr-4">
                          <div className="relative w-full mb-5">
                            <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                               0.5-10 KG
                            </label>
                            <input
                              type="number"
                              onChange={(e)=>setPerKg(e.target.value)}
                              className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                              placeholder=""
                              value={perKg}
                              
                              // onChange={null}
                              required
                            />
                          </div>
                        </div>

                        <div className="w-full lg:w-4/12 lg:pr-4">
                          <div className="relative w-full mb-5">
                            <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                            10.5-20 KG
                            </label>
                            <input
                              type="number"
                              onChange={(e)=>setTenKg(e.target.value)}
                              className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                              placeholder=""
                              value={tenKg}
                              
                              // onChange={null}
                              required
                            />
                          </div>
                        </div>

                        <div className="w-full lg:w-4/12 lg:pr-4">
                          <div className="relative w-full mb-5">
                            <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                               20.5-30 kg
                            </label>
                            <input
                              type="number"
                              onChange={(e)=>setTwentyKg(e.target.value)}
                              className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                              placeholder=""
                              value={twentyKg}
                              
                              // onChange={null}
                              required
                            />
                          </div>
                        </div>

                        <div className="w-full lg:w-4/12 lg:pr-4">
                          <div className="relative w-full mb-5">
                            <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                            30.5-40 kg
                            </label>
                            <input
                              type="number"
                              onChange={(e)=>setthirtyKg(e.target.value)}
                              className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                              placeholder=""
                              value={thirtyKg}
                              
                              // onChange={null}
                              required
                            />
                          </div>
                        </div>
                        <div className="w-full lg:w-4/12 lg:pr-4">
                          <div className="relative w-full mb-5">
                            <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                            40.5-50 kg
                            </label>
                            <input
                              type="number"
                              onChange={(e)=>setFortyKg(e.target.value)}
                              className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                              placeholder=""
                              value={fortyKg}
                              
                              // onChange={null}
                              required
                            />
                          </div>
                        </div>


                        <div className="w-full lg:w-4/12 lg:pr-4">
                          <div className="relative w-full mb-5">
                            <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                            50Kg and Above
                            </label>
                            <input
                              type="number"
                              onChange={(e)=>setFiftyKg(e.target.value)}
                              className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                              placeholder=""
                              value={fiftyKg}
                              
                              // onChange={null}
                              required
                            />
                          </div>
                        </div>

                        <div className="w-full lg:w-12/12 lg:pr-4">
                          <div className="relative w-full mb-5">
                            <label className="block mb-2 text-sm font-semibold text-[#19213D]">
                             Client Email (Optional)
                            </label>
                            <input
                              type="email"
                              onChange={(e)=>setClientEmail(e.target.value)}
                              className="bg-white border-[0.5px] border-[#DADADA] text-[#333333] text-sm rounded-[5px] block w-full p-2 placeholder:text-[#DADADA] placeholder:text-[12px]  ease-linear transition-all duration-150"
                              placeholder=""
                              value={clientEmail}
                              // onChange={null}
                              
                            />
                          </div>
                        </div>





                        <div className="w-full flex justify-end">
                          <button type='submit' className='bg-[#E04343] text-white py-[10px] rounded w-full'>Add State</button>
                        </div>


                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </form>




          </div>
        </Modal>
      </section>

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
    </>
  )
}

export default AddStateToModal