import React from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from 'react-redux';
import { Dispatch } from "redux";
import { SvgElement, icontypesEnum } from "../assets/svgElement";
import { NavLink } from "react-router-dom";

export default function Navbar({ title }) {
  const dispatch = useDispatch();
  const userLoginData = useSelector((state) => state.data.login.value);

  return (
    <>
      <div className="relative md:pt-12 pb-2 pt-8 md:border-b  md:m-2" style={{ backgroundColor: '#FAFAFA' }}>
        {/* Navbar */}
        <nav className="absolute top-0 left-0 w-full z-10 bg-transparent md:flex-row md:flex-nowrap md:justify-end flex items-center p-2">
          <div className="flex justify-between px-4 lg:w-full">
            {/* Brand */}
            <a
              className="text-black font-semibold text-[16px]  hidden mt-2 lg:inline-block"
              href=""
              onClick={(e) => e.preventDefault()}
            >
              {title}
            </a>


            {/* Form */}
            <span className="flex justify-end">

              <span className="md:flex hidden flex-row flex-wrap items-center lg:ml-auto mr-3">

                {userLoginData?.data?.role == 'user' ?
                 <NavLink to='/editprofile'>
                 <h2 className=" pt-2"><SvgElement type={icontypesEnum.SETTINGS} /></h2>
               </NavLink>
                  :
                  <div className="relative flex justify-end w-full flex-wrap items-stretch gap-5">
                    <h2 className="pt-2 capitalize">  {(userLoginData?.data?.role)?.replace(/_/g, ' ')} <b>({userLoginData?.data?.office ? (userLoginData?.data?.office + " Branch")?.replace(/_/g, ' ') : 'No Branch'})</b>  </h2>
                
                    {/* <NavLink to='/editprofile'>
                  <h2 className=" pt-2"><SvgElement type={icontypesEnum.SETTINGS} /></h2>
                </NavLink> */}

                  </div>
                }

                



              </span>
            </span>

            {/* User */}

          </div>
        </nav>
      </div>
      {/* End Navbar */}
    </>
  );
}

Navbar.defaultProps = {
  title: " ",
};
