import React, { useState } from "react";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import { Dispatch } from "redux";
import { useSelector, useDispatch } from 'react-redux';
import { AxiosResponse, AxiosError } from "axios";
import axios from "axios";
import { History } from "history";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { PaymentApis } from '../../apis/paymentApis';
import { Oval } from 'react-loader-spinner'



function VerifyPayment() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [show, setShow] = useState(false);

  const navigate = useNavigate();

  // How to access the redux store
  const userLoginData = useSelector((state: any) => state.data.login.value);

  // This is used to update the store
  const dispatch: Dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams()

  if (searchParams.get('reference')) {

    let data = {
      "reference": searchParams.get('reference'),
      "ship_id": searchParams.get('ship_id'),
      "invoice_id": searchParams.get('invoice_id')
    }
    PaymentApis.verifyShipmentPayment(data).then(
      (response: any) => {
        if (response?.data) {
          if (response?.data?.status === true) {
            let shiData = response?.data?.data?.shipmentData
            let shipment = [
              {
                "ThirdPartyToken": "",
                "SenderDetails": {
                  "SenderName": shiData?.SenderName ? shiData?.SenderName:'empty',
                  "SenderCompanyName": shiData?.SenderCompanyName ? shiData?.SenderCompanyName : 'empty',
                  "SenderCountryCode": shiData?.SenderCountryCode ? shiData?.SenderCountryCode : 'empty',
                  "SenderAdd1": shiData?.SenderAdd1 ?  shiData?.SenderAdd1 : 'empty',
                  "SenderAdd2": shiData?.SenderAdd2 ?  shiData?.SenderAdd2 : 'empty',
                  "SenderAdd3": shiData?.SenderAdd3 ? shiData?.SenderAdd3:'empty',
                  "SenderAddCity": shiData?.SenderAddCity ? shiData?.SenderAddCity : 'empty',
                  "SenderAddState": shiData?.SenderAddState ? shiData?.SenderAddState:'empty',
                  "SenderAddPostcode": shiData?.SenderAddPostcode ?  shiData?.SenderAddPostcode :'empty',
                  "SenderPhone": shiData?.SenderPhone ? shiData?.SenderPhone :'empty',
                  "SenderEmail": shiData?.SenderEmail ? shiData?.SenderEmail:'empty',
                  "SenderFax": shiData?.SenderFax ? shiData?.SenderFax : 'empty',
                  "SenderKycType": shiData?.SenderKycType ?shiData?.SenderKycType : 'empty',
                  "SenderKycNumber": shiData?.SenderKycNumber ? shiData?.SenderKycNumber : 'empty',
                  "SenderReceivingCountryTaxID": shiData?.SenderReceivingCountryTaxID ? shiData?.SenderReceivingCountryTaxID :'empty'
                },
                "ReceiverDetails": {
                  "ReceiverName": shiData?.ReceiverName ? shiData?.ReceiverName : 'empty',
                  "ReceiverCompanyName": shiData?.ReceiverCompanyName ? shiData?.ReceiverCompanyName : 'empty',
                  "ReceiverCountryCode": shiData?.ReceiverCountryCode ? shiData?.ReceiverCountryCode : 'empty',
                  "ReceiverAdd1": shiData?.ReceiverAdd1 ? shiData?.ReceiverAdd1 : 'empty',
                  "ReceiverAdd2": shiData?.ReceiverAdd2 ? shiData?.ReceiverAdd2 : 'empty',
                  "ReceiverAdd3": shiData?.ReceiverAdd3 ? shiData?.ReceiverAdd3 : 'empty',
                  "ReceiverAddCity": shiData?.ReceiverAddCity ? shiData?.ReceiverAddCity : 'empty',
                  "ReceiverAddState": shiData?.ReceiverAddState ? shiData?.ReceiverAddState : 'empty',
                  "ReceiverAddPostcode": shiData?.ReceiverAddPostcode ? shiData?.ReceiverAddPostcode : 'empty',
                  "ReceiverMobile": shiData?.ReceiverMobile ? shiData?.ReceiverMobile : 'empty',
                  "ReceiverPhone": shiData?.ReceiverPhone ? shiData?.ReceiverPhone : 'empty',
                  "ReceiverEmail": shiData?.ReceiverEmail ? shiData?.ReceiverEmail : 'empty',
                  "ReceiverAddResidential": shiData?.ReceiverAddResidential ? shiData?.ReceiverAddResidential : 'empty',
                  "ReceiverFax": shiData?.ReceiverFax ? shiData?.ReceiverFax : 'empty',
                  "ReceiverKycType": shiData?.ReceiverKycType ? shiData?.ReceiverKycType : 'empty',
                  "ReceiverKycNumber": shiData?.ReceiverKycNumber ? shiData?.ReceiverKycNumber : 'empty'
                },
                "PackageDetails": {
                  "GoodsDescription": shiData?.GoodsDescription ? shiData?.GoodsDescription : 'empty',
                  "CustomValue": shiData?.CustomValue ? shiData?.CustomValue : 0,
                  "CustomCurrencyCode": shiData?.CustomCurrencyCode ? shiData?.CustomCurrencyCode : 'empty',
                  "InsuranceValue": shiData?.InsuranceValue ? shiData?.InsuranceValue : 0,
                  "InsuranceCurrencyCode": shiData?.InsuranceCurrencyCode ? shiData?.InsuranceCurrencyCode : 'empty',
                  "ShipmentTerm": shiData?.ShipmentTerm ? shiData?.ShipmentTerm :'empty',
                  "GoodsOriginCountryCode": shiData?.GoodsOriginCountryCode ? shiData?.GoodsOriginCountryCode : 'empty',
                  "DeliveryInstructions": shiData?.DeliveryInstructions ? shiData?.DeliveryInstructions : 'empty',
                  "Weight": shiData?.Weight ? shiData?.Weight :0,
                  "WeightMeasurement": shiData?.WeightMeasurement ? shiData?.WeightMeasurement : 'empty',
                  "NoOfItems": shiData?.NoOfItems ?  shiData?.NoOfItems : 0,
                  "CubicL": shiData?.CubicL ? shiData?.CubicL : 0,
                  "CubicW": shiData?.CubicW ? shiData?.CubicW : 0,
                  "CubicH": shiData?.CubicH ? shiData?.CubicH : 0,
                  "CubicWeight": shiData?.CubicWeight ? shiData?.CubicWeight : 0,
                  "ServiceTypeName": shiData?.ServiceTypeName ? shiData?.ServiceTypeName :'empty',
                  "BookPickUP": false,
                  "AlternateRef": shiData?.AlternateRef ? shiData?.AlternateRef : 'empty',
                  "SenderRef1": shiData?.SenderRef1 ? shiData?.SenderRef1 : 'empty',
                  "SenderRef2": shiData?.SenderRef2 ? shiData?.SenderRef2 : 'empty',
                  "SenderRef3": shiData?.SenderRef3 ? shiData?.SenderRef3 : 'empty',
                  "DeliveryAgentCode": shiData?.DeliveryAgentCode ? shiData?.DeliveryAgentCode : 'empty',
                  "DeliveryRouteCode": shiData?.DeliveryRouteCode ? shiData?.DeliveryRouteCode : 'empty',
                  "BusinessType": "B2B",
                  "ShipmentResponseItem": [
                    {
                      "ItemAlt": 'empty',
                      "ItemNoOfPcs": shiData?.NoOfItems ? shiData?.NoOfItems : 'empty',
                      "ItemCubicL": shiData?.CubicL ? shiData?.CubicL : 0,
                      "ItemCubicW": shiData?.CubicW ? shiData?.CubicW :0,
                      "ItemCubicH": shiData?.CubicH ? shiData?.CubicH : 0,
                      "ItemWeight": shiData?.Weight ? shiData?.Weight : 0,
                      "ItemCubicWeight": shiData?.CubicWeight ? shiData?.CubicWeight : 0,
                      "ItemDescription": shiData?.GoodsDescription ? shiData?.GoodsDescription : 'empty',
                      "ItemCustomValue": shiData?.CustomValue ? shiData?.CustomValue : 0,
                      "ItemCustomCurrencyCode": shiData?.CustomCurrencyCode ? shiData?.CustomCurrencyCode : 'empty',
                      "Notes": shiData?.Notes ? shiData?.Notes : 'empty'
                    }
                  ],
                  "CODAmount": shiData?.CODAmount ? shiData?.CODAmount : 0,
                  "CODCurrencyCode": shiData?.CODCurrencyCode ? shiData?.CODCurrencyCode : 'empty',
                  "Bag": shiData?.Bag ? shiData?.Bag : 0,
                  "Notes": shiData?.Notes ? shiData?.Notes : 'empty',
                  "OriginLocCode": shiData?.OriginLocCode ? shiData?.OriginLocCode : 'empty',
                  "BagNumber": shiData?.BagNumber ? shiData?.BagNumber : 'empty',
                  "DeadWeight": shiData?.DeadWeight ? shiData?.DeadWeight :0,
                  "ReasonExport": shiData?.ReasonExport ? shiData?.ReasonExport : 'empty',
                  "DestTaxes": shiData?.DestTaxes ? shiData?.DestTaxes : 0,
                  "Security": shiData?.Security ? shiData?.Security : 0,
                  "Surcharge": shiData?.Surcharge ? shiData?.Surcharge : 0,
                  "ReceiverTaxID": shiData?.ReceiverTaxID ? shiData?.ReceiverTaxID : 'empty',
                  "OrderNumber": shiData?.OrderNumber ? shiData?.OrderNumber : 0,
                  "Incoterms": shiData?.Incoterms ? shiData?.Incoterms : 'empty',
                  "ClearanceReference": shiData?.ClearanceReference ? shiData?.ClearanceReference : 'empty'
                }
               
              }
            ];




            //  let datss =  fetch('https://api.postshipping.com/api2/shipments', {
            //     mode: 'no-cors',
            //     method: "post",
            //     headers: {
            //       "Content-Type": "aplication/json",
            //       "Token":"CA8B5441A42899FD8BCC347B92A7B771",
            //     },
            //     body: JSON.stringify(shipment)
            //   })

            let final = {
              "data": shipment,
              "ship_id": searchParams.get('ship_id')
            }




            PaymentApis.pushShipmentToGetonline(final).then(
              (response: AxiosResponse<any>) => {
                if (response?.data) {
                  console?.log(response?.data)
                  console?.log(final)
                  navigate('/shipments');
                }
              }
            )

            // navigate('/shipments');
          }
        } else {
          // toast.warn('Invalid Login Credentials');
        }
      }
    ).catch(function (error: any) {
      // handle error
      console.log(error.response.data);
    }).finally(() => {
      // toast.error("No Internet Connection");

    });
  }



  return (
    <>
      <div className="flex justify-center mt-[50vh]">
        <Oval
          visible={true}
          height="80"
          width="80"
          color="#E04343"
          secondaryColor="#FCBF94"
          ariaLabel="oval-loading"
          wrapperStyle={{}}
          wrapperClass=""
        />
      </div>


    </>
  );
}

export default VerifyPayment;
