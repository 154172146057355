import React from 'react'
import Navbar from '../../Navbars/Navbar'
import Footer from '../../Footer'

function UsaToNigeria() {
    return (
        <>
            <Navbar />
            <div className='bg-[#FFFFFF] sm:px-16 px-6 md:flex md:justify-center items-start py-10' >
                <div className='xl:max-w-[1200px] w-full mx-auto'>
                    {/* <!-- blog Header --> */}
                    <div>
                        <h1 className="text-[24px] font-[700] text-[#282828] mb-3">How to ship goods from Nigeria to USA</h1>
                        <img src="/images/blog1.jpg" alt="hero" />
                    </div>

                    {/* <!-- blog Header --> */}
                    <div>
                        <p className='mt-3 text-[#000000] text-[16px] md:leading-[25px]'>When it comes to shipping goods from Nigeria to USA, many people are usually at a loss. Some people still believe it’s impossible. Others will argue that it is prohibitively expensive. Until now, there is almost nothing you can’t send to the United States, assuming it’s legal. You can also receive anything from the United States, including clothing, food, gifts, letters, and much more. Many Nigerians are starting to build online empires using eBay, Instagram, and other online e-commerce platforms. They no longer have to be concerned about shipping their goods abroad because it has now become simple.</p>
                    </div>

                    <div className='mt-4'>
                        <h1 className="text-[24px] font-[700] text-[#282828] mb-3">REQUIREMENTS FOR SHIPPING FROM NIGERIA TO USA</h1>
                        <p className='mt-3 text-[#000000] text-[16px] md:leading-[25px]'>To successfully send items to the USA, you must meet a few requirements.</p>
                    </div>

                    <div className='mt-5'>
                        <h1 className="text-[22px] font-[700] text-[#282828] mb-3">
                            1. Examine your package 
                        </h1>
                        <p className='mt-3 text-[#000000] text-[16px] md:leading-[25px]'>You must thoroughly inspect your items. The types of package you can send are governed by rules. The rules are in place to prevent wrongdoers from abusing this opportunity.
                        Items such as explosives, perishable goods, liquid, hazardous goods, animals, and so on are not permitted. You can always ask your preferred freight company for a list of prohibited items.</p>
                    </div>

                    <div className='mt-5'>
                    <h1 className="text-[22px] font-[700] text-[#282828] mb-3">
                            2. Find out what the import rules are for your products
                        </h1>
                        <p className='mt-3 text-[#000000] text-[16px] md:leading-[25px]'>When receiving items from another country, the United States has customs restrictions and different rules. You may be required to pay additional fees for certain items. However, this depends on the type and quantity of items in your package. It is always a good idea to inquire about customs and border protection to be aware of any additional fees or paperwork that may be required. </p>
                    </div>

                    <div className='mt-5'>
                    <h1 className="text-[22px] font-[700] text-[#282828] mb-3">
                            3. Make sure your products are properly packaged
                        </h1>
                        <p className='mt-3 text-[#000000] text-[16px] md:leading-[25px]'>The climate in the United States varies according to the season. So, if you’re sending a package in the winter, you’ll need to pack it differently than you would in the summer. There are guidelines available online to assist you in properly packing your items. </p>
                    </div>

                    <div className='mt-5'>
                    <h1 className="text-[22px] font-[700] text-[#282828] mb-3">
                            4. Find the cheapest delivery option for your package.
                        </h1>
                        <p className='mt-3 text-[#000000] text-[16px] md:leading-[25px]'>Many delivery companies specialize in sending items to the United States. While looking for an affordable one, keep their credibility in mind to avoid missing items or being scammed. SkyNet Worldwide Express is your recommended option. </p>
                    </div>

                    <div className='mt-4'>
                    <h1 className="text-[24px] font-[700] text-[#282828] mb-3">
                    CONCLUSION 
                        </h1>
                        <p className='mt-3 text-[#000000] text-[16px] md:leading-[25px]'> SkyNet provides access to multiple shipping options for your ocean and air shipments. With state-of-the-art online tools and platforms, you can have online visibility until your shipment is delivered to you.</p>
                        <p className='mt-3 text-[#000000] text-[16px] md:leading-[25px]'>Our services at SkyNet Worldwide Express are guided by our complete adherence and conformity to the laws governing import and export. This has allowed us to successfully ship thousands of items from Nigeria to USA (Import) and send goods back to Nigeria (Export). </p>
                    </div>
                </div>
            </div>

            <Footer/>
        </>
    )
}

export default UsaToNigeria