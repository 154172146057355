import React from 'react'
import AdminNavbar from "../Navbars/AdminNavbar";
import Sidebar from "../Sidebar/Sidebar";
import { SuperAdminApis } from '../../apis/superAdminApi';
import { useNavigate } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { convertUTCDateToLocalDate } from '../Reusable/functions';
//@ts-ignore
import Modal from 'react-awesome-modal';
import { SvgElement, icontypesEnum } from '../assets/svgElement';
import { AuthApis } from '../../apis/authApis';
import { error } from 'console';
import AdminSidebar from '../Sidebar/AdminSidebar';
import { formatDateTime } from '../Reusable/functions';
function ManageAudit() {

  const navigate = useNavigate();
  const [userLists, setUserList] = React.useState<any>([]);
  const [searchText, setSearchText] = React.useState('');
  const [stateFrom, setStateFrom] = React.useState<any>([]);

  let [addModal, setAddModal] = React.useState<boolean>(false);
  let [officeModal, setOfficeModal] = React.useState<boolean>(false);

  const [phone, setPhone] = React.useState<any>("");

  const [userData, setUserdata] = React.useState<any>({
    'branch_name': "",
    'state': "",
    'zone': ""
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target;
    setUserdata({ ...userData, [name]: value });
  };

  const handleSubmit = React.useCallback(
    (e: React.ChangeEvent<HTMLFormElement>) => {
      e.preventDefault();
      const formData = new FormData()
      formData.append('branch_name', userData?.branch_name)
      formData.append('zone', userData?.zone)
      formData.append('state', userData?.state)

      SuperAdminApis.createBranch(formData).then(
        (response: AxiosResponse<any>) => {
          if (response?.data) {
            if (response?.data?.status === true) {
              // dispatch(login({ email: userData?.email, token: response.data.token, name: response.data.name }))
              toast.success(response?.data?.message);
              setUserdata([]);
              // navigate('/email-verify');

            }else{
              // toast.warn("Something went wrong,Try Again");
            }

          } else {
            console?.log(response)
            toast.warn("Something went wrong,Try Again");
          }

          setAddModal(false)
          const query: any = {
            search: searchText,
          };
          SuperAdminApis.getAllBranch('', query).then(
            (response: AxiosResponse<any>) => {
              if (response?.data) {
                setUserList(response?.data?.data)
              }
            }
          )

          setUserdata([])

          // toast.success(response?.data?.message);
        }
      ).catch(function (error) {
        // handle error
        console.log(error.response.data);
        toast.warn(error.response?.data?.message);
        toast.error("Offfline");
      }).finally(() => {

      });
    },
    [userData, phone,userLists]
  );

  let [riderModal, setRiderModal] = React.useState<boolean>(false);

  let [riderDeleteModal, setRiderDeleteModal] = React.useState<boolean>(false);
  let [shipmentId, setShipmentId] = React.useState<any>([]);
  let [shipmentRider, setShipmentRider] = React.useState<any>('');
 

  React.useEffect(() => {
    const query: any = {
      search: searchText,
    };
    SuperAdminApis.getAudits('', query).then(
      (response: AxiosResponse<any>) => {
        if (response?.data) {
          setUserList(response?.data?.data)
        }
      }
    ).catch(function (error) {
      // handle error

    })
  }, []);



  const paginator = React.useCallback(
    (value: any) => {
      //   setLoader(true);
      let value2 = '';
      if (value !== null) {
        value2 = value;
      } else {
        value2 = ''
      }
      const query: any = {
        search: searchText,
      };

      SuperAdminApis.getAudits(value2, query).then(
        (response: AxiosResponse<any>) => {
          if (response?.data) {
            setUserList(response?.data?.data)
          }
        }
      ).catch(function (error) {
        console.log(error.response.data);
      })

    }, [userLists, searchText]);


  return (
    <>
      <AdminSidebar title="Audit" />
      <div className="relative md:ml-64 bg-[#FAFAFA] ">
        <AdminNavbar title="Audit" />
        <div className=' lg:px-10 pb-6 px-6 bg-[#FAFAFA] min-h-screen'>

          <div className='flex justify-between my-5'>
            <div>
              <h3 className='text-[15px] text-[#1C1C1C] font-semibold'>Audit List</h3>

            </div>
            <div>
              {/* <button className='bg-[#E04343] text-white text-[14px] px-[12px] py-[8px] rounded-[10px]'>+ Add Customers</button> */}
              <div className='flex justify-end'>
                <div className="relative flex w-full flex-wrap items-stretch">
                  <div className="absolute inset-y-0 right-2 flex items-center pl-3 pointer-events-none">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="#9da4aa" d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0a5.5 5.5 0 0 1 11 0z" /></svg>
                  </div>
                  <input type="text" onClick={() => paginator('')} onChange={e => setSearchText(e.target.value)} placeholder='search...' id="simple-search" className=" border border-gray-300 text-gray-500 text-sm rounded-md block w-full pl-4 p-1  " required />
                </div>

                <div className='mt-0.5 ml-2'><button type='button' onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => paginator('')} className={"font-normal text-white bg-[#E04343] px-3 py-0.5 rounded-md"}>Search</button> </div>

              </div>

            


            </div>
          </div>


          <div className="relative overflow-x-auto">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 border-separate border-spacing-y-3">
              <thead className="text-[12px] text-[#030229] bg-[#FAFAFA] ">
                <tr>
                  <th scope="col" className="px-2 py-2">
                    S/N
                  </th>

                  <th scope="col" className="px-2 py-2">
                  Auditor Name
                  </th>
                  <th scope="col" className="px-2 py-2">
                    Email
                  </th>

                  <th scope="col" className="px-2 py-2">
                    Branch
                  </th>

                  <th scope="col" className="px-2 py-2">
                    Role
                  </th>

                  <th scope="col" className="px-2 py-2">
                    Shipmemt Id
                  </th>

                  <th scope="col" className="px-2 py-2">
                   Action
                  </th>
                 
                  <th scope="col" className="px-2 py-4">
                    Date
                  </th>

                </tr>
              </thead>


              <tbody className=''>

                {
                  userLists?.data?.length > 0 ?
                    userLists?.data?.map(
                      (datas: any, index: any) => (
                        <tr className="bg-white  odd:bg-white  even:bg-gray-50 ">

                          <td className="px-2 py-2">
                            {index + 1}
                          </td>

                          <td className="px-2 py-4">
                            {datas?.auditor_name}
                          </td>
                          <td className="px-2 py-4 ">
                            {datas?.auditor_email}
                          </td>

                          <td className="px-2 py-4 ">
                            {datas?.branch}
                          </td>

                          <td className="px-2 py-4 ">
                            {datas?.role}
                          </td>

                          <td className="px-2 py-4 ">
                            {datas?.shipment_id}
                          </td>

                          <td className="px-2 py-4 ">
                            {datas?.shipment_action}
                          </td>

                          <td className="px-2 py-4 ">
                            {formatDateTime(datas?.created_at)}
                          </td>

                        </tr>
                      )
                    )
                    :
                    <div className='flex justify-start mt-4'>
                      <td >No Record Found</td>
                    </div>


                }

              </tbody>
            </table>
          </div>
          <div className='m-4 flex justify-end mt-20'>
            {
              userLists?.links?.filter(((item: any, idx: any) => idx < 1000)).map(
                (datas: any, index: any) => (
                  <button onClick={() => paginator(datas?.label == 'Next &raquo;' ? datas?.url?.charAt(datas?.url?.length - 1) : (datas?.label === '&laquo; Previous') ? datas?.url?.charAt(datas?.url?.length - 1) : datas?.label)} disabled={datas?.active} className={'mx-1 py-1 px-2 ' + ((datas?.active == false || datas?.url == null) ? 'bg-gray-300 text-black ' : 'bg-[#E04343] text-white')}>
                    {datas?.label == '&laquo; Previous' ? '< Previous' : (datas?.label === 'Next &raquo;') ? 'Next  >' : datas?.label}
                  </button>
                )
              )
            }

          </div>
        </div>
      </div>


  

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
    </>
  )
}

export default ManageAudit