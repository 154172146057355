import React, { useState } from "react";
import { Transition } from "@headlessui/react";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { Dispatch } from "redux";
import { login } from '../../reducer/loginSlice'
import { useNavigate } from 'react-router-dom';
import '../../index.css';
import { SvgElement, icontypesEnum } from "../assets/svgElement";



function Navbar() {
  const dispatch: Dispatch = useDispatch();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const [isLog, setIslog] = useState(false);

  const userLoginData = useSelector((state: any) => state.data.login.value);



  const logOut = () => {
    setIslog(false)
    dispatch(login([]))
    navigate('/login');
  };

  return (
    <div>
      <nav className="bg-white sm:px-4 w-full z-20 top-0 left-0 shadow-md py-4 md:py-0 px-5" >
        <div className=" flex justify-between  mx-auto">
          <div className=" md:px-16 px-1">

            <NavLink to='/'>
              <div className="self-center pt-3 text-2xl font-semibold whitespace-nowrap">
                <img src="/images/Auth/logo.png" alt="hero" />
              </div>
            </NavLink>
          </div>

          <div className="flex md:order-2">
            <div className="hidden md:flex justify-center ml-[80px]">

              <NavLink to='/'
                className=" hover:text-[#FF0000] text-[#000000] font-bold text-base px-5 py-5 mt-3 text-center mr-1 md:mr-1 "
                style={({ isActive }) =>
                  isActive ? { color: '#FF0000', fontWeight: "700" } : { color: '#000000' }
                }
              >
                Home
              </NavLink>
              <NavLink to='/about-us'
                className="hover:bg-gray-100 hover:text-[#FF0000] text-[#000000] font-bold text-base px-5 py-5 mt-3 text-center mr-1 md:mr-1 "
                style={({ isActive }) =>
                  isActive ? { color: '#FF0000', fontWeight: "700" } : { color: '#000000' }
                }
              >
                About Us
              </NavLink>

              <NavLink to='/services'
                className="hover:text-[#FF0000] font-bold text-base px-5 py-5 mt-3 text-center mr-1 md:mr-1 "
                style={({ isActive }) =>
                  isActive ? { color: '#FF0000', fontWeight: "700" } : { color: '#000000' }
                }
              >
                Services
              </NavLink>

              <NavLink to='/tracking-page'
                className="hover:text-[#FF0000] font-bold text-base px-5 py-5 mt-3 text-center mr-1 md:mr-1 "
                style={({ isActive }) =>
                  isActive ? { color: '#FF0000', fontWeight: "700" } : { color: '#000000' }
                }
              >
                Tracking
              </NavLink>

             

              <NavLink to='/blog'
                className="hover:text-[#FF0000] font-bold text-base px-5 py-5 mt-3 text-center mr-1 md:mr-1 "
                style={({ isActive }) =>
                  isActive ? { color: '#FF0000', fontWeight: "700" } : { color: '#000000' }
                }
              >
                Blog
              </NavLink>
              <div className="flex">
                <NavLink to='/login'>
                  <div
                    className="font-semibold text-base py-5 mt-3 text-center mr-1 md:mr-1 "
                  >
                    Log in
                  </div>
                </NavLink>

                <NavLink to="/Register">
                  <div
                    className="text-white font-medium text-sm px-5 py-4 mt-3 text-center mr-3 md:mr-3 "
                  >
                    <button
                      type="submit"
                      className=" text-white font-medium rounded-[50px] bg-[#FE0000] text-sm  px-5 py-2.5 text-center "
                    >
                      Sign up
                    </button>
                  </div></NavLink>

              </div>
            </div>




            <button
              onClick={() => setIsOpen(!isOpen)}
              data-collapse-toggle="navbar-sticky"
              type="button"
              className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 "
              aria-controls="navbar-sticky"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>
              {!isOpen ? (
                <svg
                  className="w-6 h-6"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              ) : (
                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              )}
            </button>
          </div>


        </div>

        <Transition
          show={isOpen}
          enter="transition ease-out duration-100 transform"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-75 transform"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          {(ref) => (
            <div className="md:hidden bg-white w-full mt-3" id="mobile-menu">
              <div ref={ref} className="px-2 pt-2 pb-3 space-y-1 sm:px-3">

                <NavLink
                  to="/"
                  onClick={() => setIsOpen(!isOpen)}
                  className="block text-[#616161] pt-3 font-bold "

                >
                  Home
                </NavLink>
                <hr className="pb-2" />

                <NavLink
                  to="/about-us"
                  onClick={() => setIsOpen(!isOpen)}
                  className="block text-[#616161] pt-3 font-bold "

                >
                  About Us
                </NavLink>
                <hr className="pb-2" />
                <NavLink
                  to="/"
                  onClick={() => setIsOpen(!isOpen)}
                  className="block text-[#616161] pt-3 font-bold "

                >
                  Services
                </NavLink>
                <hr className="pb-2" />
                <NavLink
                  to="/tracking-page"
                  onClick={() => setIsOpen(!isOpen)}
                  className="block text-[#616161] pt-3 font-bold "

                >
                  Tracking
                </NavLink>
                <hr className="pb-2" />
                
                <NavLink
                  to="/"
                  onClick={() => setIsOpen(!isOpen)}
                  className="block text-[#705656] pt-3 font-bold "

                >
                  Blog
                </NavLink>
                <hr className="pb-2" />

                <div className="flex">
                  <NavLink
                    to="/login"
                    className="block py-2 pr-4    md:p-0"
                  >
                    <button className="border border-[#FF0000] text-[#FF0000] rounded-[10px] px-5 py-2">
                      Login
                    </button>

                  </NavLink>

                  <NavLink
                    to="/register"
                    className="block py-2 pr-4  md:p-0"
                  >
                    <button className="bg-[#FF0000] border border-[#FF0000] text-white rounded-[10px] px-5 py-2">
                    Register
                    </button>
                   
                  </NavLink>
                </div>



              </div>
            </div>
          )}
        </Transition>

      </nav>


    </div>
  );
}

export default Navbar;
