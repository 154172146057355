import React from 'react'
import Navbar from '../Navbars/Navbar'
import { NavLink } from "react-router-dom";
// @ts-ignore
import Carousel from 'react-grid-carousel'

import Footer from '../Footer';

function Home() {
  
  return (
    <>
      <Navbar />
      {/* Start Section */}
      <div className='pt-4'>
      <Carousel cols={1} rows={1} gap={10}  mobileBreakpoint={20} loop showDots>
        <Carousel.Item>
          <div className="relative">
            <img src="/images/slide-1.png" alt="hero" className='w-full' />
            <div className="absolute top-7 md:top-28 lg:top-48 left-10">
              <h1 className="text-white text-[16px] md:text-[30px] lg:text-[44px] font-bold">THE BEST COURIER COMPANY WITH<br /> AFFORDABLE RATES</h1>
              <div className="mt-3">
                <button className="border border-white text-white py-[4px] md:py-[10px] px-[10px] md:px-[30px] uppercase text-[12px] md:text-[20px]">Get Quote</button>
              </div>
            </div>
          </div>

        </Carousel.Item>
        <Carousel.Item>
          <div className="relative">
            <img src="/images/slide-2.png" alt="hero" className='w-full' />
            <div className="absolute top-7 md:top-28 lg:top-48 left-10">
              <h1 className="text-white text-[16px] md:text-[30px] lg:text-[44px] font-bold">1 TEAM, 1 MISSION, EXCEPTIONAL<br /> SERVICE.</h1>
              <div className="mt-3">
                <button className="border border-white text-white py-[4px] md:py-[10px] px-[10px] md:px-[30px] uppercase text-[12px] md:text-[20px]">Learn More</button>
              </div>
            </div>
          </div>

        </Carousel.Item>
        <Carousel.Item>
          <div className="relative">
            <img src="/images/slide-31.png" alt="hero" className='w-full' />
            <div className="absolute top-7 md:top-28 lg:top-48 left-10">
              <h1 className="text-white text-[16px] md:text-[30px] lg:text-[44px] font-bold">A DELIVERY SERVICE YOU CAN DEPEND<br /> ON</h1>
              <div className="mt-3">
                <button className="border border-white text-white py-[4px] md:py-[10px] px-[10px] md:px-[30px] uppercase text-[12px] md:text-[20px]">Get Quote</button>
              </div>
            </div>
          </div>

        </Carousel.Item>

      </Carousel>
      </div>
    
      {/* End Section */}

      {/* Start Section */}
      <div className='bg-[#FFFFFF] sm:px-16 px-6 md:flex md:justify-center items-start md:py-10' >
        <div className='xl:max-w-[1200px] w-full mx-auto'>
          <div className="flex justify-center items-center">
            <div>
              <h1 className="text-[26px] font-bold leading-[33px]">Why Choose Us?</h1>
              <div className='w-[90px] h-1 bg-[#FD0000] mx-auto mt-3'></div>
            </div>
          </div>

          <div className='grid grid-cols-1 lg:grid-cols-4 mt-10'>
            <div>
              <div className="flex justify-center">
                <img src="/images/truck.png" alt="hero" />
              </div>
              <h3 className="text-[17px] font-[500] text-center">Timely Delivery</h3>
            </div>

            <div>
              <div className="flex justify-center">
                <img src="/images/user.png" alt="hero" />
              </div>
              <h3 className="text-[17px] font-[500] text-center mt-5">Exceptional Customer <br /> Service </h3>
            </div>

            <div>
              <div className="flex justify-center">
                <img src="/images/calender.png" alt="hero" />
              </div>

              <h3 className="text-[17px] font-[500] text-center mt-5">Flexible</h3>
            </div>

            <div>
              <div className="flex justify-center">
                <img src="/images/hand.png" alt="hero" />
              </div>
              <h3 className="text-[17px] font-[500] text-center mt-5">Reliable</h3>
            </div>

          </div>
        </div>
      </div>
      {/* End Section */}

      {/* Start Section */}
      <div className='bg-[#FFFFFF] sm:px-16 px-6 flex justify-center items-start md:pt-10' >
        <div className='xl:max-w-[1280px] w-full'>
          <section className='flex md:flex-row flex-col sm:py-16 py-6'>
            <div className='flex-1 flex-col justify-center items-start  '>
              <div>
                <iframe className="w-[40vw] h-full aspect-video " src="https://www.youtube.com/embed/ak5Ivz7oPwE?controls=0&rel=0&playsinline=0&modestbranding=0&autoplay=0&enablejsapi=1&origin=https%3A%2F%2Fskynetworldwide.com.ng&widgetid=1"></iframe>
              </div>
            </div>

            <div className='flex-1 flex flex-col md:ml-[80px] ml-0  relative'>

              <div>
                <h1 className="text-[19px] font-semibold leading-[24px]">About Us</h1>
                <div className='w-[60px] h-1 bg-[#FD0000] mt-3'></div>
              </div>
              <div className="mt-10">
                <h1 className="text-[28px] font-bold leading-[36.4px]">MODERN & TRUSTED <br />LOGISTICS COMPANY</h1>
                <p className="text-[16px] leading-[25px] mt-3 text-black">The SkyNet Worldwide Express network stretches across all continents, providing the Global business community with fast, reliable and secure Express delivery services to almost every country and territory Worldwide. Our Global Network of offices provides you global reach, competitive transit time, online tracking and proof of delivery.</p>
                <p className="text-[16px] leading-[25px] mt-3 text-black">What’s more, our fast, efficient and reliable global express services
                  are underpinned by a powerful and user-friendly web- based
                  system. This system features on-line tracking, proof of delivery,
                  electronic invoicing and smart dispatch systems.</p>

                <div className="mt-4">
                  <button className="bg-[#FE0503] text-white rounded-[100px] px-[24px] py-[12px]">
                    Learn More
                  </button>
                </div>
              </div>




            </div>

          </section>
        </div>
      </div>
      {/* End Section */}

      {/* Start Section */}
      <div className='bg-[#FE0503] sm:px-16 px-6 flex justify-center items-start py-10' >
        <div className='xl:max-w-[1280px] w-full'>
          <div className="flex justify-center items-center">
            <div>
              <h1 className="text-[26px] font-bold leading-[33px] text-white">Our Services</h1>
              <div className='w-[90px] h-1 bg-[#FFFFFF] mx-auto mt-3'></div>
            </div>
          </div>
          <div className="flex justify-center items-center pt-10">
            <div>
              <h1 className="text-[40px] font-bold leading-[33px] text-white">We Are Trusted For Our Services</h1>

            </div>
          </div>
          <div className="grid grid-cols-3">

          </div>

        </div>
      </div>
      {/* End Section */}

      {/* Start Section */}
      <div className='bg-[#FFFFFF] sm:px-16 px-6 flex justify-center items-start py-10' >
        <div className='xl:max-w-[1280px] w-full'>
          <div className='flex justify-center items-center'>
            <h1 className="text-center font-bold font-poppins md:text-[32px] text-[27px] text-black md:leading-[50px] leading-[38px]">
              Testimonials
            </h1>
          </div>

          <div className=' pt-10'>
            <Carousel cols={1} rows={1} gap={10} loop showDots>
              <Carousel.Item>
                <div className="flex justify-center">
                  <img src="/images/test1.jpg" alt="hero" className="h-[100px] w-[100px] rounded-full" />
                </div>
                <div className="flex justify-center items-center text-center">

                  <div className=" md:mx-[200px]">
                    <h3 className="mt-3 text-[26px] font-normal leading-[33px]">Danjuma Okeme</h3>
                    <h4 className="mt-3 text-[#4B4F58] text-[16px]">National warehouse and Logistics Manager, Smile Communications Nigeria Limited</h4>
                    <p className="mt-3 text-[#4B4F58] text-[16px]">They have handled and cleared over 400 TONS of telecom equipment worth over 100 Million USD for us since commencement of our relationship. For almost half a decade now, they have successfully provided us with logistics and deployment solutions of our network equipment which now covers four key states namely; Lagos, Ibadan, Abuja and Port Harcourt.</p>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="flex justify-center">
                  <img src="/images/test2.png" alt="hero" className="h-[100px] w-[100px] rounded-full" />
                </div>
                <div className="flex justify-center items-center text-center">
                  <div className=" md:mx-[200px]">
                    <h3 className="mt-3 text-[26px] font-normal leading-[33px]">Prince Neburagho</h3>
                    <h4 className="mt-3 text-[#4B4F58] text-[16px]">Supply Chain Manager, RANBAXY NIGERIA LTD</h4>
                    <p className="mt-3 text-[#4B4F58] text-[16px]">They are known for quality experience within the logistics and haulage industry, their core values of safety, customer service and professionalism create a niche for them above others. With their trained personnel, the outfit will meet your expectations.</p>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="flex justify-center">
                  <img src="/images/test3.png" alt="hero" className="h-[100px] w-[100px] rounded-full" />
                </div>
                <div className="flex justify-center items-center text-center">

                  <div className=" md:mx-[200px]">
                    <h3 className="mt-3 text-[26px] font-normal leading-[33px]">Banjoko Adedapo</h3>
                    <h4 className="mt-3 text-[#4B4F58] text-[16px]">Logistics Manager, Calcare Development Nigeria LTD</h4>
                    <p className="mt-3 text-[#4B4F58] text-[16px]">For the past 5 years,Skynet Worldwide Express Nigeriahas handled our nationwide deliveries and export of our international packages.They are our major service provider, and we attribute this to their good track records in providing dependable and reliable courier services.</p>
                  </div>
                </div>
              </Carousel.Item>


            </Carousel>



          </div>




        </div>
      </div>
      {/* End Section */}
      {/* Start Section */}
      <div className='bg-[#FFFFFF] sm:px-16 px-6 flex justify-center items-start py-10' >
        <div className='xl:max-w-[1280px] w-full'>
          <div className='flex justify-center items-center'>
            <h1 className="text-center font-bold font-poppins md:text-[32px] text-[27px] text-black md:leading-[50px] leading-[38px]">
              Our Clientele
            </h1>
          </div>

          <div className=' pt-10'>


            <Carousel cols={3} rows={1} gap={10} loop showDots>
              <Carousel.Item>
                <div className="flex justify-center">
                  <img src="/images/images.png" alt="hero" />

                </div>

              </Carousel.Item>
              <Carousel.Item>
                <div className="flex justify-center">
                  <img src="/images/images1.png" alt="hero" />

                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="flex justify-center">
                  <img src="/images/astrazeneca-logo-sm.png" alt="hero" />

                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="flex justify-center">
                  <img src="/images/carlcare.png" alt="hero" className='w-[40%] mt-5' />

                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="flex justify-center">
                  <img src="/images/Cummins_logo.png" alt="hero" className='w-[150px] mt-5' />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="flex justify-center">
                  <img src="/images/emel.jfif" alt="hero" className='w-[150px] mt-5' />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="flex justify-center">
                  <img src="/images/exphar.jfif" alt="hero" className='w-[150px] mt-5' />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="flex justify-center">
                  <img src="/images/fidelity-bank.png" alt="hero" className='w-[150px] mt-5' />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="flex justify-center">
                  <img src="/images/manipal.jfif" alt="hero" className='w-[150px] mt-5' />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="flex justify-center">
                  <img src="/images/ppc.png" alt="hero" className='w-[150px] mt-5' />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="flex justify-center">
                  <img src="/images/smile-logo.png" alt="hero" className='w-[150px]' />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="flex justify-center">
                  <img src="/images/sports.png" alt="hero" className='w-[150px]' />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="flex justify-center">
                  <img src="/images/sproxil.png" alt="hero" />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="flex justify-center">
                  <img src="/images/sun-pharma.png" alt="hero" className='w-[100px]' />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="flex justify-center">
                  <img src="/images/TecnoMobile.png" alt="hero" className='w-[150px]' />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="flex justify-center">
                  <img src="/images/usaid.png" alt="hero" className='w-[150px] mt-5' />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="flex justify-center">
                  <img src="/images/Xiaomi-Logo.png" alt="hero" className='w-[150px] mt-5' />
                </div>
              </Carousel.Item>


            </Carousel>



          </div>

          <div className="flex justify-center items-center pt-16">
            <div>
              <h2 className="text-center text-[32px] font-[600]">We’re dedicated to customers and focused on quality</h2>
              <h5 className="text-center text-[15px] mt-3">You can rely on us to deliver your cargo with fastest means possible giving you peace of mind.</h5>
              <div className="flex justify-center items-center pt-10">
                <button className='bg-[#FE0503] text-white px-[30px] py-[15px] rounded-[100px] uppercase'>Request a Quote</button>
              </div>
            </div>
          </div>


        </div>
      </div>
      {/* End Section */}
      
      {/* Start Section */}
      {/* <div className='bg-[#F4FBFF] sm:px-16 px-6 flex justify-center items-start py-16' >
        <div className='xl:max-w-[1200px] w-full'>
          <CTA />
        </div>
      </div> */}
      <div>
        <Footer/>
      </div>
      {/* End Section */}

     





      
    </>
  )
}

export default Home;